import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { noop } from 'lodash-es'
import { State } from '@/types/redux/social-auth'

const initialState: State = {
  FacebookAuthComplete: false,
  loading: false,
}

const socialAuthSlice = createSlice({
  name: 'socialAuth',
  initialState,
  reducers: {
    getSocialAuthStatus: (state) => {
      state.loading = true
    },
    setFacebookAuth: (
      state,
      action: PayloadAction<{ accessToken: string; userID: string }>,
    ) => {
      noop(state, action)
    },
    setFacebookAuthStatus: (
      state,
      action: PayloadAction<{ status: string }>,
    ) => {
      state.FacebookAuthComplete =
        action.payload.status === 'permission_granted'
      state.loading = false
    },
  },
})

export const { getSocialAuthStatus, setFacebookAuth, setFacebookAuthStatus } =
  socialAuthSlice.actions
export default socialAuthSlice.reducer

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CampaignInfo, setContract } from '@/store/contract'
import { reduxAPI } from '@/store/redux-api'
import { ModelContractAcception } from '@/types/mock-api-types'

type ShowContract = (
  contract: ModelContractAcception,
  campaignInfo?: CampaignInfo,
) => void

type UseContractModal = () => {
  showContract: ShowContract
}

const useContractModal: UseContractModal = () => {
  const dispatch = useDispatch()
  const contractModal = reduxAPI.state.modal.hooks.useVisible('contract')

  const showContract: ShowContract = useCallback(
    (contract, campaignInfo) => {
      dispatch(setContract({ contract, campaignInfo }))
      contractModal.toggleVisible()
    },
    [contractModal, dispatch],
  )

  return { showContract }
}

export default useContractModal

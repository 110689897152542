import { useEffect, useMemo, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { IntersectionObserverHookRefCallback } from 'react-intersection-observer-hook'
import { useSet } from 'react-use'
import {
  FacebookPost,
  useFetchFacebookPagePicture,
  useFetchFacebookPagePosts,
} from '@/api/meta-api'

type UseFacebookPosts = (
  accessToken?: string,
  pageID?: string,
) => {
  posts: FacebookPost[]
  loading: boolean
  loadingRef: IntersectionObserverHookRefCallback
  hasNextPage: boolean
  pagePicture: string
}

/**
 * @Todo Extract to shared useRTKInfinite
 */
const useFacebookPosts: UseFacebookPosts = (accessToken = '', pageID = '') => {
  const [, { add, has, reset }] = useSet<string>()
  const [after, setAfter] = useState<string>()
  const [posts, setPosts] = useState<FacebookPost[]>([])

  const { data: pagePictureData } = useFetchFacebookPagePicture(
    {
      pageID,
      accessToken,
    },
    { skip: pageID === '' || accessToken === '' },
  )
  const { data, isLoading } = useFetchFacebookPagePosts(
    {
      pageID,
      accessToken,
      after,
    },
    { skip: pageID === '' || accessToken === '' },
  )

  const pagePicture = pagePictureData?.picture.data.url ?? ''
  const hasNextPage = useMemo<boolean>(() => !!data?.paging?.next, [data])

  const [loadingRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: () => setAfter(data?.paging?.cursors.after),
    rootMargin: '0px 0px 0px 0px',
  })

  useEffect(() => {
    reset()
  }, [pageID, reset])

  useEffect(() => {
    if (data) {
      if (!after) {
        setPosts(data.data)
        return
      }

      if (after && !has(after)) {
        add(after)
        setPosts((prevPosts) => [...prevPosts, ...data.data])
      }
    }
  }, [add, after, data, has])

  return {
    posts,
    loading: isLoading,
    loadingRef,
    hasNextPage,
    pagePicture,
  }
}

export default useFacebookPosts

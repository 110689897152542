import { Col, List, Row } from 'antd'
import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

interface CampaignDetailListItemProps {
  title: ReactNode
  content: ReactNode
}

const CampaignDetailListItem: FunctionComponent<
  CampaignDetailListItemProps
> = ({ title, content }) => {
  return (
    <ListItem>
      <Row gutter={[10, 0]}>
        <Col md={4} span={7}>
          {title}
        </Col>
        <Col md={20} span={17}>
          {content}
        </Col>
      </Row>
    </ListItem>
  )
}

const ListItem = styled(List.Item)`
  .ant-col {
    height: 100%;
  }
`

export default CampaignDetailListItem

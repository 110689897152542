import { Select } from 'antd'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { AuthPage } from '@/api/oauth-api'
import defaultProfileSrc from '@/assets/images/kol-profile-default.jpg'
import { KolThumbnail } from '@/components/common/kol-thumbnail'
import { useIntl } from '@/i18n/hooks/use-intl'

interface PageSelectorProps {
  loading: boolean
  value?: string
  onSelect: (page?: AuthPage) => void
  pages: AuthPage[]
  platform: 'facebook' | 'instagram'
  handleAuthClick: (platform: 'facebook' | 'instagram') => void
}

const PageSelector: FunctionComponent<PageSelectorProps> = ({
  loading,
  value,
  onSelect,
  pages,
  handleAuthClick,
  platform,
}) => {
  const { formatMessage } = useIntl()

  const getThumbnailSrc = (page: AuthPage): string => {
    switch (platform) {
      case 'facebook':
        return page.facebookProfileThumbnail ?? defaultProfileSrc
      case 'instagram':
        return page.instagramProfileThumbnail ?? defaultProfileSrc
      default:
        return defaultProfileSrc
    }
  }

  const getName = (page: AuthPage): string => {
    switch (platform) {
      case 'facebook':
        return page.facebookName || ''
      case 'instagram':
        return page.instagramUsername || ''
      default:
        return ''
    }
  }

  return (
    <Select
      loading={loading}
      placeholder={formatMessage(
        { id: 'error:please_select' },
        { selectField: formatMessage({ id: 'general:facebook_page' }) },
      )}
      size='small'
      style={{ width: '100%' }}
      value={value}
      onSelect={(pageID): void => {
        if (pageID === 'RE_AUTH') {
          handleAuthClick(platform)
        } else {
          const page = pages.find((page) => page.pageId === pageID)
          onSelect(page)
        }
      }}
    >
      {pages.map((page) => (
        <Select.Option key={page.pageId} value={page.pageId}>
          <OptionWrapper>
            <KolThumbnail imageSize={18} src={getThumbnailSrc(page)} />
            {getName(page)}
          </OptionWrapper>
        </Select.Option>
      ))}
      <Select.Option value='RE_AUTH'>
        <ReAuthWrapper>
          {formatMessage({
            id: 'general:account_authorization_failed_reconnect_act',
          })}
        </ReAuthWrapper>
      </Select.Option>
    </Select>
  )
}

export const ReAuthWrapper = styled.div`
  font-size: 14px;
  line-height: 36px;
  color: ${({ theme }): string => theme.colors.tagStatus.error};
`

const OptionWrapper = styled.span`
  display: flex;
  align-items: center;

  ${KolThumbnail} {
    margin: 0 6px 0 0;
  }
`

export default PageSelector

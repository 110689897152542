import { z } from 'zod'
import { TappayStatusCode } from '@/api/tappay/tappay-api'
import { stripeBindCreditCardResponseSchema } from '@/types/schema/payment/stripe'
import { tappayBindCreditCardResponseSchema } from '@/types/schema/payment/tappay'

export const bindCreditCardResponseSchema = z.union([
  stripeBindCreditCardResponseSchema,
  tappayBindCreditCardResponseSchema,
])
export type BindCreditCardResponse = z.infer<
  typeof bindCreditCardResponseSchema
>

export const verifyCreditCardResponseSchema = z.object({
  is_succeeded: z.boolean(),
})
export type VerifyCreditCardResponse = z.infer<
  typeof verifyCreditCardResponseSchema
>

export const verifySubscriptionPaymentTransactionResponseSchema = z.object({
  is_succeeded: z.boolean(),
  billing: z
    .object({
      plan: z.object({
        duration: z.number(),
        display_currency: z.string(),
      }),
      amount: z.number(),
    })
    .nullable(),
})
export type VerifySubscriptionPaymentTransactionResponse = z.infer<
  typeof verifySubscriptionPaymentTransactionResponseSchema
>

export const executeSubscriptionPaymentTransactionResponseSchema = z.object({
  payment_provider_info: z
    .object({
      stripe_intent_client_secret: z.string(),
    })
    .optional(),
  status: z.literal(TappayStatusCode.Success).optional(),
  redirect_url: z.string().optional(),
})
export type ExecuteSubscriptionPaymentTransactionResponse = z.infer<
  typeof executeSubscriptionPaymentTransactionResponseSchema
>

import { filter, isNull, isUndefined } from 'lodash-es'
import { QueryParamConfig } from 'serialize-query-params/src/types'
import {
  decodeArray,
  decodeNumber,
  decodeNumericArray,
  decodeString,
  encodeArray,
  encodeNumber,
  encodeNumericArray,
  encodeString,
} from 'use-query-params'

export const createNonNullNumericEnumParam = <T extends number>(
  enumValues: T[],
): QueryParamConfig<T | null | undefined, T | null | undefined> => ({
  encode: encodeNumber,
  decode: (input) => decodeNumericEnum(input, enumValues),
})

function decodeNumericEnum<T extends number>(
  input: string | (string | null)[] | null | undefined,
  enumValues: T[],
): T | null | undefined {
  const numeric = decodeNumber(input)

  if (isNull(numeric) || isUndefined(numeric)) {
    return numeric
  }

  const isValidEnumValue = enumValues.includes(numeric as T)

  return isValidEnumValue ? (numeric as T) : undefined
}

export const NoNullNumericArrayParam: QueryParamConfig<
  number[] | undefined,
  number[] | undefined
> = {
  encode: encodeNumericArray,
  decode: (input) => {
    const result = decodeNumericArray(input)
    return result === null || result === undefined
      ? undefined
      : filter(result, Number)
  },
}

export const NonNullStringParam: QueryParamConfig<
  string | undefined,
  string | undefined
> = {
  encode: encodeString,
  decode: (input) => {
    const result = decodeString(input)
    return result === null ? undefined : result
  },
}

export const NoNullStringArrayParam: QueryParamConfig<
  string[] | undefined,
  string[] | undefined
> = {
  encode: encodeArray,
  decode: (input) => {
    const result = decodeArray(input)
    return result === null || result === undefined
      ? undefined
      : filter(result, String)
  },
}

import { BaseEvent } from '@/utils/ampli'

const sendGoogleAnalyticsEvent = (event: BaseEvent): void => {
  if (!window?.gtag) {
    return
  }

  window.gtag('event', event.event_type, {
    ...event.event_properties,
  })
}

export default sendGoogleAnalyticsEvent

import { Carousel, ModalProps, Typography } from 'antd'
import Image from 'next/image'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import Space from '@/components/common/antd-v4-backport/space'
import { BasicModal } from '@/components/common/antd/basic-modal'
import { Icon } from '@/components/custom-icon'
import { Picture } from '@/types/mock-api-types'

interface ImageItem extends Picture {
  alt: string
  description?: string
}

export interface ImageViewerModalProps extends Pick<ModalProps, 'open'> {
  title: string
  items: ImageItem[]
  onClose: VoidFunction
}

const ImageViewerModal: FunctionComponent<ImageViewerModalProps> = ({
  title,
  items,
  open,
  onClose,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  return (
    <BasicModal
      centered
      destroyOnClose
      footer={null}
      open={open}
      title={title}
      width={520}
      zIndex={1002}
      onCancel={onClose}
    >
      <Wrapper direction='vertical' size='middle'>
        <CarouselWrapper>
          <Carousel
            afterChange={setCurrentSlide}
            arrows={true}
            nextArrow={<Icon type='chevronRightSolid' />}
            prevArrow={<Icon type='chevronLeftSolid' />}
          >
            {items.map((item) => {
              return (
                <ItemWrapper direction='vertical' key={item.uuid}>
                  <Image
                    alt={item.alt}
                    height={456}
                    src={item.signedUrl}
                    width={456}
                  />
                </ItemWrapper>
              )
            })}
          </Carousel>
        </CarouselWrapper>
        {items[currentSlide]?.description && (
          <Typography.Paragraph ellipsis={{ rows: 3 }}>
            {items[currentSlide].description}
          </Typography.Paragraph>
        )}
      </Wrapper>
    </BasicModal>
  )
}

const Wrapper = styled(Space)`
  width: 100%;

  .ant-typography {
    text-align: center;
    color: ${({ theme }): string => theme.colors.text.primary};
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin: 0;
  }
`

const CarouselWrapper = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 2px;
  overflow: hidden;

  .ant-carousel,
  .slick-slider {
    height: 100%;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover {
    margin: 0;
    left: 0;
    z-index: 2;
    color: ${({ theme }): string => theme.colors.text.white};
    font-size: 60px;
    height: 60px;
    width: 60px;
    transform: translateY(-50%);
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    margin: 0;
    right: 0;
    color: ${({ theme }): string => theme.colors.text.white};
    font-size: 60px;
    height: 60px;
    width: 60px;
    transform: translateY(-50%);
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  }
`

const ItemWrapper = styled(Space)`
  img {
    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: center;
  }
`

export default ImageViewerModal

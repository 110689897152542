import { z } from 'zod'
import { countryCodeEnumSchema } from '@/constants/country-code'

export const audienceMainCountrySchema = z.object({
  code: countryCodeEnumSchema,
  weight: z.number(),
})

export const audienceMainAgeSchema = z.object({
  code: z.string(),
  weight: z.number(),
})

export const audienceMainGenderSchema = z.object({
  code: z.enum(['male', 'female']),
  weight: z.number().nullable().describe('Will be null when locked.'),
})

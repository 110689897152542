import { z } from 'zod'
import { listResourceSchema } from '@/types/schema/api/list-resource-schema'
import { ownerSchema } from '@/types/schema/api/owner-schema'
import { competitiveStaticHashTagsSchema } from '@/types/schema/competitive-analyses/competitive-brand-analyses'
import {
  competitiveMentionAnalysisKolAnalyzeSchema,
  competitiveMentionAnalysisSchema,
  competitiveMentionEffectivenessTrendChartDataSchema,
  competitiveMentionKolFollowerChartDataSchema,
  competitiveMentionKolPropertyChartDataSchema,
  competitiveMentionPostKolSchema,
  competitiveMentionPostSchema,
  competitiveMentionSimilarKolSchema,
} from '@/types/schema/competitive-analyses/competitive-mention-analyses'
import { Potential } from '@/utils/detail/potential'

export const competitiveMentionAnalysisListSchema = listResourceSchema.extend({
  data: competitiveMentionAnalysisSchema.array(),
})
export type CompetitiveMentionAnalysisList = z.infer<
  typeof competitiveMentionAnalysisListSchema
>

export const competitiveMentionAnalysesCreatorListSchema = ownerSchema.array()
export type CompetitiveMentionAnalysesCreatorList = z.infer<
  typeof competitiveMentionAnalysesCreatorListSchema
>

export const fetchCompetitiveMentionKolFollowerChartDataResponseSchema =
  z.object({
    follower_percentage_charts:
      competitiveMentionKolFollowerChartDataSchema.array(),
  })
export type FetchCompetitiveMentionKolFollowerChartDataResponse = z.infer<
  typeof fetchCompetitiveMentionKolFollowerChartDataResponseSchema
>

export const fetchCompetitiveMentionKolPropertyChartDataResponseSchema =
  z.object({
    mention_kol_property_charts:
      competitiveMentionKolPropertyChartDataSchema.array(),
  })
export type FetchCompetitiveMentionKolPropertyChartDataResponse = z.infer<
  typeof fetchCompetitiveMentionKolPropertyChartDataResponseSchema
>
export const fetchCompetitiveMentionSimilarKolListResponseSchema = z.object({
  competitive_mention_similar_kols: competitiveMentionSimilarKolSchema.array(),
})
export const fetchCompetitiveKeywordSimilarKolListResponseSchema = z.object({
  competitive_mention_similar_kols: competitiveMentionSimilarKolSchema.array(),
})
export type FetchCompetitiveMentionSimilarKolListResponse = z.infer<
  typeof fetchCompetitiveMentionSimilarKolListResponseSchema
>

export const competitiveMentionAnalysisKolAnalyzeListResponseSchema =
  listResourceSchema.extend({
    data: z.array(competitiveMentionAnalysisKolAnalyzeSchema),
  })
export type CompetitiveMentionAnalysisKolAnalyzeListResponse = z.infer<
  typeof competitiveMentionAnalysisKolAnalyzeListResponseSchema
>

export const fetchCompetitiveMentionPostKolListResponseSchema = z.object({
  data: competitiveMentionPostKolSchema.array(),
  paging: z.object({
    cursors: z.object({
      after: z.string().nullable(),
      before: z.string().nullable(),
    }),
  }),
})
export type FetchCompetitiveMentionPostKolListResponse = z.infer<
  typeof fetchCompetitiveMentionPostKolListResponseSchema
>

const competitiveMentionTopTenPostSchema = competitiveMentionPostSchema.extend({
  kol_uuid: z.string(),
  kol_name: z.string(),
  kol_potential: z.nativeEnum(Potential).nullable(),
  kol_customized_tags_names: z.string().array(),
  engagement_count: z.number().nullable(),
  social_volume_count: z.number().nullable(),
})
export type CompetitiveMentionTopTenPost = z.infer<
  typeof competitiveMentionTopTenPostSchema
>

export const competitiveMentionTopTenPostListSchema =
  competitiveMentionTopTenPostSchema.array()
export type CompetitiveMentionTopTenPostList = z.infer<
  typeof competitiveMentionTopTenPostListSchema
>

export type CompetitiveMentionStaticHashTagListResponse = z.infer<
  typeof competitiveStaticHashTagsSchema
>

export const fetchCompetitiveMentionEffectivenessTrendChartDataResponseSchema =
  z.object({
    kol_infos: competitiveMentionEffectivenessTrendChartDataSchema.array(),
  })
export type FetchCompetitiveMentionEffectivenessTrendChartDataResponse =
  z.infer<
    typeof fetchCompetitiveMentionEffectivenessTrendChartDataResponseSchema
  >

export type FetchCompetitiveKeywordSimilarKolListResponse = z.infer<
  typeof fetchCompetitiveKeywordSimilarKolListResponseSchema
>

import { z } from 'zod'
import { listResourceSchema } from '@/types/schema/api/list-resource-schema'
import { ownerSchema } from '@/types/schema/api/owner-schema'
import { postCollectionSchema } from '@/types/schema/post-collection'

export const postCollectionListSchema = listResourceSchema.extend({
  data: z.array(postCollectionSchema),
})

export const postCollectionOwnerListSchema = z.array(ownerSchema)

export type PostCollectionList = z.infer<typeof postCollectionListSchema>
export type PostCollectionOwnerList = z.infer<
  typeof postCollectionOwnerListSchema
>

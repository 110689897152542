import { z } from 'zod'

export const kolPostListSorterSchema = z.enum([
  'publishTime',
  'engagement',
  'pageView',
  'likeCount',
  'commentCount',
])
export type KolPostListSorter = z.infer<typeof kolPostListSorterSchema>

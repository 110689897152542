import { useMemo } from 'react'
import { z } from 'zod'
import { I18nId } from '@/i18n/config'
import { PlatformShortcode } from '@/utils/convert-platform'

type UseSectionTabs = (platform?: PlatformShortcode) => TabOption[]

export const tabSchema = z.enum([
  'overview',
  'audienceAnalyses',
  'cooperationAndValue',
  'similarKols',
  'engagementAnalyses',
  'creativeContentAnalyses',
])
export type TabKey = z.infer<typeof tabSchema>
export type TabOption = { key: TabKey; i18n: I18nId }

const tabOptions: Record<TabKey, TabOption> = {
  overview: {
    key: 'overview',
    i18n: 'kol:side_nav_overview',
  },
  audienceAnalyses: {
    key: 'audienceAnalyses',
    i18n: 'kol:side_nav_audience',
  },
  cooperationAndValue: {
    key: 'cooperationAndValue',
    i18n: 'kol:nav_cooperation_and_value',
  },
  similarKols: {
    key: 'similarKols',
    i18n: 'kol:similar_kols',
  },
  engagementAnalyses: {
    key: 'engagementAnalyses',
    i18n: 'kol:side_nav_engagement',
  },
  creativeContentAnalyses: {
    key: 'creativeContentAnalyses',
    i18n: 'kol:nav_creative_content_analyses',
  },
}

const useSectionTabs: UseSectionTabs = (platform) => {
  return useMemo((): TabOption[] => {
    return Object.values(tabOptions).filter(({ key }) => {
      switch (key) {
        case 'overview':
        case 'cooperationAndValue':
        case 'engagementAnalyses':
        case 'creativeContentAnalyses':
          return true
        case 'audienceAnalyses':
          return platform
            ? [
                PlatformShortcode.Instagram,
                PlatformShortcode.Facebook,
                PlatformShortcode.YouTube,
                PlatformShortcode.TikTok,
              ].includes(platform)
            : false
        case 'similarKols':
          return platform
            ? [
                PlatformShortcode.Instagram,
                PlatformShortcode.Facebook,
                PlatformShortcode.YouTube,
              ].includes(platform)
            : false
        default:
          return false
      }
    })
  }, [platform])
}

export default useSectionTabs

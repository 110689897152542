import { SerializedError } from '@reduxjs/toolkit'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

export const isFetchBaseQueryError = (
  error: FetchBaseQueryError | SerializedError,
): error is FetchBaseQueryError => {
  return (error as FetchBaseQueryError).status !== undefined
}

export type BaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  unknown,
  FetchBaseQueryMeta
>

interface IsClient {
  (): boolean
}

/**
 * next 有些地方會自己有 `isServer` 參數可以使用。
 *
 * 如果找不到該參數，那就可以考慮使用這個 util。
 */
export const isClient: IsClient = () => {
  return typeof window !== 'undefined'
}

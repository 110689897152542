type IsExternalOrigin = (referrer?: string, currentOrigin?: string) => boolean

// 預設值僅作用於 client side，如果 server side 需要使用，需自己傳值
const isExternalOrigin: IsExternalOrigin = (
  referrer = document?.referrer,
  currentOrigin = window?.location?.origin,
) => {
  return !referrer.startsWith(currentOrigin)
}

export default isExternalOrigin

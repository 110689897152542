import {
  MOCK_KOL_INFO_PREFIX,
  mockKolInfoList,
} from '@/constants/mock-data/kol-info'

export function getThumbnail(id = ''): string {
  // 如果得到 demo 用假資料的 ID，就回傳對應的 thumbnail
  if (id.includes(MOCK_KOL_INFO_PREFIX)) {
    const mockKolInfo = mockKolInfoList.find(
      (mockKolInfo) => mockKolInfo.id === id,
    )
    if (mockKolInfo) {
      return mockKolInfo.thumbnail
    }
  }

  return `https://thumbnail.kolradar.com/${id}`
}

import { createSlice } from '@reduxjs/toolkit'

interface State {
  initialPathname: string
}

const initialState: State = {
  // 初始化 kol-radar 的頁面，亦即使用者的著陸頁
  initialPathname:
    typeof document !== 'undefined' ? document?.location?.pathname : '',
}

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {},
})

export default routeSlice.reducer

export enum CookieName {
  UserToken = 'userToken',
  LoginTime = 'loginTime',
  Channel = 'channel',
  IsSingleLogin = 'isSingleLogin',
  NeedAuthAgain = 'needAuthAgain',
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmContent = 'utm_content',
  UtmTerm = 'utm_term',
}

export const UTM_KEYS = [
  CookieName.UtmSource,
  CookieName.UtmMedium,
  CookieName.UtmCampaign,
  CookieName.UtmContent,
  CookieName.UtmTerm,
] as const

import { type ResourcesSelectorProps } from '@/components/common/selectors/resources-selector'
import getItemFromSessionStorage from '@/utils/session-storage/get-item-from-session-storage'
import saveItemToSessionStorage from '@/utils/session-storage/save-item-to-session-storage'

const setCollectionList = (list: ResourcesSelectorProps['value']): void =>
  saveItemToSessionStorage('exclude_kol_collection_folder_ids', list)

const getCollectionList = (
  filterList?: string[],
): ResourcesSelectorProps['value'] => {
  const collectionList =
    getItemFromSessionStorage<ResourcesSelectorProps['value']>(
      'exclude_kol_collection_folder_ids',
    ) ?? 'all'

  if (collectionList === 'all') {
    return collectionList
  }

  return collectionList.filter((item) =>
    (filterList ?? []).includes(item.id.toString()),
  )
}

export { setCollectionList, getCollectionList }

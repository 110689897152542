import { UnknownAction } from 'redux'
import { Epic } from 'redux-observable'
import { from, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { AppContext } from '@/api/executor/app-context'
import {
  getSocialAuthStatus,
  setFacebookAuth,
  setFacebookAuthStatus,
} from '@/store/state.socialAuth'
import { RootState } from '@/store/store'
import { Action } from '@/types/redux/social-auth'

const getFacebookAuthStatusEpic: Epic<
  UnknownAction,
  UnknownAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(getSocialAuthStatus.match),
    switchMap((): Observable<Action['SET_FACEBOOK_AUTH_STATUS']> => {
      return from(AppContext.ApiExecutor.getFacebookAuthStatus()).pipe(
        map(({ data }) => {
          const { status } = data
          return setFacebookAuthStatus({ status })
        }),
        catchError(() => {
          return of(setFacebookAuthStatus({ status: '' }))
        }),
      )
    }),
  )

const setFacebookAuthEpic: Epic<UnknownAction, UnknownAction, RootState> = (
  action$,
) =>
  action$.pipe(
    filter(setFacebookAuth.match),
    switchMap(
      (
        action: Action['SET_FACEBOOK_AUTH'],
      ): Observable<Action['GET_SOCIAL_AUTH_STATUS']> => {
        const { accessToken, userID } = action.payload

        return from(
          AppContext.ApiExecutor.setFacebookAuthToken({ accessToken, userID }),
        ).pipe(
          map(() => {
            return getSocialAuthStatus()
          }),
          catchError(() => {
            return of(getSocialAuthStatus())
          }),
        )
      },
    ),
  )

const epics = [getFacebookAuthStatusEpic, setFacebookAuthEpic]

export default epics

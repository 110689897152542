import styled from 'styled-components'

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-space-item {
    height: auto;
    font-size: 14px;
    padding: 0;
    border-radius: 4px;
    width: 160px;
  }
`

export default FilterWrapper

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFetchUserStatusQuery } from '@/api/user-api'
import { useAuth } from '@/hooks/use-auth'
import { fetchNotifications } from '@/store/chatroom'

export const useNotifications = (): VoidFunction => {
  const dispatch = useDispatch()
  const { isAd } = useAuth()
  const { data: userStatus } = useFetchUserStatusQuery()

  const getNotifications = useCallback(() => {
    if (isAd && userStatus?.currentWorkspaceId) {
      dispatch(
        fetchNotifications({
          page: 1,
          perPage: 50,
        }),
      )
    }
  }, [isAd, userStatus?.currentWorkspaceId, dispatch])

  return getNotifications
}

import { Spin } from 'antd'
import React, { FunctionComponent } from 'react'
import { IntersectionObserverHookRefCallback } from 'react-intersection-observer-hook'

interface LoadingElementWithRefProps {
  loadingRef: IntersectionObserverHookRefCallback
}
const LoadingElementWithRef: FunctionComponent<LoadingElementWithRefProps> = ({
  loadingRef,
}) => {
  return (
    <div ref={loadingRef}>
      <Spin />
    </div>
  )
}

export default LoadingElementWithRef

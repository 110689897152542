import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import UnsafeNewTabLink from '@/components/public-page/footer/components/unsafe-new-tab-link'

interface LinkItemProps {
  children: ReactNode
  url: string
  isBlank?: boolean
  visible?: boolean
  onClick?: () => void
}

const LinkItem: FunctionComponent<LinkItemProps> = (props) => {
  const {
    children,
    visible = true,
    isBlank = false,
    url,
    onClick = (): void => {},
  } = props

  if (!visible) {
    return null
  }

  if (isBlank) {
    return (
      <UnsafeNewTabLink as={Item} href={url} onClick={onClick}>
        {children}
      </UnsafeNewTabLink>
    )
  }

  return (
    <Item href={url} onClick={onClick}>
      {children}
    </Item>
  )
}

export default LinkItem

const Item = styled.a`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-size: 16px;
  line-height: 19px;

  :hover {
    color: ${({ theme }): string => theme.colors.text.primary};
  }
`

import { Duration } from '@/components/common/duration-select'
import useKolDetailRoute from '@/components/kol/hooks/use-kol-detail-route'
import { KolDetailDuration } from '@/hooks/use-authorization/routes'
import { usePermissions } from '@/hooks/use-permissions'

export const DEFAULT_DURATION = Duration.ThreeMonths

interface UseKolDetailDuration {
  duration: KolDetailDuration
  enable3MonthDuration: boolean
}

const useKolDetailDuration = (): UseKolDetailDuration => {
  const { withRadarStarPermission, withKolPermission } = usePermissions()
  const { searchParams } = useKolDetailRoute()
  const enable3MonthDuration = withRadarStarPermission || !withKolPermission
  const defaultDuration = enable3MonthDuration
    ? DEFAULT_DURATION
    : Duration.SixMonths

  return {
    duration: searchParams.success
      ? searchParams.data.duration ?? defaultDuration
      : defaultDuration,

    enable3MonthDuration,
  }
}

export default useKolDetailDuration

import { Modal } from 'antd'
import styled from 'styled-components'

/**
 * Represents a basic styled version of the antd Modal component.
 *
 * @class
 * @extends Modal
 *
 */
export const BasicModal = styled(Modal)`
  --ant-modal-header-margin-bottom: 24px;
  --ant-modal-title-font-size: 24px;
`

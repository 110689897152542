import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import { ZodSchema } from 'zod'
import ZeusEnvironment from '@/constants/zeus-environment'
import logger from '@/utils/logger'

type ZodBaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  { dataSchema?: ZodSchema },
  FetchBaseQueryMeta
>

const zodBaseQueryWrapper: (baseQuery: ZodBaseQuery) => ZodBaseQuery =
  (baseQuery: ZodBaseQuery) => async (args, api, extraOptions) => {
    const returnValue = await baseQuery(args, api, extraOptions)

    const zodSchema = extraOptions?.dataSchema

    const { data } = returnValue

    if (data && zodSchema) {
      const parseResult = zodSchema.safeParse(data)

      if (!parseResult.success) {
        logger.error(
          parseResult.error,
          {
            endpoint: api.endpoint,
            data: JSON.stringify(data),
            args: JSON.stringify(args),
          },
          { 'zod-parse-failure': true },
        )

        if (
          process.env.NEXT_PUBLIC_ZEUS_ENVIRONMENT !==
          ZeusEnvironment.Production
        ) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: parseResult.error.message,
            },
          }
        }
      } else {
        return {
          ...returnValue,
          data: parseResult.data,
        }
      }
    }

    return returnValue
  }

export default zodBaseQueryWrapper

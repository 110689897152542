import { noop } from 'lodash-es'
import { useEffect } from 'react'
import {
  useLazyFetchUserMetadataQuery,
  useUpdateUserMetadataMutation,
} from '@/api/user-api'
import migrateData, {
  isLatestVersion,
  resetWithDefaultData,
} from '@/constants/user-metadata'
import { useAuth } from '@/hooks/use-auth'
import { partialUserMetadataSchema } from '@/types/schema/user-metadata'

const useUserMetadataInit = (): void => {
  const { isLogin } = useAuth()
  const [fetchUserMetadata] = useLazyFetchUserMetadataQuery()
  const [updateUserMetadata] = useUpdateUserMetadataMutation()

  useEffect(() => {
    const handleDataMigration = async (): Promise<void> => {
      if (!isLogin) {
        return
      }

      const { data: response } = await fetchUserMetadata()

      // 預防 parse 資料不正確 undefined 的狀態，reset 到已 onboarding 的狀態
      // 第一次登入的 user 會是 {}，不會進入這個判斷
      if (!response?.data) {
        await updateUserMetadata(
          resetWithDefaultData({
            version_no: '1',
            completed_onboarding: true,
          }),
        )
      }

      if (response) {
        const parseData = partialUserMetadataSchema
          .strict()
          .safeParse(response?.data)

        // 確保沒有多餘屬性的髒資料
        if (!parseData.success) {
          await updateUserMetadata(resetWithDefaultData(response.data))
          return
        }

        // 確保是最新版本
        if (!isLatestVersion(parseData.data)) {
          const migratedData = migrateData(response.data)
          await updateUserMetadata(migratedData)
        }
      }
    }

    handleDataMigration().then(noop)
  }, [updateUserMetadata, fetchUserMetadata, isLogin])
}

export default useUserMetadataInit

import sourceLocale from '@/i18n/locales/zh/locale.json'

/** 設定支援哪些語言 */
export enum SupportedLanguages {
  zhTW = 'zh',
  enUS = 'en',
  ja = 'ja',
  ach = 'ach',
}

export const sourceLanguage = SupportedLanguages.zhTW
export const inContextPseudoLanguage = SupportedLanguages.ach

export type I18nId = keyof typeof sourceLocale

export type Messages = Record<I18nId, string | null>

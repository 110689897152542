import { Tag } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import searchOperators, {
  SearchOperator,
} from '@/constants/search/search-operators'
import { useIntl } from '@/i18n/hooks/use-intl'

interface SearchOperatorSuggestionsProps {
  onClick?: (operator: SearchOperator) => void
}

const SearchOperatorSuggestions: FunctionComponent<
  SearchOperatorSuggestionsProps
> = ({ onClick }) => {
  const { formatMessage } = useIntl()

  return (
    <Box>
      <Header>{formatMessage({ id: 'search:insert_operator' })}</Header>
      <Operators>
        {searchOperators.map((operator) => (
          <Tag
            key={operator.label}
            onClick={(event): void => {
              event.stopPropagation()
              onClick?.(operator)
            }}
          >
            {formatMessage({ id: operator.label })}
            <OperatorValue>{operator.value}</OperatorValue>
          </Tag>
        ))}
      </Operators>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  cursor: default;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.border.divider};
  background: ${({ theme }): string => theme.colors.background.white};

  > :not(:last-child) {
    margin-bottom: 8px;
  }
`

const Header = styled.div`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

const Operators = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  .ant-tag {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 0;
    cursor: pointer;
  }
`

const OperatorValue = styled.div`
  color: ${({ theme }): string => theme.colors.brand.primary};
  margin-left: 4px;
`

export default SearchOperatorSuggestions

import { Spin } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const LoadingPage: FunctionComponent = () => {
  return (
    <Spin spinning>
      <Page />
    </Spin>
  )
}

export default LoadingPage

const Page = styled.div`
  height: 100vh;
  width: 100vw;
`

import styled from 'styled-components'

const BackButton = styled.button`
  background: transparent;
  border: none;
  color: ${({ theme }): string => theme.colors.brand.primary};
  cursor: pointer;
  display: block;
  padding: 6px;

  @media (min-width: 768px) {
    display: none;
  }

  .anticon {
    font-size: 20px;
  }
`

export default BackButton

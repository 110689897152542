import { Button, Flex } from 'antd'
import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { BasicModal } from '@/components/common/antd/basic-modal'
import { useAuth } from '@/hooks/use-auth'
import { useI18n } from '@/hooks/use-i18n'
import { useSelector } from '@/hooks/use-selector'
import { useIntl } from '@/i18n/hooks/use-intl'
import { logout } from '@/store/me'
import { setModalVisible } from '@/store/unauthorized'
import { goToPage } from '@/utils/routes/go-to-page'

const UnauthorizedModal: FunctionComponent = () => {
  const { userInfo } = useAuth()
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const { locale } = useI18n()
  const { modalVisible } = useSelector((state) => state.unauthorized)
  const visible = modalVisible && !!userInfo

  const handleClose: VoidFunction = () => {
    dispatch(logout())
    goToPage({
      pathname: '/login',
      query: { lang: locale },
    }).catch((e) => console.warn(e))
    dispatch(setModalVisible(false))
  }

  return (
    <StyledModal
      closable={false}
      footer={null}
      open={visible}
      style={{ top: '15vh' }}
      width={576}
      onCancel={handleClose}
    >
      <Flex vertical align='center' gap={20}>
        <Title>{formatMessage({ id: 'login_expired' })}</Title>
        <Message>{formatMessage({ id: 'login_required_continue' })}</Message>
        <Button type='primary' onClick={handleClose}>
          {formatMessage({ id: 'login_again' })}
        </Button>
      </Flex>
    </StyledModal>
  )
}

const StyledModal = styled(BasicModal)`
  --ant-modal-title-font-size: 38px;
`

const Title = styled.div`
  color: ${({ theme }): string => theme.colors.gray10};
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 42px */
`

const Message = styled.div`
  color: ${({ theme }): string => theme.colors.gray10};
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
`

export default UnauthorizedModal

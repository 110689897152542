import { Select } from 'antd'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import FollowerLevel from '@/types/schema/follower-level'
import { SearchForKolProperties } from '@/utils/ampli'
import {
  followerLevelInfluencerI18nIdMap,
  followerLevelInfluencerRangeI18nIdMap,
} from '@/utils/i18n/follower-level-i18n-id-map'

interface FollowerLevelOption {
  label: I18nId
  value: FollowerLevel
}

export interface FollowerCountRange {
  followerStartFrom: number
  followerEndTo: number
}

interface FollowerLevelSelectorProps {
  value: FollowerLevel
  onChange: (value: FollowerLevel) => void
  isDemo?: boolean
}

export const FOLLOWER_COUNT_RANGE_RECORD: Record<
  FollowerLevel,
  FollowerCountRange
> = {
  level1: {
    //奈米網紅, 0 - 10k
    followerStartFrom: 0,
    followerEndTo: 9999,
  },
  level2: {
    //微型網紅, 10k - 30k
    followerStartFrom: 10000,
    followerEndTo: 29999,
  },
  level3: {
    //小型網紅, 30k - 50k
    followerStartFrom: 30000,
    followerEndTo: 49999,
  },
  level4: {
    //中小型網紅, 50k - 100k
    followerStartFrom: 50000,
    followerEndTo: 99999,
  },
  level5: {
    //中型網紅,100k - 300k
    followerStartFrom: 100000,
    followerEndTo: 299999,
  },
  level6: {
    //中大型網紅, 300k - 500k
    followerStartFrom: 300000,
    followerEndTo: 499999,
  },
  level7: {
    //大型網紅, 500k - 1M
    followerStartFrom: 500000,
    followerEndTo: 999999,
  },
  level8: {
    //百萬網紅, 1M+
    followerStartFrom: 1000000,
    followerEndTo: 999999999,
  },
}

export const EVENT_FOLLOWER_COUNT_RANGE_RECORD: Record<
  FollowerLevel,
  SearchForKolProperties['filterFollowerRange']
> = {
  level1: 'Small (1K - 10K)',
  level2: 'Small (10K - 30K)',
  level3: 'Small (30K - 50K)',
  level4: 'Medium (50K - 100K)',
  level5: 'Medium (100K - 300K)',
  level6: 'Medium (300K - 500K)',
  level7: 'Large (500K - 1M)',
  level8: 'Large (1M+)',
}

export type FollowerCountRangeGroup = 'small' | 'medium' | 'large'

export const FOLLOWER_COUNT_RANGE_GROUP: Record<
  FollowerCountRangeGroup,
  FollowerLevel[]
> = {
  small: ['level1', 'level2', 'level3'],
  medium: ['level4', 'level5', 'level6'],
  large: ['level7', 'level8'],
}

const FollowerRange: FollowerLevelOption[] = [
  { value: 'level1', label: followerLevelInfluencerI18nIdMap.level1 },
  { value: 'level2', label: followerLevelInfluencerI18nIdMap.level2 },
  {
    value: 'level3',
    label: followerLevelInfluencerI18nIdMap.level3,
  },
  {
    value: 'level4',
    label: followerLevelInfluencerI18nIdMap.level4,
  },
  {
    value: 'level5',
    label: followerLevelInfluencerI18nIdMap.level5,
  },
  {
    value: 'level6',
    label: followerLevelInfluencerI18nIdMap.level6,
  },
  {
    value: 'level7',
    label: followerLevelInfluencerI18nIdMap.level7,
  },
  {
    value: 'level8',
    label: followerLevelInfluencerI18nIdMap.level8,
  },
]

const FollowerLevelSelector: FunctionComponent<FollowerLevelSelectorProps> = ({
  value,
  onChange,
  isDemo,
}) => {
  const { formatMessage } = useIntl()
  const options = FollowerRange.map((level) => {
    return {
      disabled: isDemo,
      label: formatMessage({ id: level.label }),
      value: level.value,
    }
  })

  return (
    <StyledSelector
      optionRender={({ label, value: level }) =>
        level
          ? formatMessage({ id: followerLevelInfluencerRangeI18nIdMap[level] })
          : label
      }
      options={options}
      popupMatchSelectWidth={false}
      size='small'
      value={value}
      onChange={onChange}
    />
  )
}

const StyledSelector = styled(Select)`
  min-width: 120px;
`

export default FollowerLevelSelector

import {
  AD_PAGES,
  AGENCY_PAGES,
  KOL_PAGES,
  Page,
  UserType,
} from '@/hooks/use-authorization/constants'

interface IsUserAuthorizedForPage {
  (page: Page, userType: string): boolean
}

const isUserAuthorizedForPage: IsUserAuthorizedForPage = (page, userType) => {
  if (userType === UserType.NormalKol) {
    return KOL_PAGES.includes(page)
  }

  if (userType === UserType.Agency) {
    return AGENCY_PAGES.includes(page)
  }

  if (userType === UserType.NormalAd) {
    return AD_PAGES.includes(page)
  }

  return false
}

export default isUserAuthorizedForPage

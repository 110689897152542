import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UnauthorizedState {
  modalVisible: boolean
}

const initialState: UnauthorizedState = {
  modalVisible: false,
}

const unauthorizedSlice = createSlice({
  name: 'unauthorized',
  initialState,
  reducers: {
    setModalVisible: (state, action: PayloadAction<boolean>) => {
      state.modalVisible = action.payload
    },
  },
})

export const { setModalVisible } = unauthorizedSlice.actions

export default unauthorizedSlice.reducer

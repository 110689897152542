import { Page } from '@/hooks/use-authorization/constants'

interface GetPage {
  (thePage: string): Page | undefined
}

const getPage: GetPage = (thePage) => {
  // 剔除 query string
  const thePageSplittedList = thePage.split('?')[0].split('/')

  return Object.values(Page).find((page) => {
    const pageSplittedList = page.split('/')

    const hasCatchAllSegments = page.includes('[...')
    if (
      pageSplittedList.length !== thePageSplittedList.length &&
      !hasCatchAllSegments
    ) {
      return false
    }

    return pageSplittedList.every((pageSplitted, index) => {
      // 略過 [] 開頭結尾的路徑
      if (pageSplitted.startsWith('[') && pageSplitted.endsWith(']')) {
        return true
      }

      if (pageSplitted === thePageSplittedList[index]) {
        return true
      }

      return false
    })
  })
}

export default getPage

import { omit } from 'lodash-es'
import { useCallback } from 'react'
import { FormatNumberOptions, useIntl as originalUseIntl } from 'react-intl'
import useIsClient from '@/hooks/use-is-client'
import { SupportedLanguages } from '@/i18n/config'
import { getLanguage } from '@/i18n/languages'
import {
  FORMAT_DATE_TYPE_OPTION_MAP,
  FormatDateRangeType,
  FormatDateType,
  OurIntlFormatNumberOptions,
  OurIntlShape,
} from '@/i18n/typing'
import { getPercentageMaximumFractionDigits } from '@/utils/i18n/get-percentage-maximum-fraction-digits'
import logger from '@/utils/logger'
import getLocalizedDiscountRate from '@/utils/subscription-checkout/get-localized-discount-rate'

/**
 * 用來取代 `react-intl.useIntl` 具備 {id} compiler check typing
 */
export const useIntl: () => OurIntlShape = () => {
  const {
    formatNumber: originFormatNumber,
    formatDate: originFormatDate,
    formatMessage: originFormatMessage,
    ...rest
  } = originalUseIntl()

  const isClient = useIsClient()

  const formatDate = useCallback(
    (
      value: Parameters<Intl.DateTimeFormat['format']>[0] | string,
      type?: FormatDateType,
    ): string => {
      return originFormatDate(
        value,
        FORMAT_DATE_TYPE_OPTION_MAP[type ?? 'default'],
      )
    },
    [originFormatDate],
  )

  return {
    formatNumber: useCallback(
      (value, opts): string => {
        // default value
        const clonedOpts: Omit<OurIntlFormatNumberOptions, 'style'> & {
          style?: FormatNumberOptions['style']
        } = {
          maximumFractionDigits: 0,
          ...opts,
        }

        if (Number.isNaN(value) && clonedOpts?.treatNaNAsZero) {
          value = 0
        }

        // 為了避免公開 KD 頁 SSR Error，於是加上 isClient 判斷，待 https://github.com/formatjs/formatjs/issues/4476 修好後可移除
        if (isClient && clonedOpts?.isCompactDisplay) {
          clonedOpts.notation = 'compact'
          clonedOpts.useGrouping = 'always'
          clonedOpts.maximumFractionDigits = 1
        }

        if (clonedOpts?.isPercentageDisplay) {
          clonedOpts.style = 'percent'
          clonedOpts.maximumFractionDigits =
            getPercentageMaximumFractionDigits(value)
        }

        if (clonedOpts?.isDiscountRateDisplay) {
          const language = getLanguage(rest.locale).i18nCode
          const localizedDiscountRate = getLocalizedDiscountRate(
            value,
            language,
          )

          return language === SupportedLanguages.zhTW
            ? `${localizedDiscountRate}`
            : `${localizedDiscountRate}%`
        }

        return originFormatNumber(value, clonedOpts)
      },
      [originFormatNumber, rest.locale, isClient],
    ),
    formatDate,
    formatDateTimeRange: useCallback(
      (from, to, type?: FormatDateRangeType): string => {
        return formatDate(from, type) + ' - ' + formatDate(to, type)
      },
      [formatDate],
    ),
    // 當外部把 undefined 當成 id 傳進來時，useIntl 會拋出錯誤而導致全域太空人，所以我們在這邊接一層，讓這種等級的錯誤發生時不要太空人，僅不顯示文字就好
    formatMessage: useCallback(
      (...x) => {
        try {
          return originFormatMessage(...x) as string
        } catch (e) {
          logger.error(e)
          return ''
        }
      },
      [originFormatMessage],
    ),
    ...omit(rest, ['formatDateTimeRange']),
  }
}

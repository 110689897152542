import { KolSchemaModel } from '@/types/schema/kol-schema'

const NAMESPACE = 'irm'

export interface State {
  importModalVisible: {
    uuid: boolean
    link: boolean
    radar: boolean
    add: boolean
    report: boolean
    campaign: boolean
  }
  selectedKOLIDs: string[]
  selectedKolList: Pick<KolSchemaModel, 'id' | 'name' | 'potential'>[]
}

export const ACTION_TYPES = {
  SET_IMPORT_MODAL_VISIBLE: `${NAMESPACE}#setImportModalVisible`,
  SET_SELECTED_KOL_IDS: `${NAMESPACE}#setSelectedKOLIDs`,
  SET_SELECTED_KOL_LIST: `${NAMESPACE}#setSelectedKolList`,
}

export interface ActionPayload {
  SET_IMPORT_MODAL_VISIBLE: {
    modal: keyof State['importModalVisible']
    visible: boolean
  }
  SET_SELECTED_KOL_IDS: State['selectedKOLIDs']
  SET_SELECTED_KOL_LIST: State['selectedKolList']
}

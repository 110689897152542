import {
  DestinationPlugin,
  Event,
  PluginType,
  Result,
  SpecialEventType,
} from '@amplitude/analytics-types'
import { values } from 'lodash-es'
import sendGoogleAnalyticsEvent from '@/utils/tracking/send-google-analytics-event'

/**
 * Amplitude Typescript Browser SDK plugin for sending Google Analytics 4 events
 */
class SendGoogleAnalyticsEventPlugin implements DestinationPlugin {
  name = 'send-ga4-event'
  type = PluginType.DESTINATION as const

  /**
   * setup() is called on plugin installation
   * example: client.add(new AddEventIdPlugin());
   */
  setup(): Promise<undefined> {
    return Promise.resolve(undefined)
  }

  /**
   * execute() is called on each event instrumented
   * example: client.track('New Event');
   */
  execute(context: Event): Promise<Result> {
    const isTrackEvent = !values(SpecialEventType)
      .map((type) => type.toString())
      .includes(context.event_type)

    if (isTrackEvent) {
      sendGoogleAnalyticsEvent(context)
    }

    return Promise.resolve({
      event: context,
      code: 200,
      message: '',
    })
  }
}

export default SendGoogleAnalyticsEventPlugin

import styled from 'styled-components'

interface TypographyProps {
  color?: string
  size?: number
  weight?: number
  height?: number
}

export const H1 = styled.span.attrs<TypographyProps>((props) => ({
  color: props.color,
  size: props.size,
  weight: props.weight,
  height: props.height,
}))<TypographyProps>`
  color: ${({ color, theme }): string => color ?? theme.colors.text.primary};
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 24px;
`

export const H2 = styled.span.attrs<TypographyProps>((props) => ({
  color: props.color,
  size: props.size,
  weight: props.weight,
  height: props.height,
}))<TypographyProps>`
  color: ${({ color, theme }): string => color ?? theme.colors.text.primary};
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 18px;
`

export const H3 = styled.span.attrs<TypographyProps>((props) => ({
  color: props.color,
  size: props.size,
  weight: props.weight,
  height: props.height,
}))<TypographyProps>`
  color: ${({ color, theme }): string => color ?? theme.colors.text.primary};
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 18px;
`

export const H4 = styled.span.attrs<TypographyProps>((props) => ({
  color: props.color,
  size: props.size,
  weight: props.weight,
  height: props.height,
}))<TypographyProps>`
  color: ${({ color, theme }): string => color ?? theme.colors.text.primary};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 12px;
`

export const H6 = styled.span.attrs<TypographyProps>((props) => ({
  color: props.color,
  size: props.size,
  weight: props.weight,
  height: props.height,
}))<TypographyProps>`
  color: ${({ color, theme }): string => color ?? theme.colors.text.primary};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
`

export const StyleParagraph = styled.span.attrs<TypographyProps>((props) => ({
  color: props.color,
  size: props.size,
  weight: props.weight,
  height: props.height,
}))<TypographyProps>`
  color: ${({ color, theme }): string => color ?? theme.colors.text.primary};
  font-size: ${({ size }: TypographyProps): string => `${size ? size : 14}px`};
  font-weight: ${({ weight }: TypographyProps): string | number =>
    weight ? weight : 'normal'};
  line-height: ${({ height }: TypographyProps): string | number =>
    `${height ? height : 16}px`};
  display: inline-block;
  margin: 0;
`

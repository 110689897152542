import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { z } from 'zod'
import { ActionType } from '@/components/kol/navigation/kol-actions-dropdown'
import type { FetchKolFootprintResourceType } from '@/types/schema/directory/kol-management'
import { VisitKolDrawerProperties } from '@/utils/ampli'

export const tabKeySchema = z.enum([
  'overview',
  'contactInfo',
  'cooperationHistory',
  'quotation',
  'notes',
  'footprint',
  'custom',
])
export type TabKey = z.infer<typeof tabKeySchema>

interface KolCooperationDetailDrawerState {
  open: boolean
  dropdownActions: ActionType[]
  activeTabKey: TabKey
  data: {
    kolId?: string
    at?: VisitKolDrawerProperties['at']
  }
  activeFootprintTabKey: FetchKolFootprintResourceType
}

const initialState: KolCooperationDetailDrawerState = {
  open: false,
  activeTabKey: 'overview',
  activeFootprintTabKey: 'all',
  dropdownActions: [
    'unlock',
    'addKolToCollection',
    'addKolToCampaign',
    'addKolToReport',
    'removeFromDirectory',
  ],
  data: {},
}

const kolCooperationDetailDrawerSlice = createSlice({
  name: 'kolCooperationDetailDrawer',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
    setMetaData: (
      state,
      action: PayloadAction<KolCooperationDetailDrawerState['data']>,
    ) => {
      if (state.data.kolId !== action.payload.kolId) {
        state.activeTabKey = 'overview'
      }

      state.data = action.payload
    },
    setActiveTabKey: (state, action: PayloadAction<TabKey>) => {
      state.activeTabKey = action.payload
    },
    setActiveFootprintTabKey: (
      state,
      action: PayloadAction<FetchKolFootprintResourceType>,
    ) => {
      state.activeFootprintTabKey = action.payload
    },
  },
})

export const {
  setOpen,
  setMetaData,
  setActiveTabKey,
  setActiveFootprintTabKey,
} = kolCooperationDetailDrawerSlice.actions

export default kolCooperationDetailDrawerSlice.reducer

import Image, { ImageProps } from 'next/image'
import React, { FunctionComponent, useState } from 'react'

interface AsyncImageProps extends ImageProps {
  fallbackUrls: string[]
}

const AsyncImage: FunctionComponent<AsyncImageProps> = ({
  src,
  fallbackUrls,
  alt,
  width = 200,
  height = 200,
  ...props
}) => {
  const [imgIndex, setImgIndex] = useState(0)

  const imgSrc = [src, ...fallbackUrls].filter((source) => !!source)

  return (
    <Image
      {...props}
      alt={alt}
      height={height}
      src={imgSrc[imgIndex]}
      width={width}
      onError={(): void => {
        if (imgSrc.length > imgIndex + 1) {
          setImgIndex((prevIndex) => prevIndex + 1)
        }
      }}
    />
  )
}

export default AsyncImage

import { camelizeKeys } from 'humps'
import { api } from '@/api/api'
import { MetaMediaType } from '@/api/meta-api'
import { decamelizeKeys } from '@/utils/humps'
import removeNil from '@/utils/remove-nil'

export type Story = {
  id: string
  mediaType: MetaMediaType
  caption: string
  shortcode: string
  thumbnailUrl?: string
  timestamp: string
  permalink: string
}

interface FetchStoriesRequest {
  instagramBusinessAccount: string
  nextCursor?: string
  startTime?: string
  endTime?: string
}

interface FetchStoriesResponse {
  data: Story[]
  nextCursor?: string
  isLastPage: boolean
}

const instagramApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchStories: build.query<FetchStoriesResponse, FetchStoriesRequest>({
      query: ({ instagramBusinessAccount, ...params }) => {
        return {
          url: `/instagram/${instagramBusinessAccount}/stories`,
          params: decamelizeKeys(removeNil(params)),
        }
      },
      transformResponse: (response: object): FetchStoriesResponse => {
        return camelizeKeys(response) as unknown as FetchStoriesResponse
      },
    }),
  }),
  overrideExisting: false,
})

export const useFetchStoriesQuery = instagramApi.endpoints.fetchStories.useQuery

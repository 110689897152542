import { skipToken } from '@reduxjs/toolkit/query'
import { Avatar, Button, Divider, Select, Spin } from 'antd'
import { find, isEmpty } from 'lodash-es'
import React, { FunctionComponent, useEffect, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import styled from 'styled-components'
import {
  GoogleAccessTokenType,
  useExchangeGoogleAccessTokenMutation,
  useFetchGoogleAccessTokenQuery,
} from '@/api/oauth-api'
import { useFetchPlaylistItemsQuery } from '@/api/youtube-api'
import {
  useFetchVideosQuery,
  useFetchYouTubeChannelsQuery,
  YouTubeChannel,
  YouTubePlaylistItem,
} from '@/api/youtube-data-api'
import AuthPages from '@/components/campaign/kol/add-post-modal/auth-pages'
import { ReAuthWrapper } from '@/components/campaign/kol/add-post-modal/page-selector'
import YouTubePlaylistItemRow from '@/components/campaign/kol/add-post-modal/youtube-playlist-item-row'
import { NoData } from '@/components/kol/detail/no-data'
import useGoogleAuthorization, {
  GoogleAuthorizationScope,
} from '@/hooks/use-google-authorization'
import { useIntl } from '@/i18n/hooks/use-intl'
import { StyledAntdModal } from '@/types/antd/styled-antd'
import { PlatformCode } from '@/utils/convert-platform'

type AddYouTubeVideoProps = {
  onCancel: VoidFunction
  onSelectVideo: (checked: boolean, videoId: string) => void
  selectedVideoId?: string
}

const AddYouTubeVideo: FunctionComponent<AddYouTubeVideoProps> = ({
  onCancel,
  onSelectVideo,
  selectedVideoId,
}) => {
  const { formatMessage } = useIntl()

  const [selectedChannel, setSelectedChannel] = useState<YouTubeChannel>()
  const [shouldShowModalWhenChannelEmpty, setShouldShowModalWhenChannelEmpty] =
    useState(false)
  const [noYouTubeChannelModalVisible, setNoYouTubeChannelModalVisible] =
    useState(false)
  const [pageToken, setPageToken] = useState<string>()
  const [currentPlaylistItemsEtag, setCurrentPlaylistItemsEtag] =
    useState<string>()
  const [playlistItems, setPlaylistItems] = useState<YouTubePlaylistItem[]>([])

  const {
    data: fetchYouTubeAccessTokenResponse,
    isFetching: fetchingYouTubeAccessToken,
  } = useFetchGoogleAccessTokenQuery(GoogleAccessTokenType.YouTube)
  const [
    exchangeAuthorizationCode,
    { isLoading: exchangingYouTubeAuthorizationCode },
  ] = useExchangeGoogleAccessTokenMutation()
  const {
    data: fetchChannelsResponse,
    isFetching: fetchingChannels,
    isError: fetchChannelsFailure,
  } = useFetchYouTubeChannelsQuery(
    {},
    { skip: !fetchYouTubeAccessTokenResponse?.accessToken },
  )
  const {
    data: fetchPlaylistItemsResponse,
    isFetching: fetchingPlaylistItems,
    isError: fetchPlaylistItemsFailure,
  } = useFetchPlaylistItemsQuery(
    selectedChannel
      ? {
          playlistId: selectedChannel.contentDetails.relatedPlaylists.uploads,
          pageToken,
        }
      : skipToken,
  )
  const { data: videosResponse } = useFetchVideosQuery(
    playlistItems.length
      ? {
          id: playlistItems.map((item) => item.snippet.resourceId.videoId),
        }
      : skipToken,
  )

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    onLoadMore: () => {
      setPageToken(fetchPlaylistItemsResponse?.nextPageToken)
    },
    loading: fetchingPlaylistItems,
    disabled: fetchPlaylistItemsFailure,
    hasNextPage: !!fetchPlaylistItemsResponse?.nextPageToken,
  })

  const handleBinding = useGoogleAuthorization({
    scopes: [GoogleAuthorizationScope.YouTube],
    onSuccess: (authorizationCode) => {
      exchangeAuthorizationCode({
        type: GoogleAccessTokenType.YouTube,
        authorizationCode,
      })
        .unwrap()
        .then(() => {
          setShouldShowModalWhenChannelEmpty(true)
        })
    },
  })

  const handleCloseAll = (): void => {
    setNoYouTubeChannelModalVisible(false)
    onCancel()
  }

  useEffect(() => {
    setCurrentPlaylistItemsEtag(undefined)
    setPlaylistItems([])
  }, [selectedChannel])

  useEffect(() => {
    if (fetchChannelsResponse) {
      setSelectedChannel(fetchChannelsResponse.items?.[0])
    }
  }, [fetchChannelsResponse, selectedChannel])

  useEffect(() => {
    if (
      fetchPlaylistItemsResponse?.items &&
      currentPlaylistItemsEtag !== fetchPlaylistItemsResponse.etag
    ) {
      setCurrentPlaylistItemsEtag(fetchPlaylistItemsResponse.etag)
      setPlaylistItems([...playlistItems, ...fetchPlaylistItemsResponse.items])
    }
  }, [currentPlaylistItemsEtag, fetchPlaylistItemsResponse, playlistItems])

  useEffect(() => {
    if (
      !shouldShowModalWhenChannelEmpty ||
      fetchingYouTubeAccessToken ||
      fetchingChannels
    ) {
      return
    }

    if (isEmpty(fetchChannelsResponse?.items)) {
      setNoYouTubeChannelModalVisible(true)
    }

    setShouldShowModalWhenChannelEmpty(false)
  }, [
    fetchingChannels,
    fetchChannelsResponse?.items,
    fetchingYouTubeAccessToken,
    shouldShowModalWhenChannelEmpty,
  ])

  if (fetchingChannels || fetchingYouTubeAccessToken) {
    return (
      <Wrapper>
        <Spin />
      </Wrapper>
    )
  }

  if (
    fetchChannelsFailure ||
    isEmpty(fetchChannelsResponse?.items) ||
    !fetchYouTubeAccessTokenResponse?.accessToken
  ) {
    return (
      <>
        <AuthPages
          handleAuthClick={handleBinding}
          loading={exchangingYouTubeAuthorizationCode}
          platformCode={PlatformCode.YouTube}
        />
        <StyledModal
          footer={[
            <Button key='cancel' onClick={handleCloseAll}>
              {formatMessage({ id: 'general:btn_text_cancel' })}
            </Button>,
            <Button
              key='reset'
              type='primary'
              onClick={(): void => {
                setNoYouTubeChannelModalVisible(false)
              }}
            >
              {formatMessage({ id: 'general:reset_act' })}
            </Button>,
          ]}
          open={noYouTubeChannelModalVisible}
          onCancel={handleCloseAll}
        >
          <NoYouTubeChannelTitle>
            {formatMessage(
              { id: 'general:account_authorization_failed' },
              { platformTitle: 'YouTube' },
            )}
          </NoYouTubeChannelTitle>
          <p>
            {formatMessage({
              id: 'general:account_authorization_failed_insufficient_data',
            })}
          </p>
        </StyledModal>
      </>
    )
  }

  return (
    <Wrapper ref={rootRef}>
      <Title>
        {formatMessage(
          {
            id: 'submit_campaign_case_post:select_post_source',
          },
          {
            platformTitle: 'YouTube',
            sourceName: formatMessage({
              id: 'general:channel',
            }),
          },
        )}
      </Title>
      <Select<string>
        placeholder={formatMessage(
          {
            id: 'submit_campaign_case_post:select_post_source',
          },
          {
            platformTitle: 'YouTube',
            sourceName: formatMessage({
              id: 'general:channel',
            }),
          },
        )}
        style={{ width: '100%' }}
        value={selectedChannel?.id}
        onSelect={(channelId): void => {
          if (channelId === 'RE_AUTH') {
            handleBinding()
          } else {
            setSelectedChannel(
              find(fetchChannelsResponse?.items, {
                id: channelId,
              }),
            )
          }
        }}
      >
        {fetchChannelsResponse?.items?.map((channel) => (
          <Select.Option key={channel.id} value={channel.id}>
            <ChannelInfo>
              <Avatar
                alt={channel.snippet.title}
                size={18}
                src={channel.snippet.thumbnails.default.url}
              />
              <p>{channel.snippet.title}</p>
            </ChannelInfo>
          </Select.Option>
        ))}
        <Select.Option value='RE_AUTH'>
          <ReAuthWrapper>
            {formatMessage({
              id: 'general:account_authorization_failed_reconnect_act',
            })}
          </ReAuthWrapper>
        </Select.Option>
      </Select>
      <Divider />
      <Title>
        {formatMessage(
          {
            id: 'submit_campaign_case_post:select_post_source',
          },
          {
            platformTitle: 'YouTube',
            sourceName: formatMessage({
              id: 'autoreport:content_option_video',
            }),
          },
        )}
      </Title>
      {selectedChannel && (
        <PlaylistItems ref={sentryRef}>
          {!fetchingPlaylistItems && isEmpty(playlistItems) && <NoData />}
          {playlistItems.map((item) => (
            <YouTubePlaylistItemRow
              key={item.snippet.resourceId.videoId}
              playlistItem={item}
              selectedVideoId={selectedVideoId}
              video={find(videosResponse?.items, {
                id: item.snippet.resourceId.videoId,
              })}
              onCheck={onSelectVideo}
            />
          ))}
          {(fetchingPlaylistItems ||
            fetchPlaylistItemsResponse?.nextPageToken) && <Spin />}
        </PlaylistItems>
      )}
    </Wrapper>
  )
}

const StyledModal = styled(StyledAntdModal)`
  .ant-btn {
    height: auto;
    padding: 12px 20px;
  }

  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-close-x {
    font-size: 14px;
  }

  .ant-modal-footer {
    border: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-spin {
    width: 100%;
  }

  .ant-divider-horizontal {
    margin: 12px 0;
  }
`

const ChannelInfo = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 8px;
  }

  p {
    color: ${({ theme }): string => theme.colors.text.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    margin: 0;
  }
`

const Title = styled.p`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  margin: 0;

  :before {
    content: '*';
    margin-right: 4px;
    color: ${({ theme }): string => theme.colors.brand.primaryHover};
  }
`

const PlaylistItems = styled.div`
  max-height: 315px;
  margin: 8px 0 0;
  overflow: scroll;
`

const NoYouTubeChannelTitle = styled.p`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
`

export default AddYouTubeVideo

import { theme } from '@buggy/shared'
import { Select } from 'antd'
import React, { CSSProperties, FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Icon } from '@/components/custom-icon'
import useCountries from '@/components/search/hooks/use-countries'
import { AllowedCountryCode } from '@/constants/country-code'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { reduxAPI } from '@/store/redux-api'
import { setDBPlan } from '@/store/user-plan'

const { Option } = Select

interface CountrySelectProps {
  country: string
  setCountry: (country: string) => void
  defaultCountry: string

  /**
   * If this function provided, it will not show dbPurchase Modal
   */
  onSelectUnavailableCountry?: () => void

  /**
   * todo: remove this, after data is ready. ref: https://www.notion.so/ikala/Prod-Hashtag-626791bfa8b0455193c3671c7bc23c38?pvs=4
   */
  hideNoDataCountry?: boolean
  isDemo?: boolean
  dropdownStyle?: CSSProperties
}

const getCountryI18nId = (country: AllowedCountryCode): I18nId => {
  const countryToI18nId: Record<AllowedCountryCode, I18nId> = {
    tw: 'account:register_region_tw',
    jp: 'account:register_region_jp',
    hk: 'account:register_region_hk',
    my: 'account:register_region_my',
    th: 'account:register_region_th',
    vn: 'account:register_region_vn',
    sg: 'account:register_region_sg',
    kr: 'account:register_region_kr',
  }

  return countryToI18nId[country]
}

const CountrySelect: FunctionComponent<CountrySelectProps> = ({
  country,
  setCountry,
  defaultCountry,
  onSelectUnavailableCountry,
  hideNoDataCountry,
  isDemo,
  dropdownStyle,
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const dbPurchaseModal = reduxAPI.state.modal.hooks.useVisible('dbPurchase')
  const { sortedCountries, pivot, deprecatedPermission, availableCountries } =
    useCountries({ hideNoDataCountry })

  const onSelectCountry = (value: string): void => {
    if (availableCountries.includes(value)) {
      setCountry(value)
    } else {
      dispatch(setDBPlan(value))

      if (onSelectUnavailableCountry) {
        onSelectUnavailableCountry()
        return
      }

      dbPurchaseModal.toggleVisible()
    }
  }

  const unpaidStyle = {
    color: theme.colors.text.placeholder,
  }

  return (
    <StyledSelect
      dropdownStyle={dropdownStyle}
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      size='small'
      value={country}
      onChange={onSelectCountry}
    >
      {sortedCountries.map<React.ReactNode>((searchCountry) => {
        const style =
          deprecatedPermission && searchCountry.sortValue >= pivot
            ? unpaidStyle
            : undefined
        const displayCrown =
          deprecatedPermission &&
          searchCountry.sortValue >= pivot &&
          searchCountry.value !== defaultCountry

        return (
          <Option
            disabled={isDemo}
            key={searchCountry.value}
            style={style}
            value={searchCountry.value}
          >
            <OptionLabel>
              {formatMessage({ id: getCountryI18nId(searchCountry.value) })}
            </OptionLabel>

            {displayCrown && (
              <IconWrapper>
                <Icon type='elIconCrownOutline' />
              </IconWrapper>
            )}
          </Option>
        )
      })}
    </StyledSelect>
  )
}

export default CountrySelect

const OptionLabel = styled.div`
  width: 65px;
  color: ${({ theme }): string => theme.colors.text.secondary};
`

const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    display: flex;
    align-items: center;
  }

  .ant-select-item-option-disabled {
    ${OptionLabel} {
      color: unset;
    }
  }
`

const IconWrapper = styled.span`
  display: inline-block;
  margin: 5px 0 0 5px;
  font-size: 18px;

  path {
    fill: ${({ theme }): string => theme.colors.text.placeholder};
  }
`

import { Empty } from 'antd'
import React, { FunctionComponent } from 'react'
import { useIntl } from '@/i18n/hooks/use-intl'

export const NoData: FunctionComponent = () => {
  const { formatMessage } = useIntl()

  return (
    <Empty
      description={formatMessage({
        id: 'autoreport:chart_label_empty_data',
      })}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  )
}

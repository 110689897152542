import { BasicRequest } from '@/api/api'
import { Nullable } from '@/types/nullable'

export enum BindCreditCardMode {
  Edit = 'edit',
  Add = 'add',
}

export enum PaymentProvider {
  Tappay = 'TAPPAY_BIND_CARD',
  Stripe = 'STRIPE_BIND_CARD',
}

export interface Cardholder {
  phone_number: string
  name: string
  email: string
}

export type BindCreditCardRequest = BasicRequest & {
  mode: BindCreditCardMode
} & (
    | {
        cardholder: Cardholder
        payment_provider: PaymentProvider.Tappay
        provider_parameter: {
          tappay_prime: string
        }
      }
    | {
        payment_provider: PaymentProvider.Stripe
      }
  )

export type VerifyCreditCardRequest = BasicRequest &
  (
    | {
        payment_provider: PaymentProvider.Tappay
        rec_trade_id: string
        auth_code: Nullable<string>
        bank_transaction_id: string
      }
    | {
        payment_provider: PaymentProvider.Stripe
        payment_provider_info: {
          stripe_intent_client_secret: string
        }
      }
  )

export type VerifySubscriptionPaymentTransactionRequest = BasicRequest &
  (
    | {
        payment_provider: PaymentProvider.Tappay
        rec_trade_id: string
        auth_code: Nullable<string>
        bank_transaction_id: string
      }
    | {
        payment_provider: PaymentProvider.Stripe
        payment_provider_info: {
          stripe_intent_client_secret: string
          payment_intent_id: string
        }
      }
  )

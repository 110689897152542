import { z } from 'zod'
import { PlatformType } from '@/types/schema/report-schema'
import { PlatformShortcode } from '@/utils/convert-platform'

export const linkSchema = z.object({
  platform_type: z.nativeEnum(PlatformType),
  type: z.nativeEnum(PlatformShortcode),
  url: z.string().url(),
  name_list: z.string().array(),
  description: z.string().min(1).nullable().catch(null),
})

export type Link = z.infer<typeof linkSchema>

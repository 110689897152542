import { Avatar } from 'antd'
import { CSSProperties, FunctionComponent, useMemo } from 'react'

interface AvatarImageProps {
  imgSrc?: string
  name?: string
  deactivate?: boolean
  size?: number | 'small' | 'default' | 'large'
  style?: CSSProperties | undefined
}

export const AvatarImage: FunctionComponent<AvatarImageProps> = ({
  name = '',
  deactivate = false,
  imgSrc,
  size = 'default',
  style,
}) => {
  const backgroundColor = useMemo<string>(() => {
    if (imgSrc) {
      return 'transparent'
    }
    return deactivate ? '#BFBFBF' : '#E87D75'
  }, [deactivate, imgSrc])

  return (
    <Avatar
      size={size}
      src={imgSrc}
      style={{
        backgroundColor,
        verticalAlign: 'middle',
        ...style,
      }}
    >
      {name[0]?.toUpperCase()}
    </Avatar>
  )
}

import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { AppContextType } from 'next/dist/shared/lib/utils'
import React, { ReactNode } from 'react'
import { Provider } from 'react-redux'
import AppLayout from '@/components/layout/app-layout'
import { OPEN_GRAPH_PAGES, Page } from '@/hooks/use-authorization/constants'
import getPage from '@/hooks/use-authorization/get-page'
import { getLanguageCode, getMessagesByLocale } from '@/hooks/use-i18n'
import sourceLocale from '@/i18n/locales/zh/locale.json'
import { initStore } from '@/store/store'

type NextLayoutPage<P = NonNullable<unknown>> = NextPage<P> & {
  getLayout?: (page: ReactNode, pageProps?: PageProps) => ReactNode
}

export interface PageProps {
  pathname: string
  messages: Record<string, string>
}

type Props = AppProps<PageProps> & {
  Component: NextLayoutPage
}

export const HomeApp = ({ Component, pageProps }: Props): ReactNode => {
  const store = initStore()

  return (
    <Provider store={store}>
      <AppLayout Component={Component} pageProps={pageProps} />
    </Provider>
  )
}

HomeApp.getInitialProps = async (
  context: AppContextType,
): Promise<{
  pageProps: PageProps
}> => {
  if (typeof context.router.query.lang !== 'string') {
    return {
      pageProps: { pathname: context.router.pathname, messages: sourceLocale },
    }
  }

  const locale = getLanguageCode(context.router.query.lang)
  const messages = OPEN_GRAPH_PAGES.includes(
    getPage(context.router.pathname) ?? Page.Default,
  )
    ? await getMessagesByLocale(locale)
    : sourceLocale
  return { pageProps: { pathname: context.router.pathname, messages } }
}

export default HomeApp

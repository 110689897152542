import { z } from 'zod'
import { TappayStatusCode } from '@/api/tappay/tappay-api'

export const tappayPaymentTransactionUrlQuerySchema = z.object({
  auth_code: z.string().nullable(),
  rec_trade_id: z.string(),
  bank_transaction_id: z.string(),
})

export const tappayBindCreditCardResponseSchema = z.object({
  status: z.literal(TappayStatusCode.Success),
  redirect_url: z.string(),
})

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Icon } from '@/components/custom-icon'
import { useIntl } from '@/i18n/hooks/use-intl'

export const KolSearchDefault: FunctionComponent<{ keyword: string }> = ({
  keyword,
}) => {
  const { formatMessage } = useIntl()

  return (
    <SearchDefaultWrapper>
      <Icon type='search' />
      <SearchDefaultText>
        {formatMessage(
          { id: 'search:search_dropdown_default_text' },
          { keyword: <SearchDefaultKeyword>{keyword}</SearchDefaultKeyword> },
        )}
      </SearchDefaultText>
    </SearchDefaultWrapper>
  )
}

const SearchDefaultText = styled.div`
  color: #6f6e6e;
`

const SearchDefaultKeyword = styled.span`
  color: #e87d75;
  white-space: normal;
  word-break: break-word;
`

const SearchDefaultWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;

  .anticon {
    margin: 0 10px 0 0;
  }
`

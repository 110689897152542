import {
  Badge,
  Dropdown,
  Empty,
  Menu,
  notification as antdNotification,
  Spin,
} from 'antd'
import dayjs from 'dayjs'
import React, { FunctionComponent } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useDispatch } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { FeatureFlag } from '@/api/user-api'
import { KolThumbnail } from '@/components/common/kol-thumbnail'
import { H6 } from '@/components/common/typography'
import { Icon } from '@/components/custom-icon'
import { DATE_TIME_SHORT_FORMAT } from '@/constants/date-format'
import useFeatureFlags from '@/hooks/use-feature-flags'
import { useSelector } from '@/hooks/use-selector'
import { useIntl } from '@/i18n/hooks/use-intl'
import { CampaignTab } from '@/store/campaign'
import { fetchNotifications } from '@/store/chatroom'
import { getThumbnail } from '@/utils/get-thumbnails'
import { getMilliseconds } from '@/utils/nanoseconds'
import { goToPage } from '@/utils/routes/go-to-page'

const DEFAULT_PARAMS = {
  page: 1,
  perPage: 50,
}

interface MessageDropdownProps {
  className?: string
}

const MessageDropdown: FunctionComponent<MessageDropdownProps> = ({
  className,
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const { withFeature } = useFeatureFlags()
  const { notification, apiStatus } = useSelector((state) => state.chatroom)
  const loading = apiStatus.fetchNotifications.requesting
  const hasNextPage = notification.totalPage > notification.page

  const getNotifications = (params = DEFAULT_PARAMS): void => {
    dispatch(fetchNotifications(params))
  }

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () =>
      getNotifications({ ...DEFAULT_PARAMS, page: notification.page + 1 }),
    rootMargin: '2px 0px 2px 10px',
  })

  useUpdateEffect(() => {
    if (apiStatus.fetchNotifications.failure) {
      antdNotification.error({
        message: 'fetchNotifications error',
        placement: 'bottomRight',
      })
    }
  }, [apiStatus.fetchNotifications.failure])

  /**
   * @todo use Link
   */
  const handleGoToChatroom = (channelId: string): void => {
    const campaignId = channelId.split('.campaign')[1].split('_')[0]

    if (campaignId) {
      goToPage({
        pathname: '/campaign-list/detail',
        query: {
          campaignId,
          tab: CampaignTab.chatroom,
          conversationID: channelId,
        },
      })
    }
  }

  const formatTime = (timestamp: number): string => {
    const time = dayjs(getMilliseconds(timestamp)).format(
      DATE_TIME_SHORT_FORMAT,
    )
    const now = dayjs()
    const hoursDiff = now.diff(time, 'hours')
    const minutesDiff = now.diff(time, 'minutes')

    if (hoursDiff >= 24) {
      return time
    }

    if (minutesDiff >= 60) {
      return formatMessage({ id: 'campaign:hours_ago' }, { h: hoursDiff })
    }

    if (minutesDiff > 0) {
      return formatMessage({ id: 'campaign:minutes_ago' }, { m: minutesDiff })
    }

    return 'now'
  }

  const menu = (
    <Menu>
      {notification.unreadMessages.length === 0 && !loading && (
        <Menu.Item>
          <UnreadMessageEmpty
            description={formatMessage({
              id: 'campaign:no_unread_messages',
            })}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </Menu.Item>
      )}
      {notification.unreadMessages.map((data) => {
        const { notificationTimestamp, channelId, campaignName, kol } = data

        return (
          <Menu.Item key={channelId}>
            <ItemWrapper onClick={(): void => handleGoToChatroom(channelId)}>
              <KolThumbnail src={getThumbnail(kol.uuid)} />

              <div>
                <Message>
                  {formatMessage(
                    { id: 'campaign:message_notification' },
                    {
                      kol: <b>{kol.name}</b>,
                      campaign: <b>{campaignName}</b>,
                    },
                  )}
                </Message>
                <Time>{formatTime(notificationTimestamp)}</Time>
              </div>
            </ItemWrapper>
          </Menu.Item>
        )
      })}

      {(loading || hasNextPage) && (
        <Menu.Item>
          <LoadingWrapper ref={sentryRef}>
            <Spin />
          </LoadingWrapper>
        </Menu.Item>
      )}
    </Menu>
  )

  if (!withFeature(FeatureFlag.DualWheel)) {
    return null
  }

  return (
    <Dropdown
      className={className}
      overlay={menu}
      placement='bottomCenter'
      trigger={['click']}
    >
      <Button onClick={(e): void => e.preventDefault()}>
        <Icon type='elIconMessageCircle' />
        <Badge count={notification.unreadMessages.length}>
          <H6>{formatMessage({ id: 'campaign:campaign_message' })}</H6>
        </Badge>
      </Button>
    </Dropdown>
  )
}

export default styled(MessageDropdown)``

const Button = styled.div`
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  color: #3a3d4c;
  display: flex;
  align-items: center;

  .anticon {
    margin-right: 8px;
  }
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;

  > div:first-child {
    width: 40px;
    height: 40px;
    margin: 0 8px 0 0;
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
`

const Message = styled.div`
  font-size: 14px;
  line-height: 140%;
  max-width: 248px;
  word-break: break-all;
  white-space: pre-line;
`

const Time = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #bfbfbf;
  margin: 4px 0 0;
`

const UnreadMessageEmpty = styled(Empty)`
  margin: 11px 116px;
`

import * as kolData from '@/store/kol-detail'
import * as irm from '@/store/state.irm'
import * as modal from '@/store/state.modal'
import * as report from '@/store/state.report'
import * as userPlan from '@/store/user-plan'

export const reduxAPI = {
  state: {
    irm,
    kolData,
    modal,
    report,
    userPlan,
  },
}

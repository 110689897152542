import { z } from 'zod'
import { platformSchema } from '@/types/schema/api/platform'

export const competitiveAnalysisCrawlerStatusSchema = z.object({
  crawler_job_status: z.enum(['running', 'failed', 'succeeded']).nullable(),
  is_triggerable: z.boolean(),
})
export type CompetitiveAnalysisCrawlerStatus = z.infer<
  typeof competitiveAnalysisCrawlerStatusSchema
>
export const competitiveKolStatisticSchema = z.object({
  starred_kol: z.boolean(), // 主要品牌 true or false
  kol_name: z.string(),
  uuid: z.string(),
  platforms: platformSchema.array(),
  follower: z.number().nullable(),
  follower_count_growth_rate: z.number().nullable(),
  avg_like_count: z.number().nullable(),
  avg_comment_count: z.number().nullable(),
  avg_share_count: z.number().nullable(),
  avg_view_count: z.number().nullable(),
  view_rate: z.number().nullable(),
  avg_engagement_count: z.number().nullable(),
  engagement_rate: z.number().nullable(),
  engagement_count: z.number().nullable(),
  post_count: z.number().nullable(),
})
export type CompetitiveKolStatistic = z.infer<
  typeof competitiveKolStatisticSchema
>

const parseFloatMetric = (floatMetric: string | null): string => {
  const num = floatMetric ? parseFloat(floatMetric) : 0
  if (isNaN(num)) {
    return '0'
  }

  return num.toFixed(2)
}

const trendDataSchema = z.object({
  key: z.string(),
  from: z.string().datetime(),
  to: z.string().datetime(),
  post_count: z.number().nullable(),
  engagement_count: z.number().nullable(),
  engagement_rate: z.string().nullable().transform(parseFloatMetric),
  view_rate: z.string().nullable().transform(parseFloatMetric),
  avg_like_count: z.string().nullable(),
  avg_comment_count: z.string().nullable(),
  avg_view_count: z.string().nullable(),
  avg_share_count: z.string().nullable(),
  avg_engagement_count: z.string().nullable(),
  reels_engagement_rate: z.string().nullable().transform(parseFloatMetric),
  value_evaluation: z.string().nullable(),
})

export type TrendData = z.infer<typeof trendDataSchema>

const competitiveKolListEffectiveSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  starred: z.boolean(),
  trend_data: trendDataSchema.array(),
})

export const competitiveKolListEffectiveChartSchema = z.object({
  kol_infos: competitiveKolListEffectiveSchema.array(),
})

const kol = z.object({
  uuid: z.string(),
  name: z.string(),
  starred_kol: z.boolean(),
})

export const competitiveKolListChartsSchema = z.object({
  follower_count_charts: z.array(
    kol.extend({
      follower_count: z.number().nullable(),
    }),
  ),
  follower_count_growth_rate_charts: z.array(
    kol.extend({
      follower_count_growth_rate: z.number().nullable(),
    }),
  ),
  avg_view_rate_charts: z.array(
    kol.extend({
      avg_view_rate: z.number().nullable(),
    }),
  ),
  avg_engagement_rate_charts: z.array(
    kol.extend({
      avg_engagement_rate: z.number().nullable(),
    }),
  ),
})

const hashTagSchema = z.object({
  hashtag: z.string(),
  count: z.number(),
  engagement_count: z.number(),
  hashtag_id: z.string().nullable(),
  hashtag_status: z.enum(['running', 'failed', 'succeeded']).nullable(),
})

export type Hashtag = z.infer<typeof hashTagSchema>

export const competitiveStaticHashTagsSchema = hashTagSchema.array()

export const competitiveKolListMetricChartsSchema = z
  .object({
    uuid: z.string(),
    name: z.string(),
    starred_kol: z.boolean(),
    metric_value: z.number().nullable(),
  })
  .array()

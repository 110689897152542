import { Button, Layout, notification } from 'antd'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect } from 'react'
import { useMount } from 'react-use'
import styled from 'styled-components'
import errorImgSrc from '@/assets/images/miss.jpg'
import { H3, H6 } from '@/components/common/typography'
import { Header } from '@/components/layout/header'
import { HeaderWithWorkspace } from '@/components/layout/header-with-workspace'
import { CookieName } from '@/constants/cookie-name'
import { useAuth } from '@/hooks/use-auth'
import { useI18n } from '@/hooks/use-i18n'
import * as userAPI from '@/hooks/user-api'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli } from '@/utils/ampli'
import { goToPage } from '@/utils/routes/go-to-page'

interface ErrorPageProps {
  onReset?: () => void
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({ onReset }) => {
  const { formatMessage } = useIntl()
  const [, logout] = userAPI.hooks.useLogoutFn()
  const { locale } = useI18n()
  const router = useRouter()
  const { isAd } = useAuth()

  useMount(() => {
    ampli.somethingWentWrong({ path: router.asPath })
  })

  useEffect(() => {
    if (!onReset) {
      return
    }
    router.events.on('routeChangeComplete', onReset)
    return () => {
      router.events.off('routeChangeComplete', onReset)
    }
  }, [router.events, onReset])

  const onLogout: VoidFunction = () => {
    logout()
      .then(() => {
        ampli.logoutSuccess({
          isSingleLogin: Boolean(Cookies.get(CookieName.IsSingleLogin)),
        })
        Cookies.remove(CookieName.IsSingleLogin)

        notification.success({
          message: formatMessage({ id: 'general:logout_successfully' }),
          placement: 'bottomRight',
        })
      })
      .catch((error) => console.warn(error))
    goToPage({
      pathname: '/login',
      query: { lang: locale },
    }).catch((error) => console.warn(error))
  }

  const onReload: VoidFunction = () => {
    ampli.refreshPage({ path: router.asPath })
    location.reload()
  }

  return (
    <Layout>
      {isAd ? <HeaderWithWorkspace /> : <Header />}
      <Wrapper>
        <ErrorContainer>
          <StyledImage src={errorImgSrc} />
          <TextWrapper>
            <H3>{formatMessage({ id: 'error:something_wrong_title' })}</H3>
            <H6>{formatMessage({ id: 'error:refresh_again_try_again' })}</H6>
            <ButtonWrapper>
              <Button type='primary' onClick={onReload}>
                {formatMessage({ id: 'error:error_page_refresh_button' })}
              </Button>
              <Button ghost type='primary' onClick={onLogout}>
                {formatMessage({ id: 'error:error_page_login_button' })}
              </Button>
            </ButtonWrapper>
          </TextWrapper>
        </ErrorContainer>
      </Wrapper>
    </Layout>
  )
}

export default ErrorPage

const Wrapper = styled.div`
  margin: 100px 0;
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ErrorContainer = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  > * {
    width: 50%;
    margin: 25px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

const StyledImage = styled.img`
  width: 475px;
  height: auto;

  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 767px) {
    text-align: center;
    align-items: center;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin: 30px 0 0 0;
  button {
    margin: 0 15px 0 0;
  }
`

import { I18nId } from '@/i18n/config'
import { ClickToAddBooleanSyntaxProperties } from '@/utils/ampli'

const searchOperatorValues = ['""', 'OR', 'AND', '-', '{}'] as const
export type SearchOperatorValue = (typeof searchOperatorValues)[number]

export interface SearchOperator {
  label: I18nId
  value: SearchOperatorValue
  description: ClickToAddBooleanSyntaxProperties['booleanSyntax']
}

const searchOperators: SearchOperator[] = [
  {
    label: 'search:operator_exact_match',
    value: '""',
    description: '完全符合',
  },
  {
    label: 'search:operator_union',
    value: 'OR',
    description: '聯集',
  },
  {
    label: 'search:operator_intersection',
    value: 'AND',
    description: '交集',
  },
  {
    label: 'search:operator_exclude',
    value: '-',
    description: '排除',
  },
  {
    label: 'search:operator_group',
    value: '{}',
    description: '群組',
  },
]

export default searchOperators

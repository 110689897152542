import {
  AutoComplete,
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  ModalProps,
  Select,
  Upload,
} from 'antd'
import { AutoCompleteProps } from 'antd/lib/auto-complete'
import { ButtonProps } from 'antd/lib/button'
import { CollapseProps } from 'antd/lib/collapse'
import { DividerProps } from 'antd/lib/divider'
import { FormItemProps, FormProps } from 'antd/lib/form'
import { SearchProps } from 'antd/lib/input'
import { GroupProps } from 'antd/lib/input/Group'
import { BasicProps } from 'antd/lib/layout/layout'
import { SelectProps } from 'antd/lib/select'
import { UploadProps } from 'antd/lib/upload'
import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'

type Children = ReactNode

export type StyledProps<T> = {
  children?: Children
  style?: CSSProperties
} & T

export const StyledAntdModal = styled(Modal)<StyledProps<ModalProps>>``
export const StyledAntdForm = styled(Form)<StyledProps<FormProps>>``
export const StyledAntdFormItem = styled(Form.Item)<StyledProps<FormItemProps>>`
  .has-error .ant-btn {
    border-left-color: #f5222d;
  }
`
export const StyledAntdSelect = styled(Select)<StyledProps<SelectProps>>``
export const StyledAntdSearch = styled(Input.Search)<StyledProps<SearchProps>>``
export const StyledAntdCollapse = styled(Collapse)<StyledProps<CollapseProps>>``

/**
 * Todo: Remove workaround when upgrade antd to v4.20.7 or above
 */
export const StyledAntdInputGroup = styled(Input.Group)<
  StyledProps<GroupProps>
>`
  // WORKAROUND: Fix Input.Group 底下的 Input 在 Hover, Focus 時紅框會缺邊, 在新版（4.20.7)的 antd 都是將 z-index 改 1 所以直接照抄
  .ant-input-number:hover,
  .ant-input-number-focused,
  .ant-select:first-child:hover,
  .ant-select-focused,
  .ant-cascader-picker,
  .ant-cascader-picker:hover {
    z-index: 1;
  }

  .ant-cascader-picker-focused,
  .ant-input-number-focused {
    margin-right: 0;
  }
`
export const StyledAntdAutoComplete = styled(AutoComplete)<
  StyledProps<AutoCompleteProps>
>``
export const StyledAntdButton = styled(Button)<StyledProps<ButtonProps>>``
export const StyledAntdUpload = styled(Upload)<StyledProps<UploadProps>>``
export const StyledAntdDivider = styled(Divider)<StyledProps<DividerProps>>``
export const StyledHeader = styled(Layout.Header)<StyledProps<BasicProps>>``
export const StyledFooter = styled(Layout.Footer)<StyledProps<BasicProps>>``
export const StyledContent = styled(Layout.Content)<StyledProps<BasicProps>>``

import { theme } from '@buggy/shared'
import { Col, ConfigProvider, Flex, Input, Radio, Slider } from 'antd'
import { InputProps } from 'antd/lib/input'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { toNumber, toPairs } from 'lodash-es'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import {
  FOLLOWER_COUNT_RANGE_GROUP,
  FOLLOWER_COUNT_RANGE_RECORD,
} from '@/components/kol/common/follower-level-selector'
import { FollowerFilter } from '@/constants/search/follower-filter'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'
import { ampli } from '@/utils/ampli'
import {
  followerLevelInfluencerRangeI18nIdMap,
  followerRangeGroupI18nIdMap,
} from '@/utils/i18n/follower-level-i18n-id-map'
import getFollowerLevelByFollowerCountRange from '@/utils/kol/get-follower-level-by-follower-count-range'

interface FansSelectorContentProps {
  filterValues: Pick<Condition, 'follower_start_from' | 'follower_end_to'>
  onValuesChange: (changedValues: Condition) => void
  horizontal?: boolean
}

const FansSelectorContent: FunctionComponent<FansSelectorContentProps> = ({
  filterValues,
  onValuesChange,
  horizontal,
}) => {
  const { formatMessage } = useIntl()

  const currentFromValue = useMemo(
    () => filterValues.follower_start_from ?? FollowerFilter.LowerBound,
    [filterValues.follower_start_from],
  )

  const currentEndValue = useMemo(
    () => filterValues.follower_end_to ?? FollowerFilter.UpperBound,
    [filterValues.follower_end_to],
  )

  const onFollowerSliderChange = ([from, end]: [number, number]): void => {
    ampli.fansSelector({ type: 'slider' })
    handleValuesChange(from, end)
  }

  const handleSelectRange = (e: RadioChangeEvent): void => {
    const minMaxString = e.target.value

    const [fromValue, endValue] = minMaxString
      .split('~')
      .map((value?: string | number) => {
        if (value === 'undefined') {
          return undefined
        }
        return value
      })
    handleValuesChange(toNumber(fromValue), toNumber(endValue))
  }

  const handleValuesChange = useCallback(
    (fromValue?: number, endValue?: number): void => {
      const from = fromValue ?? FollowerFilter.LowerBound
      const end = endValue ?? FollowerFilter.UpperBound

      const queryCondition =
        from === FollowerFilter.LowerBound && end === FollowerFilter.UpperBound
          ? {
              follower_start_from: undefined,
              follower_end_to: undefined,
            }
          : {
              follower_start_from: from,
              follower_end_to: end,
            }

      const followerRangeLevel = getFollowerLevelByFollowerCountRange({
        followerStartFrom: queryCondition.follower_start_from,
        followerEndTo: queryCondition.follower_end_to,
      })

      onValuesChange({
        ...queryCondition,
        followerRangeLevel,
      })
    },
    [onValuesChange],
  )

  const handleOnInputBlur = (): void => {
    if (currentFromValue > currentEndValue) {
      handleValuesChange(currentEndValue, currentFromValue)
    }
  }

  return (
    <SelectWrapper>
      <InputWrapper>
        <StyledInput
          min={FollowerFilter.LowerBound}
          type='number'
          value={currentFromValue}
          onBlur={handleOnInputBlur}
          onChange={(event): void => {
            const maxFromValue = Math.min(
              toNumber(event.target.value),
              FollowerFilter.UpperBound,
            )

            handleValuesChange(maxFromValue, currentEndValue)
          }}
        />
        <Hyphen> - </Hyphen>
        <StyledInput
          min={FollowerFilter.LowerBound}
          type='number'
          value={currentEndValue}
          onBlur={handleOnInputBlur}
          onChange={(event): void => {
            handleValuesChange(currentFromValue, toNumber(event.target.value))
          }}
        />
        <Slider
          range
          max={FollowerFilter.UpperBound}
          min={FollowerFilter.LowerBound}
          value={[
            currentFromValue ?? FollowerFilter.LowerBound,
            currentEndValue ?? FollowerFilter.UpperBound,
          ]}
          onChange={onFollowerSliderChange}
        />
        <ConfigProvider
          theme={{
            components: {
              Radio: { paddingXS: 0, wrapperMarginInlineEnd: 0 },
            },
          }}
        >
          <StyledRadioGroup
            value={`${currentFromValue}~${currentEndValue}`}
            onChange={handleSelectRange}
          >
            <UnlimitedRadio
              $horizontal={horizontal}
              value={`${FollowerFilter.LowerBound}~${FollowerFilter.UpperBound}`}
            >
              {formatMessage({ id: 'search:filter_option_percent_any' })}
            </UnlimitedRadio>
            <Flex justify='space-between' vertical={!horizontal}>
              {toPairs(FOLLOWER_COUNT_RANGE_GROUP).map(
                ([rangeGroup, levels]) => {
                  return (
                    <div key={rangeGroup}>
                      <RadiosContainerTitle>
                        {formatMessage({
                          id: followerRangeGroupI18nIdMap[rangeGroup],
                        })}
                      </RadiosContainerTitle>
                      <RadiosContainer>
                        {levels.map((level) => {
                          const rangeRecord = FOLLOWER_COUNT_RANGE_RECORD[level]
                          return (
                            <Radio
                              key={rangeRecord.followerEndTo}
                              value={`${rangeRecord.followerStartFrom}~${rangeRecord.followerEndTo}`}
                            >
                              {formatMessage({
                                id: followerLevelInfluencerRangeI18nIdMap[
                                  level
                                ],
                              })}
                            </Radio>
                          )
                        })}
                      </RadiosContainer>
                    </div>
                  )
                },
              )}
            </Flex>
          </StyledRadioGroup>
        </ConfigProvider>
      </InputWrapper>
    </SelectWrapper>
  )
}

export default FansSelectorContent

const RadiosContainerTitle = styled(Col)`
  font-weight: 700;
  line-height: 22px;
  margin: 20px 0;
`

const RadiosContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const UnlimitedRadio = styled(Radio)<{ $horizontal?: boolean }>`
  bordertop: ${({ $horizontal }): string =>
    $horizontal ? `1px solid ${theme.colors.border.divider}` : ''};
  paddingtop: '20px';
`

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;

  span.ant-wave-target {
    margin-right: 4px;
  }
`

const StyledInput = styled((props: InputProps) => <Input {...props} />)`
  width: 45%;
`

const InputWrapper = styled.div`
  .ant-slider-track {
    background-color: ${({ theme }): string => theme.colors.brand.primary};
  }

  .ant-slider-handle {
    border-color: ${({ theme }): string => theme.colors.brand.primary};
  }
`

const Hyphen = styled.span`
  padding: 3px 2%;
`

const SelectWrapper = styled.div`
  width: 100%;
`

import { useGoogleLogin } from '@react-oauth/google'

export enum GoogleAuthorizationScope {
  Analytics = 'https://www.googleapis.com/auth/analytics.readonly',
  YouTube = 'https://www.googleapis.com/auth/youtube.readonly',
}

type UseGoogleAuthorizationOptions = {
  scopes: GoogleAuthorizationScope[]
  onSuccess?: (code: string) => void
}

const useGoogleAuthorization = (
  options: UseGoogleAuthorizationOptions,
): (() => void) =>
  useGoogleLogin({
    onSuccess: (codeResponse) => {
      options.onSuccess?.(codeResponse.code)
    },
    onError: (error) => {
      console.error('Google auth failed:', error)
    },
    scope: options.scopes.join(' '),
    redirect_uri: 'postmessage',
    flow: 'auth-code',
  })

export default useGoogleAuthorization

import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Space from '@/components/common/antd-v4-backport/space'
import { Icon } from '@/components/custom-icon'
import {
  convertPlatformShortcode,
  PlatformShortcode,
} from '@/utils/convert-platform'

interface InvolvedPlatformsProps {
  platforms: PlatformShortcode[]
}

const InvolvedPlatforms: FunctionComponent<InvolvedPlatformsProps> = ({
  platforms,
}) => {
  return (
    <PlatformInvolved>
      {platforms.map((platformShortcode) => {
        const platform = convertPlatformShortcode(platformShortcode)
        return (
          platform.icon && <Icon key={platformShortcode} type={platform.icon} />
        )
      })}
    </PlatformInvolved>
  )
}

export default InvolvedPlatforms
const PlatformInvolved = styled(Space)`
  .anticon {
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
`

import { z } from 'zod'
import { postCollectionSchema } from '@/types/schema/post-collection'

export const postCollectionResponseSchema = postCollectionSchema.omit({
  query_collected_posts: true,
})

export type FetchPostCollectionResponse = z.infer<
  typeof postCollectionResponseSchema
>

import { api } from '@/api/api'
import {
  CustomizedTagListResponse,
  customizedTagListSchema,
} from '@/types/schema/api/search'

const customizedTagApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchCustomizedTagList: build.query<CustomizedTagListResponse, void>({
      query: () => {
        return `/customized-tags`
      },
      extraOptions: {
        dataSchema: customizedTagListSchema,
      },
    }),
  }),
  overrideExisting: false,
})

export const { useFetchCustomizedTagListQuery } = customizedTagApi

import { I18nId } from '@/i18n/config'

enum GeneralTagCode {
  Gaming = 'gaming',
  BeautyAndFashion = 'beauty_and_fashion',
  Health = 'health',
  Food = 'food',
  ArtAndEntertainment = 'art_and_entertainment',
  Sports = 'sports',
  BusinessAndEconomics = 'business_and_economics',
  FamilyAndRelationships = 'family_and_relationships',
  Technology = 'technology',
  LawPoliticsAndSociety = 'law_politics_and_society',
  Travel = 'travel',
  EducationWorkAndLearning = 'education_work_and_learning',
  Pets = 'pets',
  Lifestyle = 'lifestyle',
  ReligionAndFortunetelling = 'religion_and_fortunetelling',
  Adult = 'adult',
  Vehicles = 'vehicles',
  SmallTalkNonsense = 'small_talk_nonsense',
}

export const getI18nId = (generalTagCode: GeneralTagCode): I18nId => {
  return `general:general_tag_code_${generalTagCode}`
}

export default GeneralTagCode

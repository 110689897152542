import styled, { css } from 'styled-components'

const widthBoundaryCss = css`
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
`

const WidthBoundary = styled.div`
  ${widthBoundaryCss}
`

export { WidthBoundary, widthBoundaryCss }

import { has, isEmpty, merge } from 'lodash-es'

interface GuestVisibility {
  [key: string]: boolean
}

const revertCPKey = (visibility: GuestVisibility): GuestVisibility => {
  if (has(visibility, 'cPE')) {
    visibility['CPE'] = visibility.cPE
    delete visibility.cPE
  }

  if (has(visibility, 'cPEV')) {
    visibility['CPEV'] = visibility.cPEV
    delete visibility.cPEV
  }

  if (has(visibility, 'cPV')) {
    visibility['CPV'] = visibility.cPV
    delete visibility.cPV
  }

  return { ...visibility }
}

export const getGuestVisibility = (
  visibility?: GuestVisibility,
): GuestVisibility => {
  const defaultVisibility = {
    CPE: false,
    CPEV: false,
    CPV: false,
    budget: false,
  }

  let guestVisibility = {}

  if (visibility && !isEmpty(visibility)) {
    guestVisibility = revertCPKey({ ...visibility })
  }

  return isEmpty(visibility)
    ? { ...defaultVisibility }
    : { ...merge(defaultVisibility, guestVisibility) }
}

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { isUndefined } from 'lodash-es'
import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import {
  CampaignKolAction,
  useExecuteCampaignKolActionMutation,
} from '@/api/workspaces-api'
import { Icon } from '@/components/custom-icon'
import { useIntl } from '@/i18n/hooks/use-intl'
import { fetchChannelList } from '@/store/chatroom'
import { ApproverType, CampaignKolStatus } from '@/types/mock-api-types'

export enum Subject {
  Kol = 'kol',
  Brand = 'brand',
  Us = 'us',
  You = 'you',
}

interface CampaignAdAcceptStatusProps {
  campaignId: number
  campaignKolId: number
  acceptedTime?: string
  status: CampaignKolStatus
  approverType: ApproverType
}

const CampaignAdAcceptStatus: FunctionComponent<
  CampaignAdAcceptStatusProps
> = ({ acceptedTime, status, approverType, campaignId, campaignKolId }) => {
  const { formatDate, formatMessage } = useIntl()
  const dispatch = useDispatch()

  const { data: userStatus } = useFetchUserStatusQuery()

  const [executeCampaignKolAction, { isLoading: executingCampaignKolAction }] =
    useExecuteCampaignKolActionMutation()

  const workspaceId = userStatus?.currentWorkspaceId
  const subject = approverType === ApproverType.Ad ? Subject.Us : Subject.Kol
  const subjectText = formatMessage({ id: `general:subject_${subject}` })

  const onSubmit = async (
    action: CampaignKolAction.AcceptApply | CampaignKolAction.RejectApply,
  ): Promise<void> => {
    if (isUndefined(workspaceId)) {
      return
    }

    await executeCampaignKolAction({
      workspaceId,
      campaignID: campaignId,
      campaignKolIds: [campaignKolId],
      action: action,
    }).unwrap()

    dispatch(
      fetchChannelList({
        campaignId,
      }),
    )
  }

  const getStatusText = (): string => {
    switch (status) {
      case CampaignKolStatus.Running:
        return formatMessage(
          {
            id: acceptedTime
              ? 'campaign:accept_cooperate'
              : 'campaign:ad_accept_status_force_running',
          },
          {
            subject: subjectText,
            date: formatDate(acceptedTime, 'dateTimeShort'),
          },
        )

      case CampaignKolStatus.Rejected:
        return approverType === ApproverType.System
          ? formatMessage({ id: 'campaign:system_auto_cancel' })
          : formatMessage(
              { id: 'campaign:reject_cooperate' },
              {
                subject: subjectText,
                date: formatDate(acceptedTime, 'dateTimeShort'),
              },
            )

      case CampaignKolStatus.Inviting:
        return formatMessage({ id: 'campaign:waiting_kol_response' })

      default:
        return ''
    }
  }

  const text = getStatusText()

  return (
    <Wrapper status={status}>
      <>
        {text && (
          <>
            <Icon type='bullhorn' />
            <p>{text}</p>
          </>
        )}

        {status === CampaignKolStatus.Applied && (
          <>
            <ActionButton
              isAccept
              icon={<CheckCircleOutlined />}
              loading={executingCampaignKolAction}
              onClick={(): void => {
                onSubmit(CampaignKolAction.AcceptApply)
              }}
            >
              {formatMessage({ id: 'campaign:accept_status_accepted' })}
            </ActionButton>
            <ActionButton
              icon={<CloseCircleOutlined />}
              loading={executingCampaignKolAction}
              onClick={(): void => {
                onSubmit(CampaignKolAction.RejectApply)
              }}
            >
              {formatMessage({ id: 'campaign:accept_status_declined' })}
            </ActionButton>
          </>
        )}
      </>
    </Wrapper>
  )
}

export const Wrapper = styled.div<{
  status: CampaignKolStatus
}>`
  background: ${({ status, theme }): string | undefined => {
    switch (status) {
      case CampaignKolStatus.Running:
        return '#F6FFED'
      case CampaignKolStatus.Rejected:
        return theme.colors.border.divider
      case CampaignKolStatus.Inviting:
        return '#FFFBE6'
    }
  }};
  border-radius: 2px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: ${({ status }): string =>
    status === CampaignKolStatus.Inviting ? 'space-between' : 'center'};

  > :not(:last-child) {
    margin-right: 12px;
  }

  p {
    color: ${({ theme }): string => theme.colors.text.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
  }

  .anticon {
    color: ${({ theme }): string => theme.colors.text.primary};
    font-size: 16px;
    height: 16px;
  }

  .ant-btn > .anticon + span {
    margin-left: 5px;
  }
`

export const ActionButton = styled(Button)<{ isAccept?: boolean }>`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  .anticon {
    color: ${({ isAccept, theme }): string =>
      isAccept ? theme.colors.tagStatus.success : theme.colors.tagStatus.error};
  }
`

export default CampaignAdAcceptStatus

import { UserStatus } from '@/api/user-api'
import { Page, UserType } from '@/hooks/use-authorization/constants'
import { AgentSchema, KolUserIdentityType } from '@/types/schema/agent-schema'

interface GetUserDefaultPage {
  (
    userType: string,
    workspaceStatus?: UserStatus,
    agentInfo?: AgentSchema,
  ): Page
}

const guestMemberStatus: string[] = ['guest']

const getUserDefaultPage: GetUserDefaultPage = (
  userType,
  workspaceStatus,
  agentInfo,
) => {
  if (
    agentInfo?.kolUserIdentityType === KolUserIdentityType.RadarStarAgencyKOL
  ) {
    return Page.KolSettings
  }

  if (
    guestMemberStatus.includes(
      workspaceStatus?.currentWorkspace.memberStatus ?? '',
    )
  ) {
    return Page.Report
  }

  switch (userType) {
    case UserType.Agency:
      return Page.CaseList
    case UserType.NormalKol:
      return Page.CaseList
    case UserType.NormalAd:
      return Page.Search
    default:
      return Page.Settings
  }
}

export default getUserDefaultPage

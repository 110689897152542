import {
  FileAttachment,
  isFilePayload,
  MessageEnvelope,
} from '@pubnub/react-chat-components'

export const getFileAttachment = (
  envelope: MessageEnvelope,
): (FileAttachment & { isImage: boolean }) | undefined => {
  return isFilePayload(envelope.message)
    ? {
        ...envelope.message.file,
        isImage: /\.(svg|gif|jpe?g|tiff?|png|webp|bmp)$/i.test(
          envelope.message.file.name,
        ),
      }
    : undefined
}

export const getUUID = (envelope: MessageEnvelope): string => {
  return envelope.uuid ?? envelope.publisher ?? ''
}

export const getMessageText = (
  envelope: MessageEnvelope,
): string | undefined => {
  return isFilePayload(envelope.message)
    ? envelope.message.message?.text
    : envelope.message.text
}

import env from '@/utils/env'

// TODO: refactor after dev/staging domain change
const getDomain = (): string => {
  if (env.DEVELOPMENT_MODE) {
    return 'localhost'
  }

  return process.env.NEXT_PUBLIC_CURRENT_HOST.split('.').slice(-2).join('.')
}

export default getDomain

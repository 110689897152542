import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IsJapanState {
  isJapan: boolean
}

const initialState: IsJapanState = {
  isJapan: false,
}

const isJapanSlice = createSlice({
  name: 'isJapan',
  initialState,
  reducers: {
    setIsJapan: (state, action: PayloadAction<IsJapanState['isJapan']>) => {
      state.isJapan = action.payload
    },
  },
})

export const { setIsJapan } = isJapanSlice.actions

export default isJapanSlice.reducer

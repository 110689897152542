import { createAction, createReducer } from '@reduxjs/toolkit'
import { assign, clone, isEmpty, uniqBy } from 'lodash-es'
import { ACTION_TYPES, ActionPayload, State } from '@/types/redux/report'
import { getGuestVisibility } from '@/utils/deep-report/get-guest-visibility'
import { getPlatformShortName } from '@/utils/get-platform-name'

const initialState: State = {
  data: {
    allChartData: {
      kol: [],
      platform: [],
      post: [],
      score: [],
    },
    chartData: {
      kol: [],
      platform: [],
      post: [],
      score: [],
    },
  },
  editGuestVisibility: {},
  mode: '',
  summaryFilter: {
    currentReportID: 0,
    filterByBoard: {
      comment: {
        kol: [],
        platform: [],
      },
      kol: {
        kol: [],
        platform: [],
      },
      platform: {
        kol: [],
        platform: [],
      },
      post: {
        kol: [],
        platform: [],
      },
      score: {
        kol: [],
        platform: [],
      },
    },
  },
}

export const actions = {
  cleanReport: createAction(ACTION_TYPES['CLEAN_REPORT']),
  cleanReportContent: createAction(ACTION_TYPES['CLEAN_REPORT_CONTENT']),
  deleteReportKOL: createAction<ActionPayload['DELETE_REPORT_KOL']>(
    ACTION_TYPES['DELETE_REPORT_KOL'],
  ),
  setAllChartData: createAction<ActionPayload['SET_ALL_CHART_DATA']>(
    ACTION_TYPES['SET_ALL_CHART_DATA'],
  ),
  setChartData: createAction<ActionPayload['SET_CHART_DATA']>(
    ACTION_TYPES['SET_CHART_DATA'],
  ),
  setGuestVisibility: createAction<ActionPayload['SET_GUEST_VISIBILITY']>(
    ACTION_TYPES['SET_GUEST_VISIBILITY'],
  ),
  setMode: createAction<ActionPayload['SET_MODE']>(ACTION_TYPES['SET_MODE']),
  setReport: createAction<ActionPayload['SET_REPORT']>(
    ACTION_TYPES['SET_REPORT'],
  ),
  setReportContent: createAction<ActionPayload['SET_REPORT_CONTENT']>(
    ACTION_TYPES['SET_REPORT_CONTENT'],
  ),
  setSummaryFilter: createAction<ActionPayload['SET_SUMMARY_FILTER']>(
    ACTION_TYPES['SET_SUMMARY_FILTER'],
  ),
  setVisibility: createAction<ActionPayload['SET_VISIBILITY']>(
    ACTION_TYPES['SET_VISIBILITY'],
  ),
}

export const reducer = createReducer<State>(initialState, (builder) =>
  builder
    .addCase(actions.setAllChartData, (state, { payload }) => {
      const sections = ['score', 'kol', 'post', 'platform', 'comment'] as const
      const { data } = payload
      const visibleData = data.filter((d) => !d.isHidden)
      sections.forEach((section) => {
        state.data.allChartData[section] = visibleData
        state.data.chartData[section] = visibleData
      })
    })
    .addCase(actions.setChartData, (state, action) => {
      const { section, data } = action.payload
      state.data.chartData[section] = data
    })
    .addCase(actions.setReport, (state, action) => {
      if (state.data.report && action.payload) {
        const reportStatistics: any[] = action.payload.reportStatistics ?? []
        const visibleData = reportStatistics.filter((d) => !d.isHidden)

        const newReport = {
          ...action.payload,
          platforms: uniqBy(visibleData, 'platform').map((d) => {
            return {
              id: d.platform.toString(),
              name: getPlatformShortName(d.platform),
            }
          }),
        }
        state.data.report = newReport

        state.data.report.guestVisibility = getGuestVisibility(
          action.payload.guestVisibility,
        )
      } else {
        state.data.report = action.payload
      }
    })
    .addCase(actions.setSummaryFilter, (state, action) => {
      const { section, data, currentReportID } = action.payload
      state.summaryFilter.currentReportID = currentReportID
      state.summaryFilter.filterByBoard[section] = data
    })
    .addCase(actions.cleanReport, (state) => {
      state.data.report = undefined
      state.data.chartData = {
        kol: [],
        platform: [],
        post: [],
        score: [],
      }
    })
    .addCase(actions.cleanReportContent, (state) => {
      state.editingReportContent = undefined
    })
    .addCase(actions.setReportContent, (state, action) => {
      state.editingReportContent = action.payload
    })
    .addCase(actions.deleteReportKOL, (state, { payload }) => {
      if (state.data.report) {
        const { id } = payload
        state.data.report.reportKOLs = state.data.report.reportKOLs?.filter(
          (kol) => kol.id !== id,
        )
      }
    })
    .addCase(actions.setMode, (state, { payload }) => {
      state.mode = payload
    })
    .addCase(actions.setGuestVisibility, (state, { payload }) => {
      if (isEmpty(payload)) {
        state.editGuestVisibility = getGuestVisibility()
      } else {
        const cloneVisibility = clone(state.editGuestVisibility)
        assign(cloneVisibility, payload)
        state.editGuestVisibility = cloneVisibility
      }
    })
    .addCase(actions.setVisibility, (state, { payload }) => {
      if (!state.data.report) {
        return
      }
      state.data.report.guestVisibility = getGuestVisibility(payload)
    }),
)

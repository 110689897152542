export function openTab(url: string): Promise<void> {
  if (!window || typeof window === 'undefined') {
    return Promise.reject()
  }
  const newWindow = window.open(url, '_blank')
  try {
    newWindow?.focus()
    return Promise.resolve()
  } catch (error) {
    return Promise.reject()
  }
}

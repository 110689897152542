import { PostType } from '@/constants/post-type'
import { I18nId } from '@/i18n/config'

const postTypeI18nIdMap: Record<PostType, I18nId> = {
  [PostType.Image]: 'campaign:content_post_type_image',
  [PostType.Story]: 'campaign:content_post_type_story',
  [PostType.Video]: 'campaign:content_post_type_video',
  [PostType.Short]: 'campaign:content_post_type_short',
  [PostType.Live]: 'campaign:content_post_type_live',
  [PostType.Text]: 'autoreport:export_data_posttype_text',
  [PostType.Blog]: 'autoreport:export_data_posttype_blog',
  [PostType.Other]: 'autoreport:export_data_posttype_other',
}

export default postTypeI18nIdMap

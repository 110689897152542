import { z } from 'zod'
import { listResourceSchema } from '@/types/schema/api/list-resource-schema'
import { postCollectionPostSchema } from '@/types/schema/post-collection'

export const fetchPostCollectionPostListResponseSchema =
  listResourceSchema.extend({
    data: z.array(postCollectionPostSchema),
  })

export type FetchPostCollectionPostListResponse = z.infer<
  typeof fetchPostCollectionPostListResponseSchema
>

import { OurIconType } from '@/components/custom-icon'
import { I18nId } from '@/i18n/config'

interface WorkspaceDropdownMenuItem {
  url: string
  icon: OurIconType
  titleI18n: I18nId
}

export const workspaceDropdownMenuItems: WorkspaceDropdownMenuItem[] = [
  {
    url: '/workspace-setting',
    icon: 'elIconSetting',
    titleI18n: 'workspace:setting_sidebar_title',
  },
  {
    url: '/workspace-member',
    icon: 'elIconUsers',
    titleI18n: 'workspace:setting_sidebar_members',
  },
  {
    url: '/workspace-plan',
    icon: 'elIconCrown',
    titleI18n: 'workspace:setting_sidebar_plans',
  },
]

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const UnsafeNewTabLink: FunctionComponent<
  Omit<JSX.IntrinsicElements['a'], 'target'>
> = ({ children, rel = 'noopener', ...props }): JSX.Element => {
  return (
    <a rel={rel} target='_blank' {...props}>
      {children}
    </a>
  )
}

export default styled(UnsafeNewTabLink)``

import { Skeleton, Spin } from 'antd'
import React, { FunctionComponent, ReactNode } from 'react'

interface LoadingMaskProps {
  loading: boolean
  children?: ReactNode
}

const LoadingMask: FunctionComponent<LoadingMaskProps> = ({
  loading,
  children,
}) => {
  return <Spin spinning={loading}>{loading ? <Skeleton /> : children}</Spin>
}

export default LoadingMask

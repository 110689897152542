import { getApps, initializeApp } from 'firebase/app'
import { Database, getDatabase } from 'firebase/database'

export const firebaseApi = {
  init(): void {
    if (!getApps().length) {
      const FIREBASE_DB_NAME = process.env.NEXT_PUBLIC_FIREBASE_DB_NAME
      const config = {
        databaseURL: `https://${FIREBASE_DB_NAME}.firebaseio.com`,
      }
      console.info('init firebase: ', config)
      initializeApp(config)
    } else {
      console.info('already init firebase.')
    }
  },
  database(): Database {
    firebaseApi.init()
    return getDatabase()
  },
}

import { CampaignStatus, useFetchKolCampaignListQuery } from '@/api/partner-api'

interface DefaultCampaignCaseInfo {
  pendingTabCount?: number
  runningTabCount?: number
  overTabCount?: number
}

const DEFAULT_PAGE = 1

const useDefaultCampaignCase = (): DefaultCampaignCaseInfo => {
  const { data: invitingCampaignCaseList } = useFetchKolCampaignListQuery({
    page: DEFAULT_PAGE,
    campaignStatus: CampaignStatus.Inviting,
  })

  const { data: appliedCampaignCaseList } = useFetchKolCampaignListQuery({
    page: DEFAULT_PAGE,
    campaignStatus: CampaignStatus.Applied,
  })

  const { data: runningCampaignCaseList } = useFetchKolCampaignListQuery({
    page: DEFAULT_PAGE,
    campaignStatus: CampaignStatus.Running,
  })

  const { data: overCampaignCaseList } = useFetchKolCampaignListQuery({
    page: DEFAULT_PAGE,
    campaignStatus: CampaignStatus.Over,
  })

  return {
    pendingTabCount:
      invitingCampaignCaseList && appliedCampaignCaseList
        ? invitingCampaignCaseList.total + appliedCampaignCaseList.total
        : undefined,
    runningTabCount: runningCampaignCaseList?.total,
    overTabCount: overCampaignCaseList?.total,
  }
}

export default useDefaultCampaignCase

import { ApiExecutor } from '@/api/executor/api-executor'
import { AppStore, initStore } from '@/store/store'
import { tokenProvider } from '@/utils/token-provider'

let store: AppStore | undefined
let apiExecutor: undefined | ApiExecutor

export class AppContext {
  public static getStore(): AppStore {
    if (!store) {
      store = initStore()
    }
    return store
  }

  /**
   * @deprecated
   * use RTK Query
   */
  public static get ApiExecutor(): ApiExecutor {
    return apiExecutor ?? this.buildApiExecutor()
  }

  /**
   * @deprecated
   * use RTK Query
   */
  public static buildApiExecutor(): ApiExecutor {
    apiExecutor = new ApiExecutor({
      baseURL: process.env.NEXT_PUBLIC_NEXT_API_BASE_URL,
      headers: {
        ['Accept']: 'application/json',
        ['Content-Type']: 'application/json',
      },
      tokenProvider,
    })
    return apiExecutor
  }
}

import { CurrencyCode } from '@buggy/shared'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { isEmpty } from 'lodash-es'
import { GoogleAccessTokenType, oauthApi } from '@/api/oauth-api'
import { userApi } from '@/api/user-api'
import { workspacesApi } from '@/api/workspaces-api'
import { RootState } from '@/store/store'

export enum GoogleAnalyticsAdminAPITagType {
  AccountSummaries = 'AccountSummaries',
  PropertySummary = 'PropertySummary',
}

const DEFAULT_PAGE_SIZE = 50

interface Request {
  /**
   * The maximum number of AccountSummary resources to return. The service may return fewer than this value, even if there are additional pages. If unspecified, at most 50 resources will be returned. The maximum value is 200; (higher values will be coerced to the maximum)
   */
  pageSize?: number

  /**
   * A page token, received from a previous ListAccountSummaries call. Provide this to retrieve the subsequent page. When paginating, all other parameters provided to ListAccountSummaries must match the call that provided the page token.
   */
  pageToken?: string
}

interface PropertySummary {
  property: string
  displayName: string
}

interface Property {
  name: string
  displayName: string
  parent: string
  currencyCode: CurrencyCode
}

interface FetchPropertiesResponse {
  properties: Property[]
}

interface FetchAccountSummariesResponse {
  accountSummaries: {
    displayName: string
    account: string
  }[]
  nextPageToken?: string
}

export const googleAnalyticsAdminApi = createApi({
  reducerPath: 'googleAnalyticsAdminAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://analyticsadmin.googleapis.com/v1beta',
    prepareHeaders: (headers: Headers, { getState }) => {
      const { data: fetchGoogleAccessTokenResponse } =
        oauthApi.endpoints.fetchGoogleAccessToken.select(
          GoogleAccessTokenType.Analytics,
        )(getState() as RootState)

      let accessToken = fetchGoogleAccessTokenResponse?.accessToken

      const { data: userStatus } = userApi.endpoints.fetchUserStatus.select()(
        getState() as RootState,
      )

      if (userStatus?.currentWorkspaceId) {
        const { data: googleAnalyticsSetting } =
          workspacesApi.endpoints.fetchGoogleAnalyticsSetting.select(
            userStatus?.currentWorkspaceId,
          )(getState() as RootState)
        accessToken = isEmpty(googleAnalyticsSetting?.accessToken)
          ? accessToken
          : googleAnalyticsSetting?.accessToken
      }

      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`)
      }

      return headers
    },
  }),
  tagTypes: [
    GoogleAnalyticsAdminAPITagType.AccountSummaries,
    GoogleAnalyticsAdminAPITagType.PropertySummary,
  ],
  endpoints: (builder) => ({
    fetchAccountSummaries: builder.query<
      FetchAccountSummariesResponse,
      Request
    >({
      query: (request) => {
        return {
          url: '/accountSummaries',
          params: {
            ...request,
            pageSize: request.pageSize ?? DEFAULT_PAGE_SIZE,
          },
        }
      },
      providesTags: [GoogleAnalyticsAdminAPITagType.AccountSummaries],
    }),
    fetchPropertySummary: builder.query<PropertySummary, string>({
      query: (propertyName) => {
        return `/${propertyName}`
      },
    }),

    fetchProperties: builder.query<FetchPropertiesResponse, string>({
      query: (parentAccount) => {
        return {
          url: '/properties',
          params: {
            filter: `parent:${parentAccount}`,
          },
        }
      },
    }),
  }),
})

export const useFetchAccountSummariesQuery =
  googleAnalyticsAdminApi.endpoints.fetchAccountSummaries.useQuery
export const useFetchPropertySummaryQuery =
  googleAnalyticsAdminApi.endpoints.fetchPropertySummary.useQuery
export const useFetchPropertiesLazyQuery =
  googleAnalyticsAdminApi.endpoints.fetchProperties.useLazyQuery

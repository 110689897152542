import { skipToken } from '@reduxjs/toolkit/query'
import { useFetchAgentInfoQuery } from '@/api/influencer-api'
import { useAuth } from '@/hooks/use-auth'
import { AgentSchema, KolUserIdentityType } from '@/types/schema/agent-schema'

type UseAgentInfo = () => {
  kolUserIdentityType?: KolUserIdentityType
  radarStarAgencyKOL: boolean
  agencyKOL: boolean
  agentInfo?: AgentSchema
}

const useAgentInfo: UseAgentInfo = () => {
  const { isAd } = useAuth()
  const { data: agentInfo } = useFetchAgentInfoQuery(
    isAd ? skipToken : undefined,
  )

  const kolUserIdentityType = agentInfo?.kolUserIdentityType
  const radarStarAgencyKOL =
    agentInfo?.kolUserIdentityType === KolUserIdentityType.RadarStarAgencyKOL
  const agencyKOL =
    agentInfo?.kolUserIdentityType === KolUserIdentityType.AgencyKOL

  return { agentInfo, kolUserIdentityType, radarStarAgencyKOL, agencyKOL }
}

export default useAgentInfo

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { camelizeKeys } from 'humps'

interface GoogleUserInfoResponse {
  /**
   * @description AKA: user id, same value as deprecate method googleUser.getId() return
   */
  sub: string
  name: string
  givenName: string
  picture: string
  email: string
  emailVerified: boolean
  locale: string
}

export const googleApi = createApi({
  reducerPath: 'googleAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.googleapis.com/oauth2/v3',
  }),
  endpoints: (builder) => ({
    fetchGoogleUserInfo: builder.query<GoogleUserInfoResponse, string>({
      query: (token) => {
        return {
          url: `/userinfo`,
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as GoogleUserInfoResponse
      },
    }),
  }),
})

export const useFetchGoogleUserInfoLazyQuery =
  googleApi.endpoints.fetchGoogleUserInfo.useLazyQuery

import { Optional } from '@/types/optional'

const triggers = [' ', ',', '，', '、', '&', '|'] as const
export type SearchOperatorSuggestionsTrigger = (typeof triggers)[number]

const getSearchOperatorSuggestionsTrigger = (
  keyword: string,
): Optional<SearchOperatorSuggestionsTrigger> => {
  return triggers.find((trigger) => keyword.endsWith(trigger))
}

export default getSearchOperatorSuggestionsTrigger

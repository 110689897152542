import { Select, Tag } from 'antd'
import { isEmpty } from 'lodash-es'
import React, { FunctionComponent, ReactElement } from 'react'
import styled from 'styled-components'
import {
  LANGUAGE_CODE_LIST,
  LanguageCodeType,
} from '@/constants/search/language-code'
import { useIntl } from '@/i18n/hooks/use-intl'

interface LangFilterProps {
  value: string[]
  onChange: (changedValues: LanguageCodeType[]) => void
}

const LangFilter: FunctionComponent<LangFilterProps> = ({
  value,
  onChange,
}) => {
  const { formatMessage } = useIntl()
  const options = Object.keys(LANGUAGE_CODE_LIST).map((key) => {
    const label = `${formatMessage({
      id: LANGUAGE_CODE_LIST[key].i18nKey,
    })} ( ${LANGUAGE_CODE_LIST[key].originalLang} )`
    return {
      value: key,
      title: label,
      label,
    }
  })

  return (
    <StyledSelect
      allowClear
      showArrow
      showSearch
      filterOption={(input, option): boolean =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      mode='multiple'
      optionFilterProp='children'
      options={options}
      placeholder={formatMessage({ id: 'general:select_placeholder' })}
      tagRender={({ value, closable, onClose }): ReactElement => {
        // value maybe undefined see https://github.com/ant-design/ant-design/issues/48930
        return (
          value && (
            <Tag closable={closable} style={{ margin: 2 }} onClose={onClose}>
              {formatMessage({ id: LANGUAGE_CODE_LIST[value].i18nKey })}
            </Tag>
          )
        )
      }}
      value={value.filter((str): boolean => !isEmpty(str))}
      onChange={(values: LanguageCodeType[]): void => onChange(values)}
    />
  )
}

const StyledSelect = styled(Select)`
  width: 100%;
`

export default LangFilter

import { isEmpty } from 'lodash-es'
import Image from 'next/image'
import React, { FunctionComponent, useState } from 'react'
import defaultProfileSrc from '@/assets/images/kol-profile-default.jpg'
import { getThumbnail } from '@/utils/get-thumbnails'

interface PostImageProps {
  kolUuid?: string
  thumbnailUrls?: string[]
}

const PostImage: FunctionComponent<PostImageProps> = React.memo(
  ({ kolUuid, thumbnailUrls = [] }) => {
    const [imgIndex, setImgIndex] = useState(0)
    const imgList = [
      ...thumbnailUrls,
      getThumbnail(kolUuid),
      defaultProfileSrc,
    ].filter((src) => !isEmpty(src))
    const imgSrc = imgList[imgIndex] ?? ''

    return (
      <Image
        fill
        alt='post-image'
        src={imgSrc}
        unoptimized={imgSrc.includes('kolradar.com')}
        onError={(): void => {
          setImgIndex(imgIndex + 1)
        }}
      />
    )
  },
)

PostImage.displayName = 'PostImage'
export default PostImage

import { FunctionComponent, ReactNode, useRef } from 'react'
import styled from 'styled-components'

let uuid = 0

interface DropdownProps {
  menuTitle: ReactNode
  children?: ReactNode
  position?: string
}

const Dropdown: FunctionComponent<DropdownProps> = (props) => {
  const wrapperRef = useRef(null as any)
  uuid += 1

  const handleInnerClick = (event): void => {
    if (!event.target.classList.contains('dropdown-inner')) {
      wrapperRef.current.click()
    }
  }

  return (
    <StyledDropdown className={props.position}>
      <label className='dropdown-title' htmlFor={`dropdown-${uuid}`}>
        {props.menuTitle}
      </label>
      <input
        hidden
        aria-hidden='true'
        className='dropdown-open'
        id={`dropdown-${uuid}`}
        type='checkbox'
      />
      <label
        className='dropdown-overlay'
        htmlFor={`dropdown-${uuid}`}
        ref={wrapperRef}
      ></label>
      <div className='dropdown-inner' onClick={handleInnerClick}>
        {props.children}
      </div>
    </StyledDropdown>
  )
}

export default Dropdown

const StyledDropdown = styled.div`
  position: relative;

  .dropdown-title {
    cursor: pointer;
  }

  .dropdown-inner {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    margin-top: 5px;
    min-width: 200px;
    padding: 10px 15px;
    background: ${({ theme }): string => theme.colors.background.white};
    color: ${({ theme }): string => theme.colors.text.primary};
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.102);
    border-radius: 2px;
    transition:
      opacity 0.3s,
      visibility 0.3s;
  }

  .dropdown-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .dropdown-open:checked {
    & ~ .dropdown-inner {
      opacity: 1;
      visibility: visible;
    }

    & ~ .dropdown-overlay {
      display: block;
    }
  }

  &.center {
    .dropdown-inner {
      position: fixed;
      width: 100%;
      left: 0;
      margin-top: 15px;
      padding: 30px;
      overflow-y: auto;
    }
  }

  &.full {
    .dropdown-inner {
      position: fixed;
      width: 100%;
      left: 0;
      margin-top: 15px;
      padding: 30px;
      overflow-y: auto;
    }
  }
`

import { DollarOutlined } from '@ant-design/icons'
import { Icon, theme } from '@buggy/shared'
import { Dropdown, MenuProps, notification } from 'antd'
import { noop } from 'lodash-es'
import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import useAgentInfo from '@/hooks/use-agent-info'
import { useAuth } from '@/hooks/use-auth'
import routes from '@/hooks/use-authorization/routes'
import { useI18n } from '@/hooks/use-i18n'
import * as userAPI from '@/hooks/user-api'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli } from '@/utils/ampli'
import { goToPage } from '@/utils/routes/go-to-page'

export const UserDropdown: FunctionComponent = () => {
  const { isAd, userInfo } = useAuth()

  const { radarStarAgencyKOL } = useAgentInfo()
  const [, logout] = userAPI.hooks.useLogoutFn()
  const { locale } = useI18n()
  const { formatMessage } = useIntl()
  const logoutMessage = formatMessage({ id: 'general:logout_successfully' })

  const onLogout: VoidFunction = () => {
    logout()
      .then(() => {
        ampli.logoutSuccess({ isSingleLogin: false })

        notification.success({
          message: logoutMessage,
          placement: 'bottomRight',
        })

        goToPage({
          pathname: '/login',
          query: { lang: locale },
        }).catch((e) => console.warn(e))

        return noop()
      })
      .catch((error) => console.warn(error))
  }

  const menuItems: MenuProps['items'] = [
    {
      key: 'setting',
      icon: (
        <Icon
          fontSize={16}
          name='settings'
          stroke={theme.colors.text.secondary}
        />
      ),
      label: (
        <Link
          href={routes.kolSettings.stringifyUrl({})}
          style={{ marginLeft: 8 }}
        >
          {formatMessage({ id: 'general:navbar_account_text' })}
        </Link>
      ),
    },
    ...(!isAd && !radarStarAgencyKOL
      ? [
          {
            key: 'accounting',
            icon: <DollarOutlined />,
            label: (
              <Link
                href={routes.kolSettings.stringifyUrl(
                  {},
                  { tab: 'accounting' },
                )}
              >
                {formatMessage({ id: 'account:side_accounting_info' })}
              </Link>
            ),
          },
        ]
      : []),
    {
      key: 'logout',
      icon: (
        <Icon
          fontSize={16}
          name='log-out'
          stroke={theme.colors.text.secondary}
        />
      ),
      label: (
        <span style={{ marginLeft: 8 }}>
          {formatMessage({ id: 'general:navbar_logout_text' })}
        </span>
      ),
      onClick: onLogout,
    },
  ]

  if (!userInfo) {
    return null
  }

  return (
    <Dropdown
      menu={{ items: menuItems }}
      overlayStyle={{ width: 'max-content' }}
    >
      <UserItem onClick={(e): void => e.preventDefault()}>
        <Icon name='user' stroke={theme.colors.text.primary} />
        <Icon name='chevron-down' stroke={theme.colors.text.tooltip} />
      </UserItem>
    </Dropdown>
  )
}

const UserItem = styled.div`
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
`

import { z } from 'zod'

export const postTypeSchema = z.enum([
  'text',
  'image',
  'video',
  'live',
  'story',
  'blog',
  'other',
  'short',
])

export type PostType = z.infer<typeof postTypeSchema>

import { I18nId } from '@/i18n/config'

export const CUSTOMIZED_TAG_I18N_MAP: Record<string, I18nId> = {
  ['Photography']: 'customized_tag:photography',
  ['Art and Entertainment']: 'customized_tag:art_and_entertainment',
  ['Art and Entertainment/Podcast']: 'customized_tag:podcast',
  ['Art and Entertainment/Painting']: 'customized_tag:painting',
  ['Art and Entertainment/Music']: 'customized_tag:music',
  ['Art and Entertainment/Interior and Exterior Design']:
    'customized_tag:interior_and_exterior_design',
  ['Art and Entertainment/Toy Figure']: 'customized_tag:toy_figure',
  ['Pets']: 'customized_tag:pets',
  ['Pets/Pet Healthcare']: 'customized_tag:pet_healthcare',
  ['Pets/Pets Supplies and Grooming']:
    'customized_tag:pets_supplies_and_grooming',
  ['Pets/Cat']: 'customized_tag:cat',
  ['Pets/Pet Rescue Adoption']: 'customized_tag:pet_rescue_adoption',
  ['Pets/Dog']: 'customized_tag:dog',
  ['Medical and Health']: 'customized_tag:medical_and_health',
  ['Medical and Health/Nutrition & Health Care']:
    'customized_tag:nutrition_health_care',
  ['Medical and Health/Women Health']: 'customized_tag:women_health',
  ['Medical and Health/Epidemic & Policies']:
    'customized_tag:epidemic_policies',
  ['Medical and Health/Mental Health']: 'customized_tag:mental_health',
  ['Media Entertainment']: 'customized_tag:media_entertainment',
  ['Media Entertainment/VTuber']: 'customized_tag:v_tuber',
  ['Media Entertainment/K-pop']: 'customized_tag:k_pop',
  ['Media Entertainment/Drama']: 'customized_tag:drama',
  ['Media Entertainment/Dancing']: 'customized_tag:dancing',
  ['Media Entertainment/Movie']: 'customized_tag:movie',
  ['Media Entertainment/Anime']: 'customized_tag:anime',
  ['Media Entertainment/Pop Music']: 'customized_tag:pop_music',
  ['Media Entertainment/J-pop']: 'customized_tag:j_pop',
  ['Sports']: 'customized_tag:sports',
  ['Sports/Basketball']: 'customized_tag:basketball',
  ['Sports/Football']: 'customized_tag:football',
  ['Sports/Tennis']: 'customized_tag:tennis',
  ['Sports/Yoga']: 'customized_tag:yoga',
  ['Sports/Hiking and Climbing']: 'customized_tag:hiking_and_climbing',
  ['Sports/Swimming']: 'customized_tag:swimming',
  ['Sports/Baseball']: 'customized_tag:baseball',
  ['Sports/Fishing']: 'customized_tag:fishing',
  ['Sports/Fitting']: 'customized_tag:fitting',
  ['Sports/Golf']: 'customized_tag:golf',
  ['Sports/Athletics']: 'customized_tag:athletics',
  ['Sports/Soccer']: 'customized_tag:soccer',
  ['Sports/Biking']: 'customized_tag:biking',
  ['Gaming']: 'customized_tag:gaming',
  ['Gaming/Live Streaming']: 'customized_tag:live_streaming',
  ['Gaming/Computer Game']: 'customized_tag:computer_game',
  ['Gaming/Mobile Game']: 'customized_tag:mobile_game',
  ['Relationship']: 'customized_tag:relationship',
  ['Relationship/LGBT']: 'customized_tag:lgbt',
  ['Relationship/Wedding']: 'customized_tag:wedding',
  ['Relationship/Marriage']: 'customized_tag:marriage',
  ['Adult']: 'customized_tag:adult',
  ['Education']: 'customized_tag:education',
  ['Education/Language Learning']: 'customized_tag:language_learning',
  ['Education/Educational System']: 'customized_tag:educational_system',
  ['Finance']: 'customized_tag:finance',
  ['Finance/Real Estate']: 'customized_tag:real_estate',
  ['Finance/Investment']: 'customized_tag:investment',
  ['Climate and Environment']: 'customized_tag:climate_and_environment',
  ['Travel']: 'customized_tag:travel',
  ['Travel/Camping']: 'customized_tag:camping',
  ['Travel/Korea Travel']: 'customized_tag:korea_travel',
  ['Travel/Family Travel']: 'customized_tag:family_travel',
  ['Travel/Southeast Asia Travel']: 'customized_tag:southeast_asia_travel',
  ['Travel/Taiwan Travel']: 'customized_tag:taiwan_travel',
  ['Travel/Japan Travel']: 'customized_tag:japan_travel',
  ['Family']: 'customized_tag:family',
  ['Family/Parenting']: 'customized_tag:parenting',
  ['Family/Motherhood']: 'customized_tag:motherhood',
  ['Food']: 'customized_tag:food',
  ['Food/Noodles']: 'customized_tag:noodles',
  ['Food/Beverage']: 'customized_tag:beverage',
  ['Food/Dessert']: 'customized_tag:dessert',
  ['Food/Recipe']: 'customized_tag:recipe',
  ['Food/Big Eater']: 'customized_tag:big_eater',
  ['Beauty and Fashion']: 'customized_tag:beauty_and_fashion',
  ['Beauty and Fashion/Medical Beauty']: 'customized_tag:medical_beauty',
  ['Beauty and Fashion/Makeup']: 'customized_tag:makeup',
  ['Beauty and Fashion/Luxury']: 'customized_tag:luxury',
  ['Beauty and Fashion/Perfume']: 'customized_tag:perfume',
  ['Beauty and Fashion/Hair Beauty']: 'customized_tag:hair_beauty',
  ['Beauty and Fashion/Outfit']: 'customized_tag:outfit',
  ['Beauty and Fashion/Beauty Care']: 'customized_tag:beauty_care',
  ['Technology']: 'customized_tag:technology',
  ['Technology/AI and Semiconductor']: 'customized_tag:ai_and_semiconductor',
  ['Technology/Consumer Electronics']: 'customized_tag:consumer_electronics',
  ['Shopping Promoiton']: 'customized_tag:shopping_promoiton',
  ['Law and Society']: 'customized_tag:law_and_society',
  ['Law and Society/International News']: 'customized_tag:international_news',
  ['Law and Society/Taiwan News']: 'customized_tag:taiwan_news',
  ['Religion']: 'customized_tag:religion',
  ['Fortunetelling']: 'customized_tag:fortunetelling',
  ['Transportation']: 'customized_tag:transportation',
  ['Transportation/Motorcycle']: 'customized_tag:motorcycle',
  ['Transportation/Car']: 'customized_tag:car',
  ['Life Style']: 'customized_tag:life_style',
  ['Life Style/Cleaning']: 'customized_tag:cleaning',
  ['Life Style/DIY']: 'customized_tag:diy',
  ['Daily Topics']: 'customized_tag:daily_topics',
  ['Daily Topics/Work']: 'customized_tag:work',
  ['Daily Topics/Holidays']: 'customized_tag:holidays',
  ['Daily Topics/Celebrity News']: 'customized_tag:celebrity_news',
}

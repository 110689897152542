import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { HeaderItemGroupWithWorkspace } from '@/components/header/header-item-group-with-workspace'
import { SearchKeyword } from '@/components/header/search-keyword'
import { Logo } from '@/components/layout/header'

export const HeaderWithWorkspace: FunctionComponent = () => {
  return (
    <HeaderWrapper>
      <Container>
        <HeaderItemGroupWrapper>
          <Logo />
          <SearchKeyword />
        </HeaderItemGroupWrapper>
        <HeaderItemGroupWithWorkspace />
      </Container>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  padding: 4px;
  position: fixed;
  width: 100vw;
  background-color: white;
  z-index: 1000;
  box-shadow: rgb(0 0 0 / 10%) 0 2px 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 4px 50px;
  }
`

const Container = styled.div`
  width: 1140px;
  padding: 0 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1140px) {
    width: 100%;
  }
`

const HeaderItemGroupWrapper = styled.div`
  display: flex;
  align-items: center;
`

import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import UnsafeNewTabLink from '@/components/public-page/footer/components/unsafe-new-tab-link'
import { useI18n } from '@/hooks/use-i18n'
import { SupportedLanguages } from '@/i18n/config'

interface LinkItem {
  title: string
  url: string
  visible?: boolean
  onClick?: () => void
  isBlank?: boolean
}

interface Group {
  title: string
  visible?: boolean
  type: 'primary' | 'secondary'
  linkItems: LinkItem[]
}

type Column = Group[]

interface ContentProps {
  columns: Column[]
}

const Content: FunctionComponent<ContentProps> = (props): ReactNode => {
  const { columns } = props
  const { locale } = useI18n()
  const isJapanese = locale === SupportedLanguages.ja

  return (
    <Container>
      {columns.map((column, i) => {
        return (
          <Column keepAllWord={isJapanese} key={i}>
            {column.map((group) => {
              const {
                title: groupTitle,
                visible: groupVisible = true,
                linkItems,
                type,
              } = group

              if (!groupVisible) {
                return null
              }

              const isAllItemHidden = linkItems.every(
                (item) => item.visible === false,
              )
              if (isAllItemHidden) {
                return null
              }

              return (
                <Group key={groupTitle}>
                  <GroupTitle groupType={type}>{groupTitle}</GroupTitle>
                  <Items>
                    {linkItems.map((item) => {
                      const {
                        title,
                        visible = true,
                        url,
                        onClick = (): void => {},
                        isBlank = false,
                      } = item

                      if (!visible) {
                        return null
                      }

                      return (
                        <LinkItem key={title}>
                          {isBlank ? (
                            <UnsafeNewTabLink href={url} onClick={onClick}>
                              {title}
                            </UnsafeNewTabLink>
                          ) : (
                            <a href={url} onClick={onClick}>
                              {title}
                            </a>
                          )}
                        </LinkItem>
                      )
                    })}
                  </Items>
                </Group>
              )
            })}
          </Column>
        )
      })}
    </Container>
  )
}

const LinkItem = styled.div`
  a {
    color: ${({ theme }): string => theme.colors.text.secondary};
  }
`

const Items = styled.div`
  font-size: 14px;

  ${LinkItem}:not(:last-child) {
    margin-bottom: 20px;
  }
`

const GroupTitle = styled.div<{ groupType: 'primary' | 'secondary' }>`
  font-size: 12px;
  font-weight: 700;
  color: ${({ groupType, theme }): string =>
    groupType === 'primary'
      ? theme.colors.brand.secondary
      : theme.colors.text.secondary};
`

const Group = styled.div`
  ${GroupTitle} {
    margin-bottom: 20px;
  }
`

const Column = styled.div<{ keepAllWord: boolean }>`
  min-width: 210px;
  white-space: ${({ keepAllWord }): string =>
    keepAllWord ? 'nowrap' : 'inherit'};
  word-break: ${({ keepAllWord }): string =>
    keepAllWord ? 'keep-all' : 'inherit'};
  padding: 15px;

  & + & {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:empty {
    display: none;
  }

  &:empty + & {
    border-left: none;
  }

  ${Group}:not(:last-child) {
    margin-bottom: 24px;
  }
`

const Container = styled.div`
  display: flex;
`

export type { Column }
export { Group, GroupTitle, Items }
export default Content

/**
 * @output e.g. 2024/5/21
 */
export const DEFAULT_DATE_FORMAT = 'YYYY/MM/DD'
/**
 * @output e.g. 2024/5/21 12:34
 */
export const DATE_TIME_SHORT_FORMAT = 'YYYY/MM/DD HH:mm'
/**
 * @output e.g. 2024/5/21 12:34:56
 */
export const DATE_TIME_MEDIUM_FORMAT = 'YYYY/MM/DD HH:mm:ss'

import { FunctionComponent } from 'react'

export const LogoutIcon: FunctionComponent = () => (
  <svg
    fill='currentColor'
    height='1em'
    version='1.1'
    viewBox='0 0 24 24'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M9 20h-4c-0.6 0-1-0.4-1-1v-14c0-0.6 0.4-1 1-1h4c0.6 0 1-0.4 1-1s-0.4-1-1-1h-4c-1.7 0-3 1.3-3 3v14c0 1.7 1.3 3 3 3h4c0.6 0 1-0.4 1-1s-0.4-1-1-1z'></path>
    <path d='M21.9 12.4c0.1-0.2 0.1-0.5 0-0.8-0.1-0.1-0.1-0.2-0.2-0.3l-5-5c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l3.3 3.3h-9.6c-0.6 0-1 0.4-1 1s0.4 1 1 1h9.6l-3.3 3.3c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3l5-5c0.1-0.1 0.2-0.2 0.2-0.3z'></path>
  </svg>
)

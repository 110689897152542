import Script from 'next/script'
import React, { FunctionComponent, useState, useCallback } from 'react'
import useSyncRadarUserProps from '@/hooks/use-sync-radar-user-props'

interface GoogleAnalyticsScriptProps {
  /**
   * This callback will be called after the Google Analytics has configured and every time the component is mounted
   */
  onReady?: () => void
}

const GoogleAnalyticsScript: FunctionComponent<GoogleAnalyticsScriptProps> = ({
  onReady,
}) => {
  const [googleTagManagerReady, setGoogleTagManagerReady] = useState(false)

  useSyncRadarUserProps({
    enabled: googleTagManagerReady,
    syncFn: useCallback((userProps) => {
      window.dataLayer.push(['user', userProps])
      window.gtag('config', process.env.NEXT_PUBLIC_GA4_ID || 'N/A', {
        send_page_view: false,
        user_id: userProps.userId,
        user_properties: userProps,
      })
    }, []),
  })

  return (
    <>
      <Script
        id='google-tag-manager'
        onReady={(): void => setGoogleTagManagerReady(true)}
      >
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`}
      </Script>
      {googleTagManagerReady && (
        <Script id='google-analytics' onReady={onReady}>
          {`
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GTAG_ID}');
            gtag('config', '${process.env.NEXT_PUBLIC_GA4_ID}', { 'send_page_view': false });
        `}
        </Script>
      )}
    </>
  )
}

export default GoogleAnalyticsScript

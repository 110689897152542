import { ThunkDispatch } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { WorkspacesAPITagType } from '@/api/api'
import { competitiveAnalysesApi } from '@/api/workspace-api/competitive-analyses-api'
import { RootState } from '@/store/store'

const updatePostCollectionPostQueryData = (
  state: RootState,
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  postId: string,
  isCollected: boolean,
): void => {
  const endpointsToUpdate = competitiveAnalysesApi.util.selectInvalidatedBy(
    state,
    [WorkspacesAPITagType.PostCollectionPost],
  )

  endpointsToUpdate.forEach(({ endpointName, originalArgs }) => {
    switch (endpointName) {
      case 'fetchCompetitiveKeywordTopTenPostList':
        dispatch(
          competitiveAnalysesApi.util.updateQueryData(
            endpointName,
            originalArgs,
            (draft) => {
              const foundPost = draft.find((post) => post.post_id === postId)
              if (foundPost) {
                foundPost.is_post_collected = isCollected
              }
            },
          ),
        )
        break
      case 'fetchCompetitiveMentionTopTenPostList':
        dispatch(
          competitiveAnalysesApi.util.updateQueryData(
            endpointName,
            originalArgs,
            (draft) => {
              const foundPost = draft.find((post) => post.post_id === postId)
              if (foundPost) {
                foundPost.is_post_collected = isCollected
              }
            },
          ),
        )
        break
    }
  })
}

export default updatePostCollectionPostQueryData

import { z } from 'zod'
import {
  competitiveKolListChartsSchema,
  competitiveKolListEffectiveChartSchema,
  competitiveKolListMetricChartsSchema,
  competitiveKolStatisticSchema,
  competitiveStaticHashTagsSchema,
} from '@/types/schema/competitive-analyses/competitive-brand-analyses'
import { PlatformShortcode } from '@/utils/convert-platform'

export const competitiveKolStatisticListResponseSchema = z.object({
  data: z.array(competitiveKolStatisticSchema),
  next_page: z.string().nullable(),
  previous_page: z.string().nullable(),
  total: z.number(),
})

export type CompetitiveKolStatisticListResponse = z.infer<
  typeof competitiveKolStatisticListResponseSchema
>

const engagementCountChart = z.object({
  uuid: z.string(),
  name: z.string(),
  starred_kol: z.boolean(), // 主要品牌 true or false
  engagement_counts: z
    .object({
      platform: z.enum([
        PlatformShortcode.Facebook,
        PlatformShortcode.Instagram,
        PlatformShortcode.YouTube,
      ]),
      engagement_count: z.number(),
    })
    .array(),
})

export type EngagementCountChart = z.infer<typeof engagementCountChart>

export const competitiveKolStatisticListChartsResponseSchema = z.object({
  follower_percentage_charts: z
    .object({
      uuid: z.string(),
      name: z.string(),
      starred_kol: z.boolean(), // 主要品牌 true or false
      follower_percentages: z
        .object({
          platform: z.enum([
            PlatformShortcode.Facebook,
            PlatformShortcode.Instagram,
            PlatformShortcode.YouTube,
          ]),
          follower_percentage: z.number(),
        })
        .array(),
    })
    .array(),
  engagement_count_charts: engagementCountChart.array(),
})
export type CompetitiveKolStatisticListCharts = z.infer<
  typeof competitiveKolStatisticListChartsResponseSchema
>

export type CompetitiveBrandAnalysisKolListEffectivenessResponse = z.infer<
  typeof competitiveKolListEffectiveChartSchema
>

export type CompetitiveBrandAnalysisKolListChartsResponse = z.infer<
  typeof competitiveKolListChartsSchema
>

export type CompetitiveBrandAnalysisStaticHashTagListResponse = z.infer<
  typeof competitiveStaticHashTagsSchema
>

export type CompetitiveBrandAnalysisKolListMetricChartsResponse = z.infer<
  typeof competitiveKolListMetricChartsSchema
>

import { createContext, RefObject, useContext } from 'react'
import { Nullable } from '@/types/nullable'
import { Optional } from '@/types/optional'

export type IrmContentContextProps = Optional<{
  containerRef: Nullable<RefObject<HTMLDivElement>>
  isSiderCollapsed: boolean
}>

export const IrmContentContext =
  createContext<IrmContentContextProps>(undefined)

const useIrmContentContext = (): IrmContentContextProps =>
  useContext(IrmContentContext)

export default useIrmContentContext

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import defaultProfileSrc from '@/assets/images/kol-profile-default.jpg'
import UnreadMessageCountBadge from '@/components/campaign/chatroom/unread-message-count-badge'
import { KolThumbnail } from '@/components/common/kol-thumbnail'
import { Conversation } from '@/store/chatroom'
import { getThumbnail } from '@/utils/get-thumbnails'

type CampaignCaseRowProps = {
  conversation: Conversation
  currentConversationId: string
  onClick?: () => void
}

const CampaignCaseRow: FunctionComponent<CampaignCaseRowProps> = ({
  conversation,
  currentConversationId,
  onClick,
}) => {
  const campaign = conversation.campaignCase?.campaign

  return (
    <Wrapper onClick={onClick}>
      <HStack>
        <ThumbnailWrapper>
          <KolThumbnail
            src={
              campaign?.brandRecognitionSnap?.iconFile?.signedUrl ??
              defaultProfileSrc
            }
          />
          <KolThumbnail
            imageSize={32}
            src={getThumbnail(conversation.campaignCase?.kolUUID)}
          />
        </ThumbnailWrapper>
        <VStack>
          <BrandName>{campaign?.brandRecognitionSnap?.name}</BrandName>
          <CampaignName>{campaign?.name}</CampaignName>
        </VStack>
      </HStack>
      {conversation.id !== currentConversationId && (
        <UnreadMessageCountBadge channelId={conversation.id} fontSize={14} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`

const HStack = styled.div`
  align-items: center;
  display: flex;
  min-width: 0;
  width: 100%;

  > :not(:last-child) {
    margin-right: 10px;
  }
`

const VStack = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const BrandName = styled.p`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CampaignName = styled(BrandName)`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`

const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  > div:nth-child(2) {
    position: relative;
    left: -8px;
  }
`

export default CampaignCaseRow

import { useEffect, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { IntersectionObserverHookRefCallback } from 'react-intersection-observer-hook'
import { useSet } from 'react-use'
import { InstagramPost, useFetchInstagramPagePosts } from '@/api/meta-api'

type UseInstagramPosts = (
  accessToken?: string,
  pageID?: string,
) => {
  posts: InstagramPost[]
  loading: boolean
  loadingRef: IntersectionObserverHookRefCallback
  hasNextPage: boolean
}

/**
 * @Todo Extract to shared useRTKInfinite
 */
const useInstagramPosts: UseInstagramPosts = (
  accessToken = '',
  pageID = '',
) => {
  const [, { add, has, reset }] = useSet<string>()
  const [after, setAfter] = useState<string>()
  const [posts, setPosts] = useState<InstagramPost[]>([])

  const { currentData, isLoading } = useFetchInstagramPagePosts(
    {
      pageID,
      accessToken,
      after,
    },
    { skip: pageID === '' || accessToken === '' },
  )

  const [loadingRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !!currentData?.paging?.next,
    onLoadMore: () => setAfter(currentData?.paging?.cursors.after),
    rootMargin: '0px 0px 0px 0px',
  })

  useEffect(() => {
    reset()
  }, [pageID, reset])

  useEffect(() => {
    if (currentData) {
      if (!after) {
        setPosts(currentData.data)
        return
      }

      if (after && !has(after)) {
        add(after)
        setPosts((prevPosts) => [...prevPosts, ...currentData.data])
      }
    }
  }, [add, after, currentData, has])

  return {
    posts,
    loading: isLoading,
    loadingRef,
    hasNextPage: !!currentData?.paging?.next,
  }
}

export default useInstagramPosts

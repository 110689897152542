import { useState, type Dispatch, type SetStateAction } from 'react'
import { useUpdateEffect } from 'react-use'

const useHideHelpScout = (): {
  isHelpScoutHidden: boolean
  setIsHelpScoutHidden: Dispatch<SetStateAction<boolean>>
} => {
  const [isHidden, setIsHidden] = useState(false)

  useUpdateEffect(() => {
    const beacon = document.getElementById('beacon-container')?.style

    if (isHidden) {
      beacon?.setProperty('display', 'none')
    } else {
      beacon?.setProperty('display', 'block')
    }
  }, [isHidden])

  return { isHelpScoutHidden: isHidden, setIsHelpScoutHidden: setIsHidden }
}

export default useHideHelpScout

import { useFetchUserStatusQuery } from '@/api/user-api'
import { CountryCode } from '@/constants/country-currency'
import { getDefaultCountryCode } from '@/utils/get-default-country-code'

interface UseDefaultCountry {
  (props?: {
    checkCode?: CountryCode
    availableCountries?: CountryCode[]
  }): string
}

const useDefaultCountry: UseDefaultCountry = ({
  checkCode,
  availableCountries,
} = {}) => {
  const { data: userStatus } = useFetchUserStatusQuery()

  return getDefaultCountryCode(
    checkCode,
    userStatus?.currentWorkspace.countryCode,
    availableCountries ?? userStatus?.currentWorkspace.availableServices,
  )
}

export default useDefaultCountry

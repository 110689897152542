import { EllipsisOutlined } from '@ant-design/icons'
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  notification,
  Table,
  Tag,
} from 'antd'
import { ColumnProps } from 'antd/es/table'
import { PresetStatusColorType } from 'antd/lib/_util/colors'
import { head, isEmpty, isNull, isUndefined } from 'lodash-es'
import { FunctionComponent, ReactElement, ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCopyToClipboard } from 'react-use'
import styled from 'styled-components'
import { nameof } from 'ts-simple-nameof'
import { useDeleteCampaignPostMutation } from '@/api/campaigns-api'
import CampaignDetailDrawer from '@/components/campaign/campaign-detail-drawer'
import UnreadMessageCountBadge from '@/components/campaign/chatroom/unread-message-count-badge'
import useEstApprovePaymentDate from '@/components/campaign/hooks/use-est-approve-payment-date'
import { useAddPostModal } from '@/components/campaign/kol/add-post-modal/add-post-modal'
import {
  CampaignContentWrapper,
  ClickableContent,
  Contract,
  NA,
  UTMLinkWrapper,
  WaitForSetting,
} from '@/components/campaign/kol/case'
import { ApproveAmountWrapper } from '@/components/campaign/kol/finish/finish-case-table'
import { KolInfoWrapper } from '@/components/campaign/kol/hooks/use-pending-cases-columns'
import { RunningCaseProps } from '@/components/campaign/kol/running/running-cases'
import SummaryHead from '@/components/campaign/kol/summary-head'
import { KolThumbnail } from '@/components/common/kol-thumbnail'
import { Icon } from '@/components/custom-icon'
import { ActionsWrapper } from '@/components/kol/irm/common/action-wrapper'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import { FixedLeft, FixedRight } from '@/constants/column-fixed-placements'
import useAgentInfo from '@/hooks/use-agent-info'
import { useAuth } from '@/hooks/use-auth'
import useCheckAccountInfo from '@/hooks/use-check-account-info'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Campaign } from '@/store/campaign'
import { setSelectedCampaignCase } from '@/store/campaign-case'
import {
  switchConversation,
  toggleMessageComposeVisible,
} from '@/store/chatroom'
import {
  CampaignCase,
  CampaignCaseColumn,
  CampaignKolPost,
} from '@/types/campaign-case'
import {
  CampaignKolStatus,
  ModelContractAcception,
  OrderStatus,
} from '@/types/mock-api-types'
import { ampli } from '@/utils/ampli'
import { convertPlatformType } from '@/utils/convert-platform'
import useContractModal from '@/utils/hooks/use-contract-modal'
import { parseAsNumber } from '@/utils/parse-as-number'
import { openTab } from '@/utils/routes/open-tab'
import { convertUTMInfo } from '@/utils/utm'

/**
 * @todo move to utils
 */
type GetCaseStatusTagColor = (signed: boolean) => string

export const getAcceptionStatusTagColor: GetCaseStatusTagColor = (signed) => {
  return signed ? 'success' : 'processing'
}

type IsSignedAcception = (acception?: ModelContractAcception) => boolean

export const isSignedAcception: IsSignedAcception = (acception) => {
  return !!acception?.acceptTime
}

enum PaymentStatus {
  Pending = 'pending',
  PostsChecking = 'posts_checking',
  PostsChecked = 'posts_checked',
  FinanceChecking = 'finance_checking',
  Holding = 'holding',
  Withdraw = 'withdraw',
  Cancel = 'cancel',
}

interface PaymentStatusTagConfig {
  status: PaymentStatus
  color: PresetStatusColorType
  orderStatusList: OrderStatus[]
  hasSubInfo?: boolean
  requiredUploadedAllPosts?: boolean
}

export const paymentStatusConfigs: PaymentStatusTagConfig[] = [
  {
    status: PaymentStatus.Pending,
    orderStatusList: [OrderStatus.Pending],
    hasSubInfo: true,
    color: 'default',
  },
  {
    status: PaymentStatus.PostsChecked,
    orderStatusList: [OrderStatus.Waiting],
    requiredUploadedAllPosts: true,
    hasSubInfo: true,
    color: 'warning',
  },
  {
    status: PaymentStatus.PostsChecking,
    orderStatusList: [OrderStatus.Waiting],
    hasSubInfo: true,
    color: 'warning',
  },
  {
    status: PaymentStatus.FinanceChecking,
    orderStatusList: [OrderStatus.FinanceChecking, OrderStatus.Approved],
    requiredUploadedAllPosts: true,
    hasSubInfo: true,
    color: 'processing',
  },
  {
    status: PaymentStatus.Holding,
    orderStatusList: [
      OrderStatus.Holding,
      OrderStatus.Rejected,
      OrderStatus.ApiRefundFailed,
    ],
    hasSubInfo: true,
    color: 'error',
  },
  {
    status: PaymentStatus.Withdraw,
    orderStatusList: [OrderStatus.Withdraw],
    requiredUploadedAllPosts: true,
    color: 'success',
  },
  {
    status: PaymentStatus.Cancel,
    orderStatusList: [OrderStatus.Refunded, OrderStatus.Canceled],
    color: 'error',
  },
]

export interface PaymentStatusTag {
  color: PresetStatusColorType
  tagI18nKey: I18nId
}

const getInternalPaymentStatus = (
  paymentStatus: CampaignCase['paymentStatus'],
): PaymentStatusTag => {
  const colorMap: Record<string, PresetStatusColorType> = {
    paid: 'success',
    processing: 'processing',
    pending: 'default',
    'not-need': 'default',
  }

  const descriptionMap: Record<string, string> = {
    paid: 'kol:payment_status_withdraw',
    processing: 'campaign:internal_payment_status_process',
    pending: 'campaign:internal_payment_status_pending',
    'not-need': 'campaign:internal_payment_status_no_action',
  }

  return {
    color: colorMap[paymentStatus],
    tagI18nKey: descriptionMap[paymentStatus] as I18nId,
  }
}

export const getPaymentStatus = (
  campaignCase: CampaignCaseColumn,
): (PaymentStatusTag & { subInfoI18nKey?: I18nId }) | undefined => {
  const { campaign, paymentStatus, orderStatus, kolUploadedAllPostsAt } =
    campaignCase

  if (campaign.snapIsInternal) {
    return getInternalPaymentStatus(paymentStatus)
  }

  if (isNull(orderStatus)) {
    return
  }

  const { status, color, hasSubInfo } =
    paymentStatusConfigs.find(
      ({ orderStatusList, requiredUploadedAllPosts }) => {
        return (
          orderStatusList.includes(orderStatus) &&
          (!requiredUploadedAllPosts || !isNull(kolUploadedAllPostsAt))
        )
      },
    ) ?? {}

  if (status) {
    return {
      color: color ?? 'default',
      tagI18nKey: `kol:payment_status_${status}`,
      subInfoI18nKey: hasSubInfo
        ? (`kol:payment_status_${status}_description` as I18nId)
        : undefined,
    }
  }
}

export const openAttachment = (url?: string): void => {
  if (url) {
    openTab(url).catch((e) => console.error(e))
  }
}

export const disablePostUpdateStatus = [
  OrderStatus.Approved,
  OrderStatus.FinanceChecking,
  OrderStatus.Withdraw,
]

const RunningCaseTable: FunctionComponent<RunningCaseProps> = ({
  response,
  isFetching,
  onChangePage,
}) => {
  const dispatch = useDispatch()
  const { showContract } = useContractModal()
  const { check: checkAccountInfo } = useCheckAccountInfo()
  const { getEstApprovePaymentDate } = useEstApprovePaymentDate()

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>()
  const { formatNumber, formatMessage } = useIntl()
  const { toggleModal } = useAddPostModal()
  const { isAgent } = useAuth()
  const { agencyKOL } = useAgentInfo()
  const [, copyToClipboard] = useCopyToClipboard()

  const [deleteCampaignPost] = useDeleteCampaignPostMutation()

  const openAddPostModal = (campaignCase: CampaignCase): void => {
    dispatch(setSelectedCampaignCase(campaignCase))
    toggleModal()
  }

  const operationMenu = (campaignCase: CampaignCaseColumn): ReactElement => (
    <Menu>
      <Menu.Item
        disabled={!campaignCase.finishedAllContract || agencyKOL}
        onClick={(): void => openAddPostModal(campaignCase)}
      >
        <Icon type='plus' />
        {formatMessage({ id: 'kol:upload_posts' })}
      </Menu.Item>
      {!agencyKOL && <Menu.Divider />}
      {!agencyKOL && (
        <Menu.Item>
          <MessageWrapper
            onClick={(): void => {
              dispatch(switchConversation(campaignCase.channelId))
              dispatch(toggleMessageComposeVisible())
            }}
          >
            <Icon type='message' />
            {formatMessage({ id: 'kol:message' })}
            <UnreadMessageCountBadge
              channelId={campaignCase.channelId}
              marginLeft={6}
            />
          </MessageWrapper>
        </Menu.Item>
      )}
    </Menu>
  )

  const handleShowContract = (
    campaignId: number,
    campaignName: string,
    contractAcception?: ModelContractAcception,
  ): void => {
    if (checkAccountInfo() && contractAcception) {
      const campaignInfo = {
        id: campaignId,
        name: campaignName,
      }
      showContract(contractAcception, campaignInfo)
    }
  }

  const onDelete = (
    post: CampaignKolPost,
    campaignId: number,
    campaignKolId: number,
  ): void => {
    Modal.confirm({
      title: formatMessage({ id: 'kol:delete_post' }),
      content: formatMessage({ id: 'kol:confirm_delete_the_post' }),
      onOk: async () => {
        try {
          await deleteCampaignPost({
            campaignId,
            campaignKolId,
            postId: post.id,
          }).unwrap()

          notification.success({
            message: formatMessage({ id: 'kol:delete_post_success' }),
            placement: 'bottomRight',
          })
        } catch (error) {
          console.warn(error)
          notification.error({
            message: formatMessage({ id: 'kol:delete_post_failed' }),
            placement: 'bottomRight',
          })
        }
      },
    })
  }

  const handleCheckDetail = (campaign: Campaign): void => {
    ampli.kolVisitCampaign({
      campaignId: campaign.id || 0,
      campaignName: campaign.name || '',
    })
    setSelectedCampaign(campaign)
  }

  const columns: ColumnProps<CampaignCaseColumn>[] = [
    {
      title: formatMessage({ id: 'kol:campaign_content' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.campaign),
      width: 400,
      fixed: FixedLeft,
      onCell: (campaignCase: CampaignCaseColumn) => ({
        rowSpan: campaignCase.isHead ? campaignCase.campaign?.kolsCount : 0,
      }),
      render: (campaign: CampaignCaseColumn['campaign']): ReactNode => {
        return (
          <CampaignContentWrapper>
            <SummaryHead
              campaign={campaign}
              onCheckDetailClick={handleCheckDetail}
            />
          </CampaignContentWrapper>
        )
      },
    },
    isAgent
      ? {
          title: formatMessage({ id: 'campaign:field_influencer' }),
          dataIndex: nameof<CampaignCaseColumn>((p) => p.kolUUID),
          render: (
            kolUUID: CampaignCaseColumn['kolUUID'],
            campaignCase: CampaignCaseColumn,
          ): ReactNode => {
            return (
              <KolInfoWrapper>
                <KolThumbnail kolUuid={kolUUID} />
                <span>{head(campaignCase.kolName)}</span>
              </KolInfoWrapper>
            )
          },
        }
      : {},
    {
      title: formatMessage({ id: 'kol:cooperation_fee' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.reward),
      width: 120,
      render: (reward: CampaignCaseColumn['reward']): ReactNode => {
        return reward === null ? (
          <WaitForSetting>
            {formatMessage({ id: 'kol:wait_brand_setting' })}
          </WaitForSetting>
        ) : (
          `$ ${formatNumber(reward)}`
        )
      },
    },
    {
      title: formatMessage({ id: 'campaign:condition_utm_link_title' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.utmInfo),
      width: 200,
      render: (info: CampaignCaseColumn['utmInfo']): ReactNode => {
        if (info) {
          const utmLink = convertUTMInfo(info)
          return (
            <UTMLinkWrapper>
              <p>{utmLink}</p>
              <Button
                disabled={agencyKOL}
                type='link'
                onClick={(): void => {
                  copyToClipboard(utmLink)
                  message.success(
                    formatMessage({ id: 'general:successfully_copied' }),
                  )
                }}
              >
                <Icon type='elIconCopy' />
              </Button>
            </UTMLinkWrapper>
          )
        } else {
          return <NA>N/A</NA>
        }
      },
    },
    {
      title: formatMessage({ id: 'general:contract' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.campaignKolContracts),
      render: (
        campaignKolContracts: CampaignCaseColumn['campaignKolContracts'],
        campaignCase: CampaignCaseColumn,
      ): ReactNode => {
        return campaignKolContracts.length === 0 ? (
          <WaitForSetting>
            {formatMessage({ id: 'kol:wait_brand_setting' })}
          </WaitForSetting>
        ) : (
          <>
            {campaignKolContracts.map((contract) => (
              <Contract key={contract.id}>
                <Tag
                  color={getAcceptionStatusTagColor(
                    isSignedAcception(contract.contractAcception),
                  )}
                >
                  {isSignedAcception(contract.contractAcception)
                    ? formatMessage({ id: 'campaign:contact_status_confirmed' })
                    : formatMessage({ id: 'kol:wait_sign_back' })}
                </Tag>
                <ClickableContent
                  onClick={(): void => {
                    !agencyKOL &&
                      handleShowContract(
                        campaignCase.campaign.id,
                        campaignCase.campaign.name,
                        contract.contractAcception,
                      )
                  }}
                >
                  {contract.contractTitle}
                </ClickableContent>
              </Contract>
            ))}
          </>
        )
      },
    },
    {
      title: formatMessage({ id: 'campaign:field_remittance_advice' }),
      dataIndex: nameof<CampaignCaseColumn>(
        (p) => p.campaignKolLaborDeclaration,
      ),
      render: (
        labor: CampaignCaseColumn['campaignKolLaborDeclaration'],
        data: CampaignCaseColumn,
      ): ReactNode => {
        if (isAgent) {
          return 'N/A'
        }

        if (isEmpty(data.campaignKolContracts)) {
          return (
            <WaitForSetting>
              {formatMessage({ id: 'kol:wait_brand_setting' })}
            </WaitForSetting>
          )
        }

        return labor ? (
          <Contract>
            <Tag
              color={getAcceptionStatusTagColor(
                isSignedAcception(labor.contractAcception),
              )}
            >
              {isSignedAcception(labor.contractAcception)
                ? formatMessage({ id: 'campaign:contact_status_confirmed' })
                : formatMessage({ id: 'kol:wait_sign_back' })}
            </Tag>
            <ClickableContent
              onClick={(): void => {
                !agencyKOL &&
                  handleShowContract(
                    data.campaign.id,
                    data.campaign.name,
                    labor.contractAcception,
                  )
              }}
            >
              {formatMessage({ id: 'campaign:field_remittance_advice' })}
            </ClickableContent>
          </Contract>
        ) : (
          <WaitForSetting>
            {formatMessage({ id: 'kol:wait_brand_setting' })}
          </WaitForSetting>
        )
      },
    },
    {
      title: formatMessage({ id: 'campaign:field_attachment' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.attachments),
      render: (attachments: CampaignCaseColumn['attachments']): ReactNode => {
        return (
          <>
            {attachments.map((attachment) => (
              <Contract key={attachment.id}>
                <Icon type='paper-clip' />
                <ClickableContent
                  onClick={(): void => openAttachment(attachment.signedUrl)}
                >
                  {attachment.fileName}
                </ClickableContent>
              </Contract>
            ))}
          </>
        )
      },
    },
    {
      title: formatMessage({ id: 'kol:post_link' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.campaignKolPosts),
      render: (
        posts: CampaignKolPost[],
        campaignCase: CampaignCaseColumn,
      ): ReactNode => {
        const { orderStatus } = campaignCase

        return (
          <PostsLinkWrapper>
            {posts.map((post) => (
              <Contract key={post.id}>
                <SafeNewTabLink href={post.url}>
                  {convertPlatformType(post.platformType)?.title}
                </SafeNewTabLink>
                {orderStatus &&
                  !disablePostUpdateStatus.includes(orderStatus) && (
                    <ActionsWrapper>
                      <Icon
                        type='delete'
                        onClick={(): void => {
                          if (agencyKOL) {
                            return
                          }

                          onDelete(
                            post,
                            campaignCase.campaign.id,
                            campaignCase.id,
                          )
                        }}
                      />
                    </ActionsWrapper>
                  )}
              </Contract>
            ))}
            {isEmpty(campaignCase.kolUploadedAllPostsAt) && (
              <Button
                disabled={!campaignCase.finishedAllContract || agencyKOL}
                type='link'
                onClick={(): void => openAddPostModal(campaignCase)}
              >
                {formatMessage({ id: 'kol:upload_posts' })}
              </Button>
            )}
          </PostsLinkWrapper>
        )
      },
    },
    {
      title: formatMessage({ id: 'kol:approve_payment_amount' }),
      width: 200,
      dataIndex: nameof<CampaignCaseColumn>(
        (campaignCase) => campaignCase.orderRewardReceived,
      ),
      render: (
        orderRewardReceived: CampaignCaseColumn['orderRewardReceived'],
        campaignCase: CampaignCaseColumn,
      ): ReactNode => {
        if (campaignCase.status === CampaignKolStatus.Canceled) {
          return formatMessage({ id: 'campaign:cancel_cooperation' })
        }

        const reward = parseAsNumber(orderRewardReceived)

        return reward === 0 ? (
          '-'
        ) : (
          <ApproveAmountWrapper>
            <span>{`$ ${formatNumber(reward)}`}</span>
            {!campaignCase.campaign.snapIsInternal && (
              <span>
                {formatMessage(
                  { id: 'kol:approve_payment_amount_sub_info' },
                  {
                    commissionPercentage: campaignCase.snapCommissionPercentage,
                  },
                )}
              </span>
            )}
          </ApproveAmountWrapper>
        )
      },
    },
    {
      title: formatMessage({ id: 'kol:approve_payment_status' }),
      width: 200,
      dataIndex: nameof<CampaignCaseColumn>((p) => p.orderStatus),
      render: (
        orderStatus: CampaignCaseColumn['orderStatus'],
        campaignCase: CampaignCaseColumn,
      ): ReactNode => {
        if (isNull(orderStatus) && !campaignCase.campaign.snapIsInternal) {
          return (
            <WaitForSetting>
              {formatMessage({ id: 'kol:wait_brand_setting' })}
            </WaitForSetting>
          )
        }

        const paymentStatus = getPaymentStatus(campaignCase)

        if (!isUndefined(paymentStatus)) {
          return (
            <PaymentStatusWrapper>
              <Tag color={paymentStatus.color}>
                {formatMessage({
                  id: paymentStatus.tagI18nKey,
                })}
              </Tag>

              {!!paymentStatus.subInfoI18nKey && (
                <PaymentStatusDescription>
                  {formatMessage(
                    {
                      id: paymentStatus.subInfoI18nKey,
                    },
                    {
                      date: getEstApprovePaymentDate(
                        campaignCase.kolUploadedAllPostsAt,
                        campaignCase.orderDepositedAt,
                      ),
                    },
                  )}
                </PaymentStatusDescription>
              )}
            </PaymentStatusWrapper>
          )
        }
      },
    },
    {
      title: formatMessage({ id: 'campaign:field_operation' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.channelId),
      width: 100,
      fixed: FixedRight,
      render: (
        channelId: string,
        campaignCase: CampaignCaseColumn,
      ): ReactNode => {
        return (
          <UnreadMessageCountBadge channelId={channelId}>
            <Dropdown overlay={operationMenu(campaignCase)} trigger={['click']}>
              <OperationWrapper>
                <EllipsisOutlined />
              </OperationWrapper>
            </Dropdown>
          </UnreadMessageCountBadge>
        )
      },
    },
  ]

  return (
    <>
      <Table<CampaignCaseColumn>
        columns={columns}
        dataSource={response?.data}
        loading={isFetching}
        pagination={{
          total: response?.total,
          onChange: onChangePage,
          showSizeChanger: false,
        }}
        scroll={{ x: 'max-content' }}
        showSorterTooltip={false}
        tableLayout='auto'
      />
      <CampaignDetailDrawer
        campaign={selectedCampaign}
        onClose={(): void => setSelectedCampaign(undefined)}
      />
    </>
  )
}

export default RunningCaseTable

const OperationWrapper = styled.div`
  .anticon {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 5px 7px;
    cursor: pointer;

    &:hover {
      border-color: ${({ theme }): string => theme.colors.brand.primaryHover};
      color: ${({ theme }): string => theme.colors.brand.primaryHover};
    }
  }
`

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;

  .anticon {
    margin: 0 4px 0 0;
  }
`

export const PaymentStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .ant-tag {
    margin-bottom: 4px;
  }
`

export const PaymentStatusDescription = styled.div`
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.base.grey.grey3};
`
export const PostsLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > .ant-btn {
    padding: 0;
  }
`

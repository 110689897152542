import logger from '@/utils/logger'

const getItemFromSessionStorage = <T>(key: string): T | null => {
  try {
    const item = sessionStorage.getItem(key)

    if (!item) {
      return null
    }

    const parsedItem = JSON.parse(item)

    return parsedItem as T
  } catch (error) {
    logger.error(error)

    return null
  }
}

export default getItemFromSessionStorage

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import CampaignKolAcceptStatus from '@/components/campaign/chatroom/campaign-kol-accept-status'
import { CampaignCase } from '@/types/campaign-case'

type CampaignCaseInvitationProps = {
  campaignCase: CampaignCase
}

const CampaignCaseInvitation: FunctionComponent<
  CampaignCaseInvitationProps
> = ({ campaignCase }) => {
  const { status, approverType, acceptedTime, appliedTime } = campaignCase

  return (
    <Wrapper>
      <CampaignKolAcceptStatus
        acceptedTime={acceptedTime}
        appliedTime={appliedTime}
        approverType={approverType}
        campaignKolId={campaignCase.id}
        status={status}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }): string => theme.colors.border.border};
`

export default CampaignCaseInvitation

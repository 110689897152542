import { useMemo } from 'react'
import { useFetchUserStatusQuery, WorkspaceType } from '@/api/user-api'
import {
  AllCountryCode,
  AllowedCountryCode,
  AllowedDefaultCountryCodes,
  COUNTRY_I18N_MAP,
  UNLIMITED_COUNTRY_CODE,
} from '@/constants/country-code'
import { useAuth } from '@/hooks/use-auth'
import { usePermissions } from '@/hooks/use-permissions'
import { useIntl } from '@/i18n/hooks/use-intl'
import { GeneralOption } from '@/types/search-types'
import { getDefaultCountryCode } from '@/utils/get-default-country-code'
import getDbPermissions from '@/utils/search/get-db-permissions'

interface SortCountry {
  value: AllowedCountryCode
  sortValue: number
}

interface UseCountriesResult {
  sortedCountries: SortCountry[]
  pivot: number
  deprecatedPermission: boolean
  availableCountries: string[]
  defaultCountry: AllCountryCode
  options: GeneralOption[]
}

interface UseCountriesProps {
  /**
   * todo: remove this, after data is ready. ref: https://www.notion.so/ikala/Prod-Hashtag-626791bfa8b0455193c3671c7bc23c38?pvs=4
   */
  hideNoDataCountry?: boolean
  hasUnlimited?: boolean
}
interface UseCountries {
  (props?: UseCountriesProps): UseCountriesResult
}

const useCountries: UseCountries = ({
  hideNoDataCountry = false,
  hasUnlimited = false,
} = {}) => {
  const { userInfo } = useAuth()
  const { formatMessage } = useIntl()
  const { data: userStatus } = useFetchUserStatusQuery()
  const defaultCountry = getDefaultCountryCode(
    userInfo?.country,
    userStatus?.currentWorkspace.countryCode,
    userStatus?.currentWorkspace.availableServices,
    hasUnlimited,
  )
  const { withDeprecatedPremiumPermission } = usePermissions()
  const unlimitedEnterprise = useMemo(() => {
    return (
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Enterprise
    )
  }, [userStatus?.currentWorkspace.workspaceType])
  const deprecatedPermission =
    unlimitedEnterprise || withDeprecatedPremiumPermission
  const allDropdownCountries: AllowedCountryCode[] = hideNoDataCountry
    ? AllowedDefaultCountryCodes.slice(0, 4)
    : [...AllowedDefaultCountryCodes]
  const availableCountries = deprecatedPermission
    ? getDbPermissions(userStatus?.currentWorkspace.availableServices)
    : allDropdownCountries

  // sort country paid > unpaid
  const pivot = 100
  const sortedCountries: SortCountry[] = allDropdownCountries
    .map((searchCountry, index) => {
      let sortValue = index + pivot
      if (availableCountries.includes(searchCountry ?? '')) {
        sortValue -= pivot
      }
      return { value: searchCountry, sortValue }
    })
    .sort((prev, next) =>
      deprecatedPermission ? prev.sortValue - next.sortValue : 1,
    )

  const options: GeneralOption[] = useMemo(() => {
    const countryOptions: GeneralOption[] = sortedCountries.map(
      (searchCountry) => {
        const disabled =
          deprecatedPermission &&
          searchCountry.sortValue >= pivot &&
          searchCountry?.value !== defaultCountry
        return {
          label: formatMessage({
            id: COUNTRY_I18N_MAP[searchCountry.value],
          }),
          value: searchCountry.value,
          disabled,
        }
      },
    )

    if (!deprecatedPermission && hasUnlimited) {
      countryOptions.unshift({
        label: formatMessage({
          id: 'search:unlimited',
        }),
        value: UNLIMITED_COUNTRY_CODE,
        disabled: false,
      })
    }

    return countryOptions
  }, [
    defaultCountry,
    deprecatedPermission,
    formatMessage,
    hasUnlimited,
    sortedCountries,
  ])

  return {
    defaultCountry,
    sortedCountries,
    pivot,
    deprecatedPermission,
    availableCountries,
    options,
  }
}

export default useCountries

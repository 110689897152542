import { z } from 'zod'

export const tutorialSchema = z.object({
  id: z.number().int().positive(),
  order: z.number().int().positive(),
  red_label_key: z.string(),
  title_key: z.string(),
  content_key: z.string(),
  image_en_url: z.string().url(),
  image_zh_url: z.string().url(),
  is_beta: z.boolean(),
})

export type Tutorial = z.infer<typeof tutorialSchema>

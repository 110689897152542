import { EyeOutlined, LikeOutlined } from '@ant-design/icons'
import { Radio, Skeleton } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { YouTubePlaylistItem, YouTubeVideo } from '@/api/youtube-data-api'
import {
  CreatedTime,
  InfoWrapper,
  Message,
  PostWrapper,
  RadioWrapper,
  Reactions as PostReactions,
} from '@/components/campaign/kol/add-post-modal/post'
import PostImage from '@/components/collections/table/post-image'
import { Icon } from '@/components/custom-icon'
import SafeNewTabLink from '@/components/safe-new-tab-link'
import { useAuth } from '@/hooks/use-auth'
import { useIntl } from '@/i18n/hooks/use-intl'
import getYouTubeVideoLink from '@/utils/get-youtube-video-link'

interface YouTubePlaylistItemRowProps {
  onCheck: (checked: boolean, videoId: string) => void
  playlistItem: YouTubePlaylistItem
  selectedVideoId?: string
  video?: YouTubeVideo
}

const YouTubePlaylistItemRow: FunctionComponent<
  YouTubePlaylistItemRowProps
> = ({ onCheck, playlistItem, selectedVideoId, video }) => {
  const { formatNumber, formatDate } = useIntl()
  const { userInfo } = useAuth()

  const videoId = playlistItem.snippet.resourceId.videoId

  return (
    <PostWrapper key={videoId} onClick={(): void => onCheck(true, videoId)}>
      <RadioWrapper>
        <Radio.Group value={selectedVideoId}>
          <Radio value={videoId} />
        </Radio.Group>
      </RadioWrapper>
      <SafeNewTabLink href={getYouTubeVideoLink(videoId)}>
        <ImageWrapper>
          <PostImage
            kolUuid={userInfo?.kolId}
            thumbnailUrls={[playlistItem.snippet.thumbnails.high.url]}
          />
        </ImageWrapper>
      </SafeNewTabLink>
      <InfoWrapper>
        <CreatedTime>
          {formatDate(playlistItem.snippet.publishedAt, 'dateTimeShort')}
        </CreatedTime>
        {playlistItem.snippet.description &&
          playlistItem.snippet.description.length > 0 && (
            <Message>{playlistItem.snippet.description}</Message>
          )}
        <Reactions>
          {video ? (
            <>
              <span>
                <LikeOutlined />
                {formatNumber(video.statistics.likeCount)}
              </span>
              <span>
                <Icon type='elIconMessageCircle' />
                {formatNumber(video.statistics.commentCount)}
              </span>
              <span>
                <EyeOutlined />
                {formatNumber(video.statistics.viewCount)}
              </span>
            </>
          ) : (
            <Skeleton active paragraph={{ rows: 1 }} title={false} />
          )}
        </Reactions>
      </InfoWrapper>
    </PostWrapper>
  )
}

const ImageWrapper = styled.div`
  height: 100px;
  width: 100px;
  position: relative;
  cursor: pointer;

  img {
    object-fit: cover;
  }
`

const Reactions = styled(PostReactions)`
  .ant-skeleton-paragraph {
    margin: 0;
  }
`

export default YouTubePlaylistItemRow

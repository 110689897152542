import { skipToken } from '@reduxjs/toolkit/query'
import { Modal, Select, Tag, Tooltip } from 'antd'
import { PresetStatusColorType } from 'antd/lib/_util/colors'
import { ColumnProps } from 'antd/lib/table'
import { isEmpty, isUndefined } from 'lodash-es'
import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { nameof } from 'ts-simple-nameof'
import { useFetchUserStatusQuery } from '@/api/user-api'
import { useFetchCampaignQuery } from '@/api/workspaces-api'
import useEstApprovePaymentDate from '@/components/campaign/hooks/use-est-approve-payment-date'
import { isSignedAcception } from '@/components/campaign/kol/running/running-case-table'
import { useCampaignActionPermission } from '@/hooks/use-permission'
import { useSelector } from '@/hooks/use-selector'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { updateCampaignKolPayment } from '@/store/campaign'
import { StyledAntdSelect } from '@/types/antd/styled-antd'
import {
  CampaignKol,
  CampaignKolPaymentStatus,
  CampaignKolStatus,
  OrderStatus,
} from '@/types/mock-api-types'

export enum PaymentStatus {
  Unpaid = 'unpaid',
  PostChecking = 'post_checking',
  PostsChecked = 'post_checked',
  FinanceChecking = 'finance_checking',
  Approve = 'approve',
  Holding = 'holding',
  Reject = 'reject',
  Refunded = 'refunded',
  Paid = 'paid',
}

interface PaymentStatusTag {
  status: PaymentStatus
  color: PresetStatusColorType
  orderStatus: OrderStatus
  hasSubInfo?: boolean
  requiredUploadedAllPosts?: boolean
}

const paymentStatusConfigs: PaymentStatusTag[] = [
  {
    status: PaymentStatus.Unpaid,
    orderStatus: OrderStatus.Pending,
    color: 'default',
    hasSubInfo: true,
  },
  {
    status: PaymentStatus.PostsChecked,
    orderStatus: OrderStatus.Waiting,
    color: 'warning',
    hasSubInfo: true,
    requiredUploadedAllPosts: true,
  },
  {
    status: PaymentStatus.PostChecking,
    orderStatus: OrderStatus.Waiting,
    color: 'warning',
    hasSubInfo: true,
  },
  {
    status: PaymentStatus.FinanceChecking,
    orderStatus: OrderStatus.FinanceChecking,
    color: 'processing',
  },
  {
    status: PaymentStatus.Approve,
    orderStatus: OrderStatus.Approved,
    color: 'success',
    hasSubInfo: true,
  },
  {
    status: PaymentStatus.Holding,
    orderStatus: OrderStatus.Holding,
    color: 'error',
  },
  {
    status: PaymentStatus.Reject,
    orderStatus: OrderStatus.Rejected,
    color: 'error',
  },
  {
    status: PaymentStatus.Reject,
    orderStatus: OrderStatus.ApiRefundFailed,
    color: 'error',
  },
  {
    status: PaymentStatus.Paid,
    orderStatus: OrderStatus.Withdraw,
    color: 'success',
    hasSubInfo: true,
  },
  {
    status: PaymentStatus.Refunded,
    orderStatus: OrderStatus.Refunded,
    color: 'default',
    hasSubInfo: true,
  },
]

interface UseCampaignKolPaymentStatusColumnProps {
  campaignId: number
  setChangedKols?: React.Dispatch<React.SetStateAction<CampaignKol[]>>
  setAccountingInfoModalVisible?: (value: React.SetStateAction<boolean>) => void
}

const { Option } = Select

const useCampaignKolPaymentStatusColumn = ({
  campaignId,
  setChangedKols,
  setAccountingInfoModalVisible,
}: UseCampaignKolPaymentStatusColumnProps): ColumnProps<CampaignKol> => {
  const { formatMessage, formatDate } = useIntl()
  const campaignActionPermission = useCampaignActionPermission()
  const dispatch = useDispatch()
  const { getEstApprovePaymentDate } = useEstApprovePaymentDate()
  const { apiStatus } = useSelector((state) => state.campaign)

  const { data: userStatus } = useFetchUserStatusQuery()
  const { data: campaign } = useFetchCampaignQuery(
    userStatus?.currentWorkspaceId
      ? { workspaceId: userStatus.currentWorkspaceId, campaignID: campaignId }
      : skipToken,
  )

  const loading = apiStatus.updateCampaignKolPayment.requesting

  const onChangePayment = (
    paymentStatus: CampaignKol['paymentStatus'],
    kol: CampaignKol,
  ): void => {
    if (paymentStatus === 'processing') {
      setChangedKols?.([kol])
      setAccountingInfoModalVisible?.(true)
    } else {
      dispatch(
        updateCampaignKolPayment({
          campaignId,
          campaignKolIds: [kol.id],
          campaignKol: { paymentStatus },
        }),
      )
    }
  }

  const onConfirmChangePayment = (
    paymentStatus: CampaignKol['paymentStatus'],
    kol: CampaignKol,
    isNotAllowed: boolean,
  ): void => {
    if (isNotAllowed) {
      Modal.confirm({
        title: formatMessage({
          id: 'campaign:confirm_change_payment_status_title',
        }),
        content: formatMessage({
          id: 'campaign:confirm_change_payment_status_content',
        }),
        okText: formatMessage({ id: 'account:forget_submit_btn' }),
        cancelText: formatMessage({ id: 'general:btn_text_cancel' }),
        onOk: () => {
          onChangePayment(paymentStatus, kol)
        },
        maskClosable: true,
      })
    } else {
      onChangePayment(paymentStatus, kol)
    }
  }

  const getSubIfo = (
    tagConfig: PaymentStatusTag,
    kol: CampaignKol,
  ): string | undefined => {
    if (!tagConfig.hasSubInfo) {
      return undefined
    }

    switch (tagConfig.orderStatus) {
      case OrderStatus.Withdraw:
        return kol.orderWithdrewAt
          ? formatMessage(
              { id: 'campaign:payment_status_paid_sub_info' },
              { date: formatDate(kol.orderWithdrewAt) },
            )
          : undefined
      case OrderStatus.Refunded:
        return kol.orderRefundedAt
          ? formatMessage(
              { id: 'campaign:payment_status_refunded_sub_info' },
              { date: formatDate(kol.orderRefundedAt) },
            )
          : undefined
      case OrderStatus.Waiting:
        const estApprovePaymentDate = getEstApprovePaymentDate(
          kol.kolUploadedAllPostsAt,
          kol.orderDepositedAt,
        )
        return formatMessage(
          {
            id: `campaign:payment_status_${tagConfig.status}_sub_info` as I18nId,
          },
          { date: estApprovePaymentDate },
        )
      default:
        return formatMessage({
          id: `campaign:payment_status_${tagConfig.status}_sub_info` as I18nId,
        })
    }
  }

  const getTag = (campaignKol: CampaignKol): ReactNode => {
    const tagConfig = paymentStatusConfigs.find(
      ({ orderStatus, requiredUploadedAllPosts }) => {
        const checkUploadedAllPosts =
          !isEmpty(campaignKol.kolUploadedAllPostsAt) ||
          !requiredUploadedAllPosts

        return orderStatus === campaignKol.orderStatus && checkUploadedAllPosts
      },
    )

    if (campaignKol.status === CampaignKolStatus.Canceled) {
      return (
        <Tag color='error'>
          {formatMessage({
            id: 'campaign:kol_close_list_status_canceled',
          })}
        </Tag>
      )
    }

    if (!isUndefined(tagConfig)) {
      const subInfo = getSubIfo(tagConfig, campaignKol)

      return (
        <TagWrapper>
          <Tag color={tagConfig.color}>
            {formatMessage({
              id: `campaign:payment_status_${tagConfig.status}`,
            })}
          </Tag>
          {subInfo && <TagSubInfo>{subInfo}</TagSubInfo>}
        </TagWrapper>
      )
    }
  }

  const getInternalTag = (
    value: CampaignKol['paymentStatus'],
    data: CampaignKol,
  ): ReactNode => {
    const isNotAllowed =
      !!data.kol.isVerified &&
      (data.campaignKolContracts.some(
        (contract) => !isSignedAcception(contract.contractAcception),
      ) ||
        data.campaignKolPosts.length === 0)
    const requestPaymentDate = data.radarRequestPayment?.requestPaymentDate

    if (data.isCanceled) {
      return (
        <Tag color='error'>
          {formatMessage({ id: 'campaign:cancel_cooperation' })}
        </Tag>
      )
    }

    if (data.status === CampaignKolStatus.Rejected) {
      return undefined
    }

    return (
      <>
        {value === CampaignKolPaymentStatus.Paid ? (
          <Tag color='success'>
            {formatMessage({
              id: 'campaign:payment_status_paid',
            })}
          </Tag>
        ) : (
          <Tooltip
            title={
              !campaignActionPermission
                ? formatMessage({
                    id: 'workspace:insufficient_permission_tooltip',
                  })
                : undefined
            }
          >
            <InternalPaymentStatusSelect
              disabled={
                data.status === CampaignKolStatus.Done ||
                !campaignActionPermission
              }
              loading={loading}
              popupMatchSelectWidth={false}
              showArrow={data.status !== CampaignKolStatus.Done}
              size='small'
              value={value}
              onChange={(paymentStatus: CampaignKol['paymentStatus']): void =>
                onConfirmChangePayment(paymentStatus, data, isNotAllowed)
              }
            >
              {[
                {
                  key: CampaignKolPaymentStatus.Pending,
                  text: formatMessage({
                    id: 'campaign:internal_payment_status_pending',
                  }),
                },
                {
                  key: CampaignKolPaymentStatus.Processing,
                  text: formatMessage({
                    id: 'campaign:internal_payment_status_process',
                  }),
                },
                {
                  key: CampaignKolPaymentStatus.NotNeed,
                  text: formatMessage({
                    id: 'campaign:internal_payment_status_no_action',
                  }),
                },
              ].map(({ key, text }) => (
                <Option key={key} value={key}>
                  {text}
                </Option>
              ))}
            </InternalPaymentStatusSelect>
          </Tooltip>
        )}
        {requestPaymentDate && (
          <RequestPaymentDate>
            {value === CampaignKolPaymentStatus.Processing &&
              `${formatMessage({
                id: 'general:paid_date',
              })} ${requestPaymentDate}`}
            {value === CampaignKolPaymentStatus.Paid &&
              formatMessage(
                {
                  id: 'campaign:paid_date',
                },
                { paymentDate: requestPaymentDate },
              )}
          </RequestPaymentDate>
        )}
      </>
    )
  }

  return {
    width: 200,
    dataIndex: nameof<CampaignKol>((p) => p.paymentStatus),
    title: formatMessage({ id: 'account:payment_receipt_status' }),
    render: (value: CampaignKol['paymentStatus'], data): ReactNode => {
      return campaign?.snapIsInternal
        ? getInternalTag(value, data)
        : getTag(data)
    },
  }
}

export default useCampaignKolPaymentStatusColumn

const TagSubInfo = styled.div`
  color: ${({ theme }): string => theme.colors.base.grey.grey3};
`

const InternalPaymentStatusSelect = styled(StyledAntdSelect)`
  color: ${({ value, theme }): string => {
    switch (value) {
      case CampaignKolPaymentStatus.Paid:
        return '#52C41A'
      case CampaignKolPaymentStatus.Processing:
        return '#1890FF'
      default:
        return theme.colors.text.secondary
    }
  }};

  .ant-select-selection {
    border-radius: 2px;
    background-color: ${(props): string => {
      switch (props.value) {
        case CampaignKolPaymentStatus.Paid:
          return '#F6FFED'
        case CampaignKolPaymentStatus.Processing:
          return '#E6F7FF'
        default:
          return '#FAFAFA'
      }
    }};
    border-color: ${(props): string => {
      switch (props.value) {
        case CampaignKolPaymentStatus.Paid:
          return '#B7EB8F'
        case CampaignKolPaymentStatus.Processing:
          return '#91D5FF'
        default:
          return '#D9D9D9'
      }
    }};
  }
`
const RequestPaymentDate = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  margin: 4px 0 0;
`
const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > span {
    width: fit-content;
  }
`

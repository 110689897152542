import logger from '@/utils/logger'

const saveItemToSessionStorage = <T>(key: string, data: T): void => {
  try {
    const serializedObject = JSON.stringify(data)

    sessionStorage.setItem(key, serializedObject)
  } catch (error) {
    logger.error(error)
  }
}

export default saveItemToSessionStorage

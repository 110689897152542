import React, { FunctionComponent } from 'react'
import defaultProfileSrc from '@/assets/images/kol-profile-default.jpg'
import {
  CaseSummaryDescription,
  ContentDetail,
  ProductImage,
  ProductImageWrapper,
} from '@/components/campaign/kol/case'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Campaign, CampaignPurpose } from '@/store/campaign'

interface SummaryHeadProps {
  campaign: Campaign
  onCheckDetailClick: (campaign: Campaign) => void
}

const SummaryHead: FunctionComponent<SummaryHeadProps> = ({
  campaign,
  onCheckDetailClick,
}) => {
  const { formatMessage } = useIntl()
  const isPromotingBrand =
    campaign?.campaignPurpose === CampaignPurpose.PromotingBrand
  const imageUrl = isPromotingBrand
    ? campaign?.brands?.[0]?.pictures[0]?.signedUrl
    : campaign?.products?.[0]?.pictures[0]?.signedUrl

  const imageAlt = isPromotingBrand
    ? campaign?.brands?.[0]?.name
    : campaign?.products?.[0]?.name

  return (
    <>
      <ProductImageWrapper>
        <ProductImage
          alt={imageAlt}
          fallbackUrls={[
            campaign?.brandRecognitionSnap?.iconFile?.signedUrl ??
              defaultProfileSrc,
          ]}
          src={imageUrl}
        />
      </ProductImageWrapper>
      <CaseSummaryDescription>
        <span>{campaign.brandRecognitionSnap?.name}</span>
        <h6>{campaign.name}</h6>
        <ContentDetail onClick={(): void => onCheckDetailClick(campaign)}>
          {formatMessage({ id: 'kol:check_case_details' })}
        </ContentDetail>
      </CaseSummaryDescription>
    </>
  )
}

export default SummaryHead

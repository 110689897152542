import { useDispatch } from 'react-redux'
import { useMount } from 'react-use'
import { CookieName } from '@/constants/cookie-name'
import LocalStorageKeys from '@/constants/localstorage-keys'
import { useAuth } from '@/hooks/use-auth'
import { setModalVisible } from '@/store/unauthorized'
import { isClient } from '@/utils/is-client'

const useUserTokenChange: VoidFunction = () => {
  const dispatch = useDispatch()
  const { isLogin } = useAuth()

  useMount(() => {
    if (isClient()) {
      const handleCookieChanged = (e: {
        changed: { name: string }[]
        deleted: { name: string }[]
      }): void => {
        const added = e.changed[0]?.name === CookieName.UserToken
        const deleted = e.deleted[0]?.name === CookieName.UserToken

        // when userToken cookie deleted show modal, when added hide modal
        if (added) {
          dispatch(setModalVisible(false))
        }
        if (deleted) {
          dispatch(setModalVisible(true))
        }
      }

      const handleStorageChanged = (e): void => {
        if (e.key === LocalStorageKeys.Jwt && isLogin) {
          const radarJwt = localStorage.getItem(LocalStorageKeys.Jwt)
          const visible = !radarJwt
          dispatch(setModalVisible(visible))
        }
      }

      window.addEventListener('storage', handleStorageChanged)
      window.cookieStore?.addEventListener('change', handleCookieChanged)
      return () => {
        window.removeEventListener('storage', handleStorageChanged)
        window.cookieStore?.removeEventListener('change', handleCookieChanged)
      }
    }
  })
}

export default useUserTokenChange

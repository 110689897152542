import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill'

export const loadIntlPolyfillIfNeeded = async (
  locale: string,
): Promise<void> => {
  const unsupportedLocale = shouldPolyfill(locale)

  if (!unsupportedLocale) {
    return
  }

  await import('@formatjs/intl-numberformat/polyfill-force')
  await import(`@formatjs/intl-numberformat/locale-data/${unsupportedLocale}`)
}

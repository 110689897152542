import { I18nId } from '@/i18n/config'
import { CampaignCooperationType } from '@/types/mock-api-types'
import { PlatformType } from '@/types/schema/report-schema'
import { convertPlatformType, Platform } from '@/utils/convert-platform'

export enum CampaignCooperationOtherPlatformType {
  AdvertisingLicense = 'advertising_license',
}

type CampaignCooperationTypeConfig = { platform: Platform; i18nId: I18nId } & (
  | {
      otherPlatformType: CampaignCooperationOtherPlatformType.AdvertisingLicense
      maximumQuantity: number
    }
  | {
      otherPlatformType?: CampaignCooperationOtherPlatformType
      maximumQuantity?: number
    }
)

export const getCampaignCooperationTypeConfig = (
  type: CampaignCooperationType,
): CampaignCooperationTypeConfig => {
  switch (type) {
    case CampaignCooperationType.FacebookPost:
    case CampaignCooperationType.FacebookVideo:
    case CampaignCooperationType.FacebookLive:
      return {
        platform: convertPlatformType(PlatformType.Facebook) as Platform,
        i18nId: getCampaignCooperationTypeI18nId(type),
      }
    case CampaignCooperationType.InstagramPost:
    case CampaignCooperationType.InstagramReels:
    case CampaignCooperationType.InstagramLive:
    case CampaignCooperationType.InstagramStory:
      return {
        platform: convertPlatformType(PlatformType.Instagram) as Platform,
        i18nId: getCampaignCooperationTypeI18nId(type),
      }
    case CampaignCooperationType.YouTubeVideo:
      return {
        platform: convertPlatformType(PlatformType.YouTube) as Platform,
        i18nId: getCampaignCooperationTypeI18nId(type),
      }
    case CampaignCooperationType.AdvertisingLicense7Days:
    case CampaignCooperationType.AdvertisingLicense14Days:
    case CampaignCooperationType.AdvertisingLicense30Days:
    case CampaignCooperationType.AdvertisingLicense90Days:
      return {
        platform: convertPlatformType(PlatformType.Other) as Platform,
        otherPlatformType:
          CampaignCooperationOtherPlatformType.AdvertisingLicense,
        maximumQuantity: 1,
        i18nId: getCampaignCooperationTypeI18nId(type),
      }
  }
}

const getCampaignCooperationTypeI18nId = (
  type: CampaignCooperationType,
): I18nId => {
  switch (type) {
    case CampaignCooperationType.FacebookPost:
      return 'campaign:cooperation_type_facebook_post'
    case CampaignCooperationType.FacebookVideo:
      return 'campaign:cooperation_type_facebook_video'
    case CampaignCooperationType.FacebookLive:
      return 'campaign:cooperation_type_facebook_live'
    case CampaignCooperationType.YouTubeVideo:
      return 'campaign:cooperation_type_youtube_video'
    case CampaignCooperationType.InstagramStory:
      return 'campaign:cooperation_type_instagram_story'
    case CampaignCooperationType.InstagramPost:
      return 'campaign:cooperation_type_instagram_post'
    case CampaignCooperationType.InstagramReels:
      return 'campaign:cooperation_type_instagram_reels'
    case CampaignCooperationType.InstagramLive:
      return 'campaign:cooperation_type_instagram_live'
    case CampaignCooperationType.AdvertisingLicense7Days:
      return 'campaign:cooperation_type_advertising_license_7d'
    case CampaignCooperationType.AdvertisingLicense14Days:
      return 'campaign:cooperation_type_advertising_license_14d'
    case CampaignCooperationType.AdvertisingLicense30Days:
      return 'campaign:cooperation_type_advertising_license_30d'
    case CampaignCooperationType.AdvertisingLicense90Days:
      return 'campaign:cooperation_type_advertising_license_90d'
  }
}

export const getSubmitPostLinkI18nIdByCampaignCooperationType = (
  type: CampaignCooperationType,
): I18nId | undefined => {
  switch (type) {
    case CampaignCooperationType.FacebookPost:
      return 'campaign:facebook_post'
    case CampaignCooperationType.FacebookVideo:
      return 'campaign:facebook_video'
    case CampaignCooperationType.FacebookLive:
      return 'campaign:facebook_live'
    case CampaignCooperationType.YouTubeVideo:
      return 'campaign:youtube_video'
    case CampaignCooperationType.InstagramStory:
      return 'campaign:instagram_story'
    case CampaignCooperationType.InstagramPost:
      return 'campaign:instagram_post'
    case CampaignCooperationType.InstagramReels:
      return 'campaign:instagram_reels'
    case CampaignCooperationType.InstagramLive:
      return 'campaign:instagram_live'
    default:
      return undefined
  }
}

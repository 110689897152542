import { useDispatch } from 'react-redux'
import { useMount } from 'react-use'
import LocalStorageKeys from '@/constants/localstorage-keys'
import { cacheLoginTime } from '@/store/me'
import { isClient } from '@/utils/is-client'

/**
 * @description: avoid trigger single login on different tab
 */
const useLoginTimeChange: VoidFunction = () => {
  const dispatch = useDispatch()

  useMount(() => {
    if (isClient()) {
      const handleStorageChanged = (e): void => {
        if (e.key === LocalStorageKeys.LoginTime) {
          const persistLoginTime = localStorage.getItem(
            LocalStorageKeys.LoginTime,
          )

          if (!persistLoginTime) {
            return
          }

          dispatch(cacheLoginTime(persistLoginTime))
        }
      }

      window.addEventListener('storage', handleStorageChanged)

      return function cleanup() {
        window.removeEventListener('storage', handleStorageChanged)
      }
    }
  })
}

export default useLoginTimeChange

import { z } from 'zod'
import { PlatformType } from '@/types/schema/report-schema'

export const platformSchema = z.object({
  platform: z.nativeEnum(PlatformType),
  link_id: z.number(),
  platform_name: z.string(),
  platform_id: z.string(),
  link_url: z.string(),
})

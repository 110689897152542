import { ColumnProps } from 'antd/es/table'
import { head } from 'lodash-es'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { nameof } from 'ts-simple-nameof'
import { CampaignContentWrapper } from '@/components/campaign/kol/case'
import InvolvedPlatforms from '@/components/campaign/kol/involved-platforms'
import SummaryHead from '@/components/campaign/kol/summary-head'
import { KolThumbnail } from '@/components/common/kol-thumbnail'
import { FixedLeft } from '@/constants/column-fixed-placements'
import { useAuth } from '@/hooks/use-auth'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Campaign } from '@/store/campaign'
import { CampaignCase, CampaignCaseColumn } from '@/types/campaign-case'

interface UsePendingCasesColumnsProps {
  setSelectedCampaign: React.Dispatch<
    React.SetStateAction<Campaign | undefined>
  >
  setSelectedCampaignAppliedTime?: React.Dispatch<
    React.SetStateAction<CampaignCase['appliedTime']>
  >
}

const usePendingCasesColumns = ({
  setSelectedCampaign,
  setSelectedCampaignAppliedTime,
}: UsePendingCasesColumnsProps): ColumnProps<CampaignCaseColumn>[] => {
  const { formatMessage, formatNumber } = useIntl()
  const { isAgent } = useAuth()

  return [
    {
      title: formatMessage({ id: 'kol:campaign_content' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.campaign),
      width: 400,
      fixed: FixedLeft,
      onCell: (campaignCase: CampaignCaseColumn) => ({
        rowSpan: campaignCase.isHead ? campaignCase.campaign?.kolsCount : 0,
      }),
      render: (
        campaign: CampaignCaseColumn['campaign'],
        campaignKol: CampaignCaseColumn,
      ): ReactNode => {
        return (
          <CampaignContentWrapper>
            <SummaryHead
              campaign={campaign}
              onCheckDetailClick={(campaign): void => {
                setSelectedCampaign(campaign)
                if (setSelectedCampaignAppliedTime) {
                  setSelectedCampaignAppliedTime(campaignKol.appliedTime)
                }
              }}
            />
          </CampaignContentWrapper>
        )
      },
    },
    isAgent
      ? {
          title: formatMessage({ id: 'campaign:field_influencer' }),
          dataIndex: nameof<CampaignCaseColumn>((p) => p.kolUUID),
          render: (
            kolUUID: CampaignCaseColumn['kolUUID'],
            campaignKol: CampaignCaseColumn,
          ): ReactNode => {
            return (
              <KolInfoWrapper>
                <KolThumbnail kolUuid={kolUUID} />
                <span>{head(campaignKol.kolName)}</span>
              </KolInfoWrapper>
            )
          },
        }
      : {},
    {
      title: formatMessage({ id: 'kol:case_budget_per_person_title' }),
      width: 120,
      dataIndex: nameof<CampaignCaseColumn>((p) => p.campaign),
      render: (campaign: CampaignCaseColumn['campaign']): ReactNode => {
        return `$${formatNumber(campaign.cooperation.budgetPerPerson)}`
      },
    },
    {
      title: formatMessage({ id: 'kol:edit_coop_group_type' }),
      width: 120,
      dataIndex: nameof<CampaignCaseColumn>((p) => p.campaign),
      render: (campaign: CampaignCaseColumn['campaign']): ReactNode => {
        return <InvolvedPlatforms platforms={campaign.platformInvolved} />
      },
    },
  ]
}

export default usePendingCasesColumns

export const KolInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    margin-left: 10px;
    color: ${({ theme }): string => theme.colors.text.secondary};
  }
`

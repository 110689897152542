import { Modal } from 'antd'
import { useFetchContractStatusQuery } from '@/api/user-api'
import { Page } from '@/hooks/use-authorization/constants'
import { useIntl } from '@/i18n/hooks/use-intl'
import { goToPage } from '@/utils/routes/go-to-page'

interface UseCheckAccountInfoReturnType {
  check: (hideCancel?: boolean) => boolean
}

interface UseCheckAccountInfoProps {
  modalZIndex?: number
}

const useCheckAccountInfo = (
  props: UseCheckAccountInfoProps = {},
): UseCheckAccountInfoReturnType => {
  const { formatMessage } = useIntl()

  const { data } = useFetchContractStatusQuery()

  const check = (hideCancel?: boolean): boolean => {
    if (!!data?.accountingInfo) {
      return true
    }

    const goToKolSettings: VoidFunction = () => {
      void goToPage({
        pathname: Page.KolSettings,
        query: { tab: 'accounting' },
      })
    }

    Modal.confirm({
      centered: true,
      zIndex: props.modalZIndex,
      title: formatMessage({
        id: 'campaign:kol_insufficient_account_info_title',
      }),
      content: formatMessage({
        id: 'campaign:kol_insufficient_account_info_content',
      }),
      cancelText: formatMessage({ id: 'general:btn_text_cancel' }),
      cancelButtonProps: hideCancel
        ? { style: { display: 'none' } }
        : { size: 'large' },
      okButtonProps: { size: 'large' },
      okText: formatMessage({
        id: 'campaign:kol_insufficient_account_info_ok_text',
      }),
      onOk: goToKolSettings,
      onCancel: hideCancel ? goToKolSettings : undefined,
    })

    return false
  }

  return { check }
}

export default useCheckAccountInfo

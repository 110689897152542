import { isEmpty } from 'lodash-es'
import { UserStatus } from '@/api/user-api'
import { UserType } from '@/hooks/use-authorization/constants'
import { UserSchema } from '@/types/schema/user-schema'

interface IsFullyAuthorized {
  (user: UserSchema, workspaceStatus?: UserStatus): boolean
}

const isFullyAuthorized: IsFullyAuthorized = (user, workspaceStatus) => {
  const isAd = user.type === UserType.NormalAd
  const hasType = user.type !== UserType.Untyped
  const completeRegistration = user.incompleteRegistration === false
  const emailConfirmed = user.emailConfirmed !== false

  if (!hasType) {
    return false
  }

  if (!completeRegistration) {
    return false
  }

  if (!emailConfirmed) {
    return false
  }

  if (!user.verified) {
    return false
  }

  if (!isAd && !user.linked) {
    return false
  }

  if (isAd && isEmpty(workspaceStatus?.workspaces)) {
    return false
  }

  return true
}

export default isFullyAuthorized

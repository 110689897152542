import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GoogleAccessTokenType, oauthApi } from '@/api/oauth-api'
import { RootState } from '@/store/store'

export enum Part {
  Snippet = 'snippet',
  Statistics = 'statistics',
  ContentDetails = 'contentDetails',
}

export enum YouTubeDataAPITagType {
  Channels = 'Channels',
}

const DEFAULT_MAX_RESULTS = 50

/**
 * Shared
 */
type Thumbnail = {
  url: string
}

type Snippet = {
  description?: string
  publishedAt: string
  title: string
  thumbnails: {
    default: Thumbnail
    medium: Thumbnail
    high: Thumbnail
  }
}

export type Request = {
  /**
   * Identifies a specific page in the result set that should be returned. In an API response, the nextPageToken and prevPageToken properties identify other pages that could be retrieved.
   */
  pageToken?: string

  /**
   * Specifies a comma-separated list of one or more resource properties that the API response will include. If the parameter identifies a property that contains child properties, the child properties will be included in the response. For example, in a channel resource, the contentDetails property contains other properties, such as the uploads properties. As such, if you set *part=contentDetails*, the API response will also contain all of those nested properties.
   */
  part?: Part[]

  /**
   * Specifies the maximum number of items that should be returned to the result set.
   */
  maxResults?: number
}

export type Response<T> = {
  etag: string
  nextPageToken?: string
  prevPageToken?: string
  pageInfo: {
    totalResults: number
    resultsPerPage: number
  }
  items?: T[]
}

/**
 * Channel
 */
export type YouTubeChannel = {
  id: string
  snippet: Snippet
  contentDetails: {
    relatedPlaylists: {
      uploads: string
    }
  }
}

type YouTubeChannelsRequest = Request & {
  /**
   * Return the ids of channels owned by the authenticated user.
   * Defaults: true
   */
  mine?: boolean
}

/**
 * Playlist Items
 */
export type YouTubePlaylistItem = {
  snippet: Snippet & {
    resourceId: {
      videoId: string
    }
  }
}

/**
 * Videos
 */
type YouTubeVideosRequest = Request & {
  id: string | string[]
}

export type YouTubeVideo = {
  id: string
  statistics: {
    commentCount: number
    likeCount: number
    viewCount: number
  }
}

export const youTubeDataAPI = createApi({
  reducerPath: 'youTubeDataAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.googleapis.com/youtube/v3',
    prepareHeaders: (headers: Headers, { getState }) => {
      const { data } = oauthApi.endpoints.fetchGoogleAccessToken.select(
        GoogleAccessTokenType.YouTube,
      )(getState() as RootState)

      if (data?.accessToken) {
        headers.set('authorization', `Bearer ${data.accessToken}`)
      }

      return headers
    },
  }),
  tagTypes: [YouTubeDataAPITagType.Channels],
  endpoints: (builder) => ({
    fetchYouTubeChannels: builder.query<
      Response<YouTubeChannel>,
      YouTubeChannelsRequest
    >({
      query: ({
        part = [Part.Snippet, Part.ContentDetails],
        mine = true,
        maxResults = DEFAULT_MAX_RESULTS,
      }) => {
        return {
          url: '/channels',
          params: {
            part,
            mine,
            maxResults,
          },
        }
      },
      providesTags: [YouTubeDataAPITagType.Channels],
    }),
    fetchVideos: builder.query<Response<YouTubeVideo>, YouTubeVideosRequest>({
      query: ({ id, part = [Part.Statistics], pageToken }) => {
        return {
          url: '/videos',
          params: {
            id,
            part,
            pageToken,
          },
        }
      },
    }),
  }),
})

export const useFetchYouTubeChannelsQuery =
  youTubeDataAPI.endpoints.fetchYouTubeChannels.useQuery
export const useFetchVideosQuery = youTubeDataAPI.endpoints.fetchVideos.useQuery

import Script from 'next/script'
import React, { FunctionComponent } from 'react'

const GoogleTagScript: FunctionComponent = () => {
  return (
    <Script
      id='google-tag'
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_ID}`}
    />
  )
}

export default GoogleTagScript

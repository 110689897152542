import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccountingSchema } from '@/types/schema/accounting-schema'

interface State {
  accountingInfo: AccountingSchema | null
}

const initialState: State = {
  accountingInfo: null,
}

const radarCampaignSlice = createSlice({
  name: 'radarCampaign',
  initialState,
  reducers: {
    setAccountingInfo(state, action: PayloadAction<AccountingSchema | null>) {
      state.accountingInfo = action.payload
    },
  },
})

// export all action
export const { setAccountingInfo } = radarCampaignSlice.actions

export default radarCampaignSlice.reducer

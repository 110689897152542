import { values } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import {
  FeatureFlag,
  useFetchUserInfoQuery,
  useFetchUserStatusQuery,
} from '@/api/user-api'
import { useAuth } from '@/hooks/use-auth'

interface FeatureFlagHook {
  withFeature: (feature: FeatureFlag) => boolean
}

const useFeatureFlags = (): FeatureFlagHook => {
  const { isKol, isAgent } = useAuth()
  const { data: userStatus } = useFetchUserStatusQuery(undefined, {
    skip: isKol,
  })
  const { data: userInfo } = useFetchUserInfoQuery()
  const features = useMemo(() => {
    return (
      (isKol || isAgent ? userInfo : userStatus?.currentWorkspace)
        ?.featureFlags ?? []
    )
  }, [isAgent, isKol, userInfo, userStatus?.currentWorkspace])

  const withFeature = useCallback(
    (feature: FeatureFlag): boolean => {
      return values(FeatureFlag).includes(feature)
        ? features.includes(feature)
        : true
    },
    [features],
  )
  return { withFeature }
}

export default useFeatureFlags

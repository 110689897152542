import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LayoutOption } from '@/types/layout-option'

interface SearchState {
  aiSearchLayout: LayoutOption['value']
  generalLayout: LayoutOption['value']
  viewModeForPost: LayoutOption['value']
  rateLimit: boolean
}

const initialState: SearchState = {
  aiSearchLayout: 'list',
  generalLayout: 'list',
  viewModeForPost: 'grid',
  rateLimit: false,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setAISearchLayout: (
      state,
      action: PayloadAction<LayoutOption['value']>,
    ) => {
      state.aiSearchLayout = action.payload
    },
    setViewModeForPost: (
      state,
      action: PayloadAction<LayoutOption['value']>,
    ) => {
      state.viewModeForPost = action.payload
    },
    setRateLimit: (state, action: PayloadAction<boolean>) => {
      state.rateLimit = action.payload
    },
    setGeneralLayout: (state, action: PayloadAction<LayoutOption['value']>) => {
      state.generalLayout = action.payload
    },
  },
})

export const {
  setAISearchLayout,
  setViewModeForPost,
  setRateLimit,
  setGeneralLayout,
} = searchSlice.actions

export { initialState }
export default searchSlice.reducer

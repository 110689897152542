import { Button } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Space from '@/components/common/antd-v4-backport/space'
import { Icon } from '@/components/custom-icon'
import { useIntl } from '@/i18n/hooks/use-intl'
import { convertPlatformCode, PlatformCode } from '@/utils/convert-platform'

type SupportedPlatformCodes =
  | PlatformCode.Facebook
  | PlatformCode.Instagram
  | PlatformCode.YouTube

interface AuthPagesProps {
  loading?: boolean
  platformCode: SupportedPlatformCodes
  handleAuthClick: (platform: SupportedPlatformCodes) => void
}

const AuthPages: FunctionComponent<AuthPagesProps> = ({
  platformCode,
  handleAuthClick,
  loading,
}) => {
  const { formatMessage } = useIntl()

  const platform = convertPlatformCode(platformCode)
  const isInstagram = platformCode === PlatformCode.Instagram

  return (
    <AuthWrapper>
      <Title>
        {formatMessage(
          { id: 'authorize_social_account:title' },
          { platformTitle: platform?.title },
        )}
      </Title>
      <Description>
        {formatMessage({
          id: isInstagram
            ? 'authorize_social_account:content_instagram'
            : 'authorize_social_account:content',
        })}
      </Description>
      <Space align={isInstagram ? 'end' : 'start'} direction='vertical'>
        <Button
          icon={!isInstagram && <Icon type='sync' />}
          loading={loading}
          type='primary'
          onClick={(): void => handleAuthClick(platformCode)}
        >
          {formatMessage({
            id: isInstagram
              ? 'authorize_social_account:setup_act'
              : 'authorize_social_account:connect_act',
          })}
        </Button>
      </Space>
    </AuthWrapper>
  )
}

export default AuthPages

const Title = styled.div`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin: 0 0 25px;
`

const Description = styled(Title)`
  font-weight: 400;
  font-size: 16px;
`

const AuthWrapper = styled.div`
  .ant-btn {
    height: auto;
    padding: 12px 20px;
  }

  ${Space} {
    width: 100%;
  }
`

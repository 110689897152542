import { EXCLUDED_ALL_KOL_TYPE_CODE } from '@/components/search/filters/common/with-official-filter-content'

export const defaultSearchConditions = {
  anchor: undefined,
  country_code: undefined,
  keyword: undefined,
  platform_type: undefined,
  gender: undefined,
  follower_end_to: undefined,
  follower_start_from: undefined,
  interactiveRateBegin: undefined,
  interactiveRateEnd: undefined,
  fansUpRateBegin: undefined,
  fansUpRateEnd: undefined,
  avgPvRateBegin: undefined,
  avgPvRateEnd: undefined,
  potential: undefined,
  post_end_time: undefined,
  post_start_time: undefined,
  followerRangeLevel: undefined,
  verifiedKol: undefined,
  filter_kol_type: EXCLUDED_ALL_KOL_TYPE_CODE,
  filter_collection: undefined,
  filter_cooperation: undefined,
  filter_unrecommended: undefined,
  minQuotationPrice: undefined,
  maxQuotationPrice: undefined,
  post_type: undefined,
  filter_advertorial: undefined,
  audienceGenderCode: undefined,
  audienceGenderRateBegin: undefined,
  audienceGenderRateEnd: undefined,
  audienceAgeCode: undefined,
  audienceAgeRateBegin: undefined,
  audienceAgeRateEnd: undefined,
  audienceGeoCode: undefined,
  audienceGeoRateBegin: undefined,
  audienceGeoRateEnd: undefined,
  customized_tags_names: undefined,
  languages: undefined,
  keywordFrom: undefined,
  exclude_kol_collection_folder_ids: undefined,
}

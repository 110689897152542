import { createAction, createReducer } from '@reduxjs/toolkit'
import { ACTION_TYPES, ActionPayload, State } from '@/types/redux/irm'

const initialState: State = {
  importModalVisible: {
    uuid: false,
    add: false,
    campaign: false,
    link: false,
    radar: false,
    report: false,
  },
  selectedKOLIDs: [],
  selectedKolList: [], // types: KolSchemaModel
}

export const actions = {
  setImportModalVisible: createAction<
    ActionPayload['SET_IMPORT_MODAL_VISIBLE']
  >(ACTION_TYPES.SET_IMPORT_MODAL_VISIBLE),
  setSelectedKolList: createAction<ActionPayload['SET_SELECTED_KOL_LIST']>(
    ACTION_TYPES.SET_SELECTED_KOL_LIST,
  ),
  setSelectedKOLIDs: createAction<ActionPayload['SET_SELECTED_KOL_IDS']>(
    ACTION_TYPES.SET_SELECTED_KOL_IDS,
  ),
}
export const reducer = createReducer<State>(initialState, (builder) =>
  builder
    .addCase(actions.setImportModalVisible, (state, { payload }) => {
      state.importModalVisible[payload.modal] = payload.visible
    })
    .addCase(actions.setSelectedKOLIDs, (state, { payload }) => {
      state.selectedKOLIDs = payload
    })
    .addCase(actions.setSelectedKolList, (state, { payload }) => {
      state.selectedKolList = payload
    }),
)

import { get } from 'lodash-es'
import { PlatformType } from '@/types/schema/report-schema'

/**
 * @todo migrate to convertPlatform.ts
 * @deprecated
 */
export const getPlatformName = (platformType: number): string => {
  const platformMap = {
    [PlatformType.All]: 'All',
    [PlatformType.YouTube]: 'YouTube',
    [PlatformType.Facebook]: 'Facebook',
    [PlatformType.Instagram]: 'Instagram',
    [PlatformType.TikTok]: 'TikTok',
  }

  return get(platformMap, String(platformType))
}

/**
 * @todo migrate to convertPlatform.ts
 * @deprecated
 */
export const getPlatformShortName = (platformType: number): string => {
  const platformMap = {
    [PlatformType.All]: 'all',
    [PlatformType.YouTube]: 'yt',
    [PlatformType.Facebook]: 'fb',
    [PlatformType.Instagram]: 'ig',
    [PlatformType.TikTok]: 'tiktok',
  }

  return get(platformMap, String(platformType), 'all')
}

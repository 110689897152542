import styled from 'styled-components'
import LogoSVG from '@/components/icon/own-svg/kolradar-logo.svg'

const KOLRadarLogo = styled(LogoSVG)`
  fill: ${({ theme }): string => theme.colors.brand.primary};
  width: 67px;
  height: 40px;

  @media (min-width: 768px) {
    width: 84px;
    height: 50px;
  }
`

export default KOLRadarLogo

import { z } from 'zod'
import { CustomizedTag, customizedTagSchema } from '@/types/schema/search'
import { tutorialSchema } from '@/types/schema/tutorial'

export const customizedTagListSchema = z.array(customizedTagSchema)

// zod recursive type 型別推斷為 any 故自定義 ts 型別
export type CustomizedTagListResponse = CustomizedTag[]

export const tutorialListSchema = z.object({
  data: z.object({ tutorials: z.array(tutorialSchema) }),
})

export type TutorialListResponse = z.infer<typeof tutorialListSchema>

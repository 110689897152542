import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import { useIntl } from '@/i18n/hooks/use-intl'

const AUTO_APPROVE_DAYS = 7

const useEstApprovePaymentDate = (): {
  getEstApprovePaymentDate: (
    kolUploadedAllPostsAt: string | null,
    orderDepositedAt: string | null,
  ) => string
} => {
  const { formatDate } = useIntl()
  const getEstApprovePaymentDate = (
    kolUploadedAllPostsAt: string | null,
    orderDepositedAt: string | null,
  ): string => {
    if (
      [kolUploadedAllPostsAt, orderDepositedAt].every((time) => !isEmpty(time))
    ) {
      const uploadedAllPostsTime = dayjs(kolUploadedAllPostsAt)
      const orderDepositedTime = dayjs(orderDepositedAt)
      const maxDate = uploadedAllPostsTime.isAfter(orderDepositedTime)
        ? uploadedAllPostsTime
        : orderDepositedTime

      return formatDate(maxDate.add(AUTO_APPROVE_DAYS, 'days').toDate())
    } else {
      return ''
    }
  }

  return { getEstApprovePaymentDate }
}

export default useEstApprovePaymentDate

import { Drawer, List, Tabs, Typography } from 'antd'
import { TabsProps } from 'antd/lib/tabs'
import { isEmpty } from 'lodash-es'
import { FunctionComponent, ReactNode, useState } from 'react'
import styled from 'styled-components'
import defaultProfileSrc from '@/assets/images/kol-profile-default.jpg'
import CampaignDetailListItem from '@/components/campaign/campaign-detail-list-item'
import {
  BrandName,
  CampaignName,
  PlatformInvolved,
} from '@/components/campaign/kol/case-list/case-card'
import Space, { SpaceProps } from '@/components/common/antd-v4-backport/space'
import AsyncImage from '@/components/common/async-image'
import BackButton from '@/components/common/buttons/back-button'
import ImageViewerModal, {
  ImageViewerModalProps,
} from '@/components/common/modals/image-viewer-modal'
import { Icon } from '@/components/custom-icon'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import { getI18nId } from '@/constants/general-tag-code'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useIntl } from '@/i18n/hooks/use-intl'
import postTypeI18nIdMap from '@/i18n/map/post-type-i18n-map'
import { Campaign, CampaignPurpose } from '@/store/campaign'
import { StyledProps } from '@/types/antd/styled-antd'
import { convertPlatformShortcode } from '@/utils/convert-platform'

enum Tab {
  Info = 'info',
  Cooperation = 'cooperation',
}

export interface CampaignDetailDrawerProps {
  campaign?: Omit<
    Campaign,
    | 'id'
    | 'isCampaignOver'
    | 'campaignKolStatus'
    | 'partyAOwnerEmail'
    | 'partyAOwnerPhone'
    | 'partyAOwner'
    | 'partyACompany'
    | 'partyATaxId'
    | 'contracts'
    | 'havePaid'
    | 'snapIsInternal'
    | 'campaignKolAmount'
    | 'owner'
  >
  onClose: VoidFunction
  footer?: ReactNode
}

const CampaignDetailDrawer: FunctionComponent<CampaignDetailDrawerProps> = ({
  campaign,
  onClose,
  footer,
}) => {
  const breakpoint = useBreakpoint()
  const { formatMessage, formatDate, formatNumber } = useIntl()

  const [imageViewerProps, setImageViewerProps] =
    useState<Pick<ImageViewerModalProps, 'title' | 'items'>>()

  const isPromotingBrand =
    campaign?.campaignPurpose === CampaignPurpose.PromotingBrand

  const imageUrl =
    (isPromotingBrand
      ? campaign?.brands?.[0]?.pictures[0]?.signedUrl
      : campaign?.products?.[0]?.pictures[0]?.signedUrl) ?? ''

  const imageAlt =
    (isPromotingBrand
      ? campaign?.brands?.[0]?.name
      : campaign?.products?.[0]?.name) ?? ''

  return (
    <Drawer
      destroyOnClose
      bodyStyle={{ padding: 0, height: '100vh' }}
      closable={false}
      open={!!campaign}
      width={breakpoint === 'PC' ? '640' : '100%'}
      zIndex={1001}
      onClose={onClose}
    >
      <Wrapper direction='vertical' size={0}>
        {breakpoint === 'mobile' && (
          <HStack>
            <BackButton onClick={onClose}>
              <Icon type='chevronLeftSolid' />
            </BackButton>
          </HStack>
        )}
        <LayoutContent>
          {campaign && (
            <>
              <TopCard
                direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'}
                size={0}
              >
                <ImageWrapper>
                  <Typography.Paragraph ellipsis={{ rows: 1 }}>
                    {campaign.generalTagCodes
                      .map((code) => formatMessage({ id: getI18nId(code) }))
                      .join('、')}
                  </Typography.Paragraph>
                  <Image
                    alt={imageAlt}
                    fallbackUrls={[
                      campaign?.brandRecognitionSnap?.iconFile?.signedUrl ??
                        defaultProfileSrc,
                    ]}
                    src={imageUrl}
                  />
                </ImageWrapper>
                <ContentWrapper direction='vertical' size={10}>
                  <Space direction='vertical' size={5}>
                    <BrandName ellipsis={{ rows: 1 }}>
                      {campaign.brandRecognitionSnap.name}
                    </BrandName>
                    <CampaignName ellipsis={{ rows: 2 }}>
                      {campaign.name}
                    </CampaignName>
                  </Space>
                  <Space direction='vertical' size={4}>
                    <Space size={4}>
                      <Label>
                        {formatMessage({
                          id: 'kol:case_list_platform_involved',
                        })}
                      </Label>
                      <PlatformInvolved size={4}>
                        {campaign.platformInvolved.map((platformShortcode) => {
                          const platform =
                            convertPlatformShortcode(platformShortcode)
                          return (
                            platform.icon && (
                              <Icon
                                key={platformShortcode}
                                type={platform.icon}
                              />
                            )
                          )
                        })}
                      </PlatformInvolved>
                    </Space>
                    <Label>
                      {formatMessage(
                        { id: 'kol:case_list_campaign_duration' },
                        {
                          duration: `${formatDate(
                            campaign.startAt,
                          )}-${formatDate(campaign.endAt)}`,
                        },
                      )}
                    </Label>
                    <Label>
                      {formatMessage({ id: 'kol:case_list_budget_per_person' })}
                      {formatMessage(
                        { id: 'kol:case_list_budget_per_person_starts_from' },
                        {
                          budgetPerPerson: `$${formatNumber(
                            campaign.cooperation.budgetPerPerson,
                          )}`,
                        },
                      )}
                    </Label>
                  </Space>
                </ContentWrapper>
              </TopCard>
              <StyledTabs defaultActiveKey={Tab.Info}>
                <Tabs.TabPane
                  key={Tab.Info}
                  tab={formatMessage({
                    id: 'kol:campaign_detail_cooperation_info_tab',
                  })}
                >
                  <List>
                    <CampaignDetailListItem
                      content={
                        <Label>{campaign.brandRecognitionSnap.name}</Label>
                      }
                      title={
                        <Label>
                          {formatMessage({
                            id: 'kol:campaign_detail_cooperation_info_brand_name',
                          })}
                        </Label>
                      }
                    />
                    <CampaignDetailListItem
                      content={
                        <TextLink href={campaign.brandRecognitionSnap.website}>
                          {campaign.brandRecognitionSnap.website}
                        </TextLink>
                      }
                      title={
                        <Label>
                          {formatMessage({
                            id: 'kol:campaign_detail_cooperation_info_brand_link',
                          })}
                        </Label>
                      }
                    />
                    <CampaignDetailListItem
                      content={
                        <Label>
                          {campaign.brandRecognitionSnap.description}
                        </Label>
                      }
                      title={
                        <Label>
                          {formatMessage({
                            id: 'kol:campaign_detail_cooperation_info_brand_descriptions',
                          })}
                        </Label>
                      }
                    />
                    <CampaignDetailListItem
                      content={
                        <Space direction='vertical' size={5}>
                          {isPromotingBrand
                            ? campaign.brands?.map((brand) => (
                                <Space
                                  direction='vertical'
                                  key={brand.link}
                                  size={12}
                                >
                                  <TextLink href={brand.link}>
                                    {brand.name}
                                  </TextLink>
                                  <Label>{brand.description}</Label>
                                </Space>
                              ))
                            : campaign.products?.map((product) => (
                                <Space
                                  direction='vertical'
                                  key={product.link}
                                  size={12}
                                >
                                  <Space size={4}>
                                    <TextLink href={product.link}>
                                      {product.name}
                                    </TextLink>
                                    <Label>{`$${formatNumber(
                                      product.value,
                                    )}`}</Label>
                                  </Space>
                                  <Label>{product.description}</Label>
                                </Space>
                              ))}
                        </Space>
                      }
                      title={
                        <Label>
                          {formatMessage({
                            id: isPromotingBrand
                              ? 'kol:campaign_detail_cooperation_info_brands'
                              : 'kol:campaign_detail_cooperation_info_products',
                          })}
                        </Label>
                      }
                    />
                    <CampaignDetailListItem
                      content={
                        <Label>
                          {campaign.postTypes
                            ?.map((postType) =>
                              formatMessage({
                                id: postTypeI18nIdMap[postType],
                              }),
                            )
                            .join('、')}
                        </Label>
                      }
                      title={
                        <Label>
                          {formatMessage({
                            id: 'campaign:post_types',
                          })}
                        </Label>
                      }
                    />
                  </List>
                </Tabs.TabPane>
                <Tabs.TabPane
                  key={Tab.Cooperation}
                  tab={formatMessage({
                    id: 'kol:campaign_detail_cooperation_detail_tab',
                  })}
                >
                  <List>
                    {campaign.cooperation.specifiedContentValue && (
                      <CampaignDetailListItem
                        content={
                          <Label>
                            {campaign.cooperation.specifiedContentValue}
                          </Label>
                        }
                        title={
                          <Label>
                            {formatMessage({
                              id: 'campaign:cooperation_content_requirements_keywords',
                            })}
                          </Label>
                        }
                      />
                    )}
                    {campaign.cooperation.postWrittenScenarioValue && (
                      <CampaignDetailListItem
                        content={
                          <Label>
                            {campaign.cooperation.postWrittenScenarioValue}
                          </Label>
                        }
                        title={
                          <Label>
                            {formatMessage({
                              id: 'campaign:cooperation_content_requirements_scenario',
                            })}
                          </Label>
                        }
                      />
                    )}
                    {!isEmpty(campaign.cooperation.specifiedHashtagsValue) && (
                      <CampaignDetailListItem
                        content={
                          <Label>
                            <WrapSpace size={4}>
                              {campaign.cooperation.specifiedHashtagsValue.map(
                                (hashtag) => (
                                  <Label key={hashtag}>{`#${hashtag}`}</Label>
                                ),
                              )}
                            </WrapSpace>
                          </Label>
                        }
                        title={
                          <Label>
                            {formatMessage({
                              id: 'campaign:cooperation_content_requirements_hashtags',
                            })}
                          </Label>
                        }
                      />
                    )}
                    {!isEmpty(campaign.cooperation.specifiedMentionsValue) && (
                      <CampaignDetailListItem
                        content={
                          <Space direction='vertical' size={4}>
                            {campaign.cooperation.specifiedMentionsValue.map(
                              (mention) => (
                                <Label key={mention}>{mention}</Label>
                              ),
                            )}
                          </Space>
                        }
                        title={
                          <Label>
                            {formatMessage({
                              id: 'campaign:cooperation_content_requirements_mentions',
                            })}
                          </Label>
                        }
                      />
                    )}
                    {!isEmpty(campaign.cooperation.specifiedLinkValue) && (
                      <CampaignDetailListItem
                        content={
                          <Space direction='vertical' size={4}>
                            {campaign.cooperation.specifiedLinkValue.map(
                              (link) => (
                                <TextLink href={link} key={link}>
                                  {link}
                                </TextLink>
                              ),
                            )}
                          </Space>
                        }
                        title={
                          <Label>
                            {formatMessage({
                              id: 'campaign:cooperation_content_requirements_links',
                            })}
                          </Label>
                        }
                      />
                    )}
                    {(campaign.qualifiedSamplePicture ??
                      campaign.unqualifiedSamplePicture) && (
                      <CampaignDetailListItem
                        content={
                          <Space size={10}>
                            {campaign.qualifiedSamplePicture && (
                              <Space direction='vertical' size={5}>
                                <SamplePhoto
                                  alt={formatMessage({
                                    id: 'campaign:cooperation_content_requirements_qualified_photo',
                                  })}
                                  src={
                                    campaign.qualifiedSamplePicture.signedUrl
                                  }
                                  onClick={(): void => {
                                    if (!campaign?.qualifiedSamplePicture) {
                                      return
                                    }

                                    setImageViewerProps({
                                      title: formatMessage({
                                        id: 'campaign:cooperation_content_requirements_qualified_photo',
                                      }),
                                      items: [
                                        {
                                          ...campaign.qualifiedSamplePicture,
                                          alt: formatMessage({
                                            id: 'campaign:cooperation_content_requirements_qualified_photo',
                                          }),
                                        },
                                      ],
                                    })
                                  }}
                                />
                                <SamplePhotoLabel>
                                  {formatMessage({
                                    id: 'campaign:cooperation_content_requirements_qualified_photo',
                                  })}
                                </SamplePhotoLabel>
                              </Space>
                            )}
                            {campaign.unqualifiedSamplePicture && (
                              <Space direction='vertical' size={5}>
                                <SamplePhoto
                                  alt={formatMessage({
                                    id: 'campaign:cooperation_content_requirements_unqualified_photo',
                                  })}
                                  src={
                                    campaign.unqualifiedSamplePicture.signedUrl
                                  }
                                  onClick={(): void => {
                                    if (!campaign?.unqualifiedSamplePicture) {
                                      return
                                    }

                                    setImageViewerProps({
                                      title: formatMessage({
                                        id: 'campaign:cooperation_content_requirements_unqualified_photo',
                                      }),
                                      items: [
                                        {
                                          ...campaign.unqualifiedSamplePicture,
                                          alt: formatMessage({
                                            id: 'campaign:cooperation_content_requirements_unqualified_photo',
                                          }),
                                        },
                                      ],
                                    })
                                  }}
                                />
                                <SamplePhotoLabel>
                                  {formatMessage({
                                    id: 'campaign:cooperation_content_requirements_unqualified_photo',
                                  })}
                                </SamplePhotoLabel>
                              </Space>
                            )}
                          </Space>
                        }
                        title={
                          <Label>
                            {formatMessage({
                              id: 'campaign:cooperation_content_requirements_sample_photos',
                            })}
                          </Label>
                        }
                      />
                    )}
                    <CampaignDetailListItem
                      content={
                        <Label
                          dangerouslySetInnerHTML={{
                            __html: campaign.description,
                          }}
                        />
                      }
                      title={
                        <Label>
                          {formatMessage({
                            id: 'campaign:additional_descriptions',
                          })}
                        </Label>
                      }
                    />
                  </List>
                </Tabs.TabPane>
              </StyledTabs>
              <ImageViewerModal
                items={imageViewerProps?.items ?? []}
                open={!!imageViewerProps}
                title={imageViewerProps?.title ?? ''}
                onClose={(): void => setImageViewerProps(undefined)}
              />
            </>
          )}
        </LayoutContent>
        {footer}
      </Wrapper>
    </Drawer>
  )
}

const Wrapper = styled(Space)`
  height: 100%;
  width: 100%;
`

const HStack = styled(Space)`
  background: ${({ theme }): string => theme.colors.background.white};
  border-bottom: 1px solid ${({ theme }): string => theme.colors.border.border};
  border-radius: 2px;
  padding: 11px 20px 11px 10px;
  width: 100%;

  > .anticon {
    font-size: 16px;
  }
`

const LayoutContent = styled.div`
  height: 100%;
  overflow: auto;
`

const TopCard = styled(Space)`
  width: 100%;

  @media (min-width: 768px) {
    padding: 24px 24px 0;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 220px;
  flex-shrink: 0;

  .ant-typography {
    color: ${({ theme }): string => theme.colors.text.white};
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    background: ${({ theme }): string => theme.colors.text.primary};
    border: 1px solid ${({ theme }): string => theme.colors.text.primary};
    padding: 3px 8px;
    border-radius: 2px 0;
    margin: 0;
  }

  @media (min-width: 768px) {
    height: 200px;
    width: 200px;
  }
`

const Image = styled(AsyncImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

const ContentWrapper = styled(Space)`
  width: 100%;
  padding: 20px;

  div.ant-typography {
    margin: 0;
  }

  ${CampaignName} {
    font-size: 16px;

    @media (min-width: 768px) {
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
    }
  }
`

const StyledTabs = styled(Tabs)<StyledProps<TabsProps>>`
  .ant-tabs-nav-wrap {
    padding: 0 20px;
  }

  .ant-tabs-tabpane {
    padding: 0 20px;
  }

  .ant-list-split .ant-list-item {
    border-color: ${({ theme }): string => theme.colors.border.divider};
  }

  .ant-list-item {
    padding: 15px 0;

    .ant-row {
      width: 100%;
    }
  }
`

const Label = styled.p`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
`

const TextLink = styled(SafeNewTabLink)`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  text-decoration: underline;
`

const SamplePhoto = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
`

const SamplePhotoLabel = styled(Label)`
  text-align: center;
`

export const WrapSpace = styled(Space)<StyledProps<SpaceProps>>`
  flex-wrap: wrap;
`

export default CampaignDetailDrawer

import { isUndefined } from 'lodash-es'
import { useFetchUserInfoQuery } from '@/api/user-api'
import { UserType } from '@/hooks/use-authorization/constants'
import { UserSchema } from '@/types/schema/user-schema'

interface UseAuth {
  (): {
    userInfo?: UserSchema
    isLogin: boolean
    isAd: boolean
    isKol: boolean
    isAgent: boolean
    editable: boolean
    hasType: boolean
    isVisitor: boolean
    unverified: boolean | null
    completeRegistration: boolean
    refetchUserInfo: VoidFunction
    isFetching: boolean
  }
}

export const useAuth: UseAuth = () => {
  const { data: userInfo, isFetching, refetch } = useFetchUserInfoQuery()
  const isLogin = !isUndefined(userInfo)
  const isAd = userInfo?.type === UserType.NormalAd
  const isKol = userInfo?.type === UserType.NormalKol
  const isAgent = userInfo?.type === UserType.Agency
  const editable = !userInfo?.agencyId
  const isVisitor = userInfo === null
  const unverified = userInfo ? !userInfo.verified : false
  const hasType = userInfo?.type !== UserType.Untyped
  const completeRegistration = !userInfo?.incompleteRegistration

  return {
    userInfo,
    isLogin,
    isAd,
    isKol,
    isAgent,
    editable,
    hasType,
    isVisitor,
    unverified,
    completeRegistration,
    refetchUserInfo: refetch,
    isFetching,
  }
}

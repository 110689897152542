import { Select } from 'antd'
import { values } from 'lodash-es'
import React, { CSSProperties, FunctionComponent } from 'react'
import styled from 'styled-components'
import { Icon } from '@/components/custom-icon'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { StyledAntdSelect } from '@/types/antd/styled-antd'

export enum Duration {
  FourteenDays = '14d',
  OneMonth = '1m',
  ThreeMonths = '3m',
  SixMonths = '6m',
}

export const MAP_DURATION_TO_I18NID: { [key in Duration]: I18nId } = {
  [Duration.FourteenDays]: 'general:period_option_near_fourteen_days',
  [Duration.OneMonth]: 'project:period_option_near_1mo',
  [Duration.ThreeMonths]: 'project:period_option_near_3mo',
  [Duration.SixMonths]: 'project:period_option_near_6mo',
} as const

interface DurationSelectProps {
  value?: Duration
  onChange?: (value: Duration) => void
  onSelectUnavailableDuration?: (value: Duration) => void

  /**
   * If this prop not provided, all duration options will be visible
   */
  visibleDurations?: Duration[]

  /**
   * If this prop not provided, all duration options will be available.
   * If duration is included in this prop, it will show crown icon beside the duration and gray out the option
   */
  unavailableDurations?: Duration[]
  isDemo?: boolean
  dropdownStyle?: CSSProperties
}

const DurationSelect: FunctionComponent<DurationSelectProps> = ({
  value,
  onChange,
  onSelectUnavailableDuration,
  visibleDurations = values(Duration),
  unavailableDurations = [],
  isDemo,
  dropdownStyle,
}) => {
  const { formatMessage } = useIntl()

  const handleChange = (duration: Duration): void => {
    if (unavailableDurations.includes(duration)) {
      onSelectUnavailableDuration?.(duration)
      return
    }

    onChange?.(duration)
  }

  return (
    <StyledSelect
      dropdownStyle={dropdownStyle}
      getPopupContainer={(triggerNode): HTMLElement =>
        triggerNode.parentElement || document.body
      }
      size='small'
      value={value}
      onChange={handleChange}
    >
      {visibleDurations.map((duration) => {
        const isAvailable = !unavailableDurations.includes(duration)

        return (
          <Select.Option disabled={isDemo} key={duration} value={duration}>
            <OptionText isAvailable={isAvailable}>
              {formatMessage({ id: MAP_DURATION_TO_I18NID[duration] })}
            </OptionText>
            {!isAvailable && <IconCrownOutline type='elIconCrownOutline' />}
          </Select.Option>
        )
      })}
    </StyledSelect>
  )
}

const OptionText = styled.span<{ isAvailable: boolean }>`
  color: ${({ isAvailable, theme }): string =>
    isAvailable ? theme.colors.text.secondary : theme.colors.base.grey.grey3};
`

const StyledSelect = styled(StyledAntdSelect)`
  width: 100%;

  &.ant-select {
    .ant-select-selector {
      border-radius: 4px;
    }
  }

  .ant-select-item-option-disabled {
    ${OptionText} {
      color: unset;
    }
  }
`

const IconCrownOutline = styled(Icon)`
  display: inline-block;
  margin: 5px 0 0 5px;
  font-size: 18px;

  path {
    fill: ${({ theme }): string => theme.colors.text.placeholder};
  }
`

export default DurationSelect

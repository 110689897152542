import { Button } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { BasicModal } from '@/components/common/antd/basic-modal'
import { StyleParagraph } from '@/components/common/typography'
import useLoginTimeChange from '@/components/multiple-login/use-login-time-change'
import useSingleLogin from '@/components/multiple-login/use-single-login'
import { useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { logout } from '@/store/me'
import { goToPage } from '@/utils/routes/go-to-page'

export const MultipleLoginModal: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { locale } = useI18n()
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)
  useSingleLogin(visible, setVisible)
  useLoginTimeChange()

  const handleClose: VoidFunction = () => {
    dispatch(logout())
    goToPage({
      pathname: '/login',
      query: { lang: locale },
    }).catch((e) => console.warn(e))
    setVisible(false)
  }

  return (
    <MultipleLoginModalWrapper
      footer={null}
      okButtonProps={{ style: { display: 'none' } }}
      open={visible}
      style={{ top: '15vh' }}
      title={formatMessage({ id: 'error:single_login_title' })}
      width={576}
      onCancel={handleClose}
      onOk={handleClose}
    >
      <StyleParagraph>
        {formatMessage({ id: 'error:single_login_content' })}
        <br />
        {formatMessage({ id: 'general:contact_us_if_any_question' })}
      </StyleParagraph>
      <ButtonWrapper>
        <Button type='primary' onClick={handleClose}>
          {formatMessage({ id: 'general:btn_text_i_see' })}
        </Button>
      </ButtonWrapper>
    </MultipleLoginModalWrapper>
  )
}

const MultipleLoginModalWrapper = styled(BasicModal)`
  text-align: center;
`

const ButtonWrapper = styled.div`
  margin: 30px 0;
`

import axios from 'axios'
import { first } from 'lodash-es'
import logger from '@/utils/logger'

const pipedriveAxios = axios.create({
  baseURL: 'https://api.pipedrive.com/v1',
})

// Custom field "新舊客戶" for pipedrive.
const newOrOldClientFieldKey = '87417bf9d7a8d7a591ae137fa08dcaff5da4a1d4'
const sourceFieldKey = '0ffbaaca9341a224b36ac258f93391404b09142e'
const JANICE_PIPEDRIVE_API_TOKEN = '94a9228f5554896493eb1c1b92f7e504fe622c6f'

const stageID = 14
const configForAuth = {
  params: {
    api_token: process.env.NEXT_PUBLIC_PIPEDRIVE_API_TOKEN,
  },
}
interface Organization {
  name?: string
}
interface PersonRequest {
  name?: string
  email?: string
  phone?: string
}

interface PersonResponse {
  id: string
}

interface Note {
  content?: string
}
type CustomFields = Record<string, string | undefined>

const findOrCreatePerson = async (
  person: PersonRequest,
  customFields?: CustomFields,
  org_id?,
): Promise<{
  isNew: boolean
  person: PersonResponse
}> => {
  const config = {
    params: {
      ...configForAuth.params,
      term: person.email,
      fields: 'email',
    },
  }
  const {
    data: { data: existsedPersons },
  } = await pipedriveAxios.get('/persons/search', config)

  const existsedPerson: PersonResponse | undefined = first(existsedPersons)
  const personWithCustomFields = customFields?.source
    ? {
        ...person,
        org_id,
        [sourceFieldKey]: customFields?.source,
      }
    : {
        ...person,
        org_id,
      }

  if (existsedPerson) {
    const {
      data: { data: updatedPerson },
    } = await pipedriveAxios.put(
      `/persons/${existsedPerson.id}`,
      personWithCustomFields,
      configForAuth,
    )

    return {
      isNew: false,
      person: updatedPerson,
    }
  }

  const {
    data: { data: createdPerson },
  } = await pipedriveAxios.post(
    '/persons',
    personWithCustomFields,
    configForAuth,
  )
  return {
    isNew: true,
    person: createdPerson,
  }
}

const createDeal = async (
  title: string,
  organization: Organization,
  person: PersonRequest,
  note: Note,
  customFields?: CustomFields,
): Promise<unknown> => {
  const {
    data: { data: pipedriveOrganization },
  } = await pipedriveAxios.post('/organizations', organization, configForAuth)
  const { isNew, person: pipedrivePerson } = await findOrCreatePerson(
    person,
    customFields,
    pipedriveOrganization.id,
  )

  const {
    data: { data: pipedriveDeal },
  } = await pipedriveAxios.post(
    '/deals',
    {
      title: `[${title}] ${organization.name}`,
      person_id: pipedrivePerson.id,
      org_id: pipedriveOrganization.id,
      stage_id: stageID,
      [newOrOldClientFieldKey]: isNew ? '新' : '舊',
    },
    {
      params: {
        api_token: JANICE_PIPEDRIVE_API_TOKEN,
      },
    },
  )

  if (note.content) {
    await pipedriveAxios.post(
      '/notes',
      {
        ...note,
        deal_id: pipedriveDeal.id,
      },
      configForAuth,
    )
  }

  return pipedriveDeal
}

export const pipeDriveRegisterEvent = async (
  organization: Organization,
  person: PersonRequest,
  customFields?: CustomFields,
): Promise<void> => {
  try {
    const {
      data: { data: pipedriveOrganization },
    } = await pipedriveAxios.post('/organizations', organization, configForAuth)
    await findOrCreatePerson(person, customFields, pipedriveOrganization.id)
  } catch (e) {
    logger.error(e)
  }
}

export const pipedriveContactEvent = (
  name: string,
  phone: string,
  email: string,
  companyName: string,
  jobTitle: string,
  content?: string,
): void => {
  createDeal(
    'Contact us',
    { name: companyName },
    { name, phone, email },
    { content },
    { jobTitle },
  )
}

import {
  FetchArgs,
  type BaseQueryFn,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import { isString } from 'lodash-es'
import { ExecutableRequest } from '@/types/api'

type ExecutableQuery = BaseQueryFn<
  string | (FetchArgs & ExecutableRequest),
  unknown,
  FetchBaseQueryError,
  unknown,
  FetchBaseQueryMeta
>

const lifecycle: (baseQuery: ExecutableQuery) => ExecutableQuery =
  (baseQuery: ExecutableQuery) => async (args, api, extraOptions) => {
    if (isString(args)) {
      return baseQuery(args, api, extraOptions)
    }

    const { beforeQueryStarts, onQueryFulfilled, ...restArgs } = args

    await beforeQueryStarts?.({ args: restArgs })
    const returnValue = await baseQuery(restArgs, api, extraOptions)
    await onQueryFulfilled?.({ args: restArgs, response: returnValue.data })

    return returnValue
  }

export default lifecycle

import { OurIconType } from '@/components/custom-icon'
import { I18nId } from '@/i18n/config'

interface ThumbnailBadgeInfo {
  hasBadge: boolean
  toolTipTitleKey?: I18nId
  badgeType?: OurIconType
  isInternalRadarStar: boolean
}
export const getThumbnailBadgeInfo = (
  isVerified: boolean,
  isRadarStar: boolean,
  isInternal: boolean,
  isKol: boolean,
): ThumbnailBadgeInfo => {
  const isInternalRadarStar = isInternal && isRadarStar
  const isKolRadarStar = isKol && isRadarStar
  const hasBadge = isVerified || isInternalRadarStar

  let badgeType: OurIconType | undefined
  let toolTipTitleKey: I18nId | undefined
  if (isVerified && (isInternalRadarStar || isKolRadarStar)) {
    badgeType = 'radarStarBadge'
    toolTipTitleKey = 'kol:verified_radar_star'
  } else if (isVerified && !isInternalRadarStar) {
    badgeType = 'elIconBadge'
    toolTipTitleKey = 'kol:verified_radar_kol'
  }

  return {
    hasBadge: hasBadge,
    toolTipTitleKey: toolTipTitleKey,
    badgeType: badgeType,
    isInternalRadarStar: isInternalRadarStar,
  }
}

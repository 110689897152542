import { useRouter } from 'next/router'
import { useState } from 'react'
import { useMount, useWindowSize } from 'react-use'
import { UAParser } from 'ua-parser-js'
import {
  PUBLIC_PAGES,
  Page,
  RWD_ENABLED_PAGE_LIST,
} from '@/hooks/use-authorization/constants'
import useIsClient from '@/hooks/use-is-client'
import useIsPublicDetailPage from '@/hooks/use-is-public-detail-page'

type WarningType = 'rwd' | 'browser'
type UseWarning = () => WarningType | null

const rwdWarningWhiteList: Page[] = [
  Page.ResetPassword,
  Page.VerifyAccount,
  Page.Default,
  ...RWD_ENABLED_PAGE_LIST,
  ...PUBLIC_PAGES,
]

const useSiteWarning: UseWarning = () => {
  const { width: innerWidth } = useWindowSize()
  const { pathname } = useRouter()
  const [isInApp, setIsInApp] = useState(false)
  const isClient = useIsClient()

  useMount(() => {
    const uap = new UAParser()
    const myBrowser = uap.getBrowser()
    if (!myBrowser.name) {
      return
    }

    // Browser 列表可以看 https://github.com/faisalman/ua-parser-js/blob/3622b614a71749e6d96f241d6810d6086075e2a4/src/enums/ua-parser-enums.js
    const inAppBrowserList = [
      'Chrome WebView',
      'Facebook',
      'Instagram',
      'Line',
      'LinkedIn',
      'Weibo',
      'WeChat',
    ]
    setIsInApp(inAppBrowserList.includes(myBrowser.name))
  })

  // 公開 KD 頁會被當作 landing 站台，不需要顯示 warning
  const isPublicDetailPage = useIsPublicDetailPage()
  if (isPublicDetailPage) {
    return null
  }

  if (!isClient) {
    return null
  }

  const outerWidth = window.outerWidth || 99999 // HACK: 在 prod mode（npm run start） 開新分頁的時候，這個會是 0，所以無視它
  const width = innerWidth < outerWidth ? innerWidth : outerWidth // 用比較小的那個值，避免 disabled RWD 時，innerWidth 比 outerWidth 大的情況

  if (isInApp) {
    return 'browser'
  } else if (width < 480 && !rwdWarningWhiteList.includes(pathname as Page)) {
    return 'rwd'
  }

  return null
}

export type { WarningType }
export default useSiteWarning

import router, { useRouter } from 'next/router'
import querystring from 'query-string'
import { z } from 'zod'
import { SafeParseReturnType } from 'zod/lib/types'
import { Page } from '@/hooks/use-authorization/constants'
import { Route } from '@/types/route'

export const emptySchema: z.ZodSchema<Record<string, never>> = z.object({})

function makeRoute<
  PageType extends Page,
  ParamsSchema extends z.ZodSchema = z.ZodSchema<Record<string, never>>,
  QuerySchema extends z.ZodSchema = z.ZodSchema<Record<string, never>>,
  FragmentIdentifierSchema extends z.ZodSchema = z.ZodSchema<
    Record<string, never>
  >,
>(
  page: PageType,
  paramsSchema: ParamsSchema = emptySchema as ParamsSchema,
  querySchema: QuerySchema = emptySchema as QuerySchema,
  fragmentIdentifierSchema: FragmentIdentifierSchema = emptySchema as FragmentIdentifierSchema,
): Route<PageType, ParamsSchema, QuerySchema, FragmentIdentifierSchema> {
  const safeParseParams = (
    input: unknown,
  ): SafeParseReturnType<z.input<ParamsSchema>, z.output<ParamsSchema>> =>
    paramsSchema.safeParse(input)
  const safeParseSearchParams = (
    input: unknown,
  ): SafeParseReturnType<z.input<QuerySchema>, z.output<QuerySchema>> =>
    querySchema.safeParse(input)
  const stringifyUrl = (
    params: z.input<ParamsSchema> = {},
    query?: z.input<QuerySchema>,
    fragmentIdentifier?: z.input<FragmentIdentifierSchema>,
  ): string => {
    // input {a:1,b:2}
    // output {[a]:1,[b]:2}
    const paramsWithBrackets = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [`[${key}]`, value]),
    )

    return querystring.stringifyUrl(
      {
        url: page
          .split('/')
          .map((path) => paramsWithBrackets[path] ?? path)
          .join('/'),
        query,
        fragmentIdentifier,
      },
      { arrayFormat: 'bracket' },
    )
  }

  return {
    page,
    safeParseParams,
    safeParseSearchParams,
    stringifyUrl,
    goto: (
      params = {},
      query,
      fragmentIdentifier,
      options,
    ): Promise<boolean> => {
      const url = stringifyUrl(params, query, fragmentIdentifier)
      const { replace, as, ...otherOptions } = options ?? {}

      if (replace) {
        return router.replace(url, as, otherOptions)
      } else {
        return router.push(url, as, otherOptions)
      }
    },
    searchParamsInputType: undefined,
    useParams: (): ReturnType<
      Route<
        PageType,
        ParamsSchema,
        QuerySchema,
        FragmentIdentifierSchema
      >['useParams']
    > => {
      const { query } = useRouter()
      return safeParseParams(query)
    },
    useSearchParams: (): ReturnType<
      Route<
        PageType,
        ParamsSchema,
        QuerySchema,
        FragmentIdentifierSchema
      >['useSearchParams']
    > => {
      const { asPath } = useRouter()
      const { query } = querystring.parseUrl(asPath, {
        parseBooleans: true,
        arrayFormat: 'bracket',
      })

      return safeParseSearchParams(query)
    },
    useFragmentIdentifier: (): ReturnType<
      Route<
        PageType,
        ParamsSchema,
        QuerySchema,
        FragmentIdentifierSchema
      >['useFragmentIdentifier']
    > => {
      const { asPath } = useRouter()
      const { fragmentIdentifier } = querystring.parseUrl(asPath, {
        parseFragmentIdentifier: true,
      })
      return fragmentIdentifierSchema.safeParse(fragmentIdentifier)
    },
    useSetSearchParams: (): ReturnType<
      Route<
        PageType,
        ParamsSchema,
        QuerySchema,
        FragmentIdentifierSchema
      >['useSetSearchParams']
    > => {
      const router = useRouter()

      return async (searchParams, fragmentIdentifier, options) => {
        const { replace, ...restOptions } = { shallow: true, ...options }
        const params = safeParseParams(router.query)

        if (!params.success) {
          return false
        }

        const url = stringifyUrl(params.data, searchParams, fragmentIdentifier)

        return replace
          ? router.replace(url, undefined, restOptions)
          : router.push(url, undefined, restOptions)
      }
    },
  }
}

export default makeRoute

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Modal, notification } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  KolReplyAction,
  useUpdateCampaignInvitationMutation,
} from '@/api/campaigns-api'
import {
  ActionButton,
  Subject,
  Wrapper,
} from '@/components/campaign/chatroom/campaign-ad-accept-status'
import { Icon } from '@/components/custom-icon'
import useCheckAccountInfo from '@/hooks/use-check-account-info'
import { useIntl } from '@/i18n/hooks/use-intl'
import { CampaignCase } from '@/types/campaign-case'
import { ApproverType, CampaignKolStatus } from '@/types/mock-api-types'

interface CampaignAcceptStatusProps {
  campaignKolId: number
  acceptedTime?: string
  status: CampaignKolStatus
  approverType: ApproverType
  appliedTime: CampaignCase['appliedTime']
}

const CampaignKolAcceptStatus: FunctionComponent<CampaignAcceptStatusProps> = ({
  acceptedTime,
  status,
  approverType,
  appliedTime,
  campaignKolId,
}) => {
  const { check: checkAccountInfo } = useCheckAccountInfo()
  const { formatDate, formatMessage } = useIntl()

  const subject = approverType === ApproverType.Ad ? Subject.Brand : Subject.You
  const subjectText = formatMessage({ id: `general:subject_${subject}` })

  const [updateCampaign, { isLoading: updatingCampaign }] =
    useUpdateCampaignInvitationMutation()

  const onSubmit = (accept: boolean): void => {
    if (accept && !checkAccountInfo()) {
      return
    }

    const action = accept ? KolReplyAction.Accept : KolReplyAction.Reject
    const statusKey = accept ? 'accepted' : 'declined'

    Modal.confirm({
      title: formatMessage({
        id: `kol:accept_status_${statusKey}`,
      }),
      content: formatMessage({
        id: `kol:confirm_${statusKey}_inviting`,
      }),
      onOk: async () => {
        try {
          await updateCampaign({
            campaignKolIds: [campaignKolId],
            action: action,
          }).unwrap()

          notification.success({
            message: formatMessage({ id: 'kol:campaign_reply_success' }),
            placement: 'bottomRight',
          })
        } catch (error) {
          console.error(error)
        }
      },
    })
  }

  const getText = (): string => {
    switch (status) {
      case CampaignKolStatus.Running:
        return formatMessage(
          {
            id: acceptedTime
              ? 'campaign:accept_cooperate'
              : 'campaign:ad_accept_status_force_running',
          },
          {
            subject: subjectText,
            date: formatDate(acceptedTime, 'dateTimeShort'),
          },
        )
      case CampaignKolStatus.Rejected:
        return approverType === ApproverType.System
          ? formatMessage({ id: 'campaign:system_auto_cancel' })
          : formatMessage(
              { id: 'campaign:reject_cooperate' },
              {
                subject: subjectText,
                date: formatDate(acceptedTime, 'dateTimeShort'),
              },
            )

      case CampaignKolStatus.Inviting:
        return formatMessage({ id: 'campaign:inviting_kol_text' })

      case CampaignKolStatus.Applied:
        return formatMessage(
          { id: 'campaign:waiting_brand_response' },
          { date: formatDate(appliedTime ?? '', 'dateTimeShort') },
        )

      default:
        return ''
    }
  }

  const text = getText()

  return (
    <StyledWrapper status={status}>
      {text && (
        <>
          <Icon type='bullhorn' />
          <p>{text}</p>
          {status === CampaignKolStatus.Inviting && (
            <>
              <ActionButton
                isAccept
                icon={<CheckCircleOutlined />}
                loading={updatingCampaign}
                onClick={(): void => onSubmit(true)}
              >
                {formatMessage({ id: 'campaign:accept_status_accepted' })}
              </ActionButton>
              <ActionButton
                icon={<CloseCircleOutlined />}
                loading={updatingCampaign}
                onClick={(): void => onSubmit(false)}
              >
                {formatMessage({ id: 'campaign:accept_status_declined' })}
              </ActionButton>
            </>
          )}
        </>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Wrapper)`
  background: ${({ status, theme }): string | undefined => {
    switch (status) {
      case CampaignKolStatus.Running:
        return '#F6FFED'
      case CampaignKolStatus.Rejected:
        return theme.colors.border.divider
      case CampaignKolStatus.Applied:
        return '#FFFBE6'
    }
  }};
`

export default CampaignKolAcceptStatus

import { theme } from '@buggy/shared'

export function clickLabelStatus(
  checked?: boolean,
  disabled?: boolean,
): string {
  if (!!disabled) {
    return theme.colors.text.placeholder
  }

  return checked ? theme.colors.brand.secondary : theme.colors.text.secondary
}

import router from 'next/router'
import { UrlObject } from 'url'

interface GoToPageOptions {
  replace?: boolean
  as?: string
}

const defaultGoToPageOptions: GoToPageOptions = {
  replace: false,
}

export function goToPage(
  url: string | UrlObject,
  opt?: GoToPageOptions,
): Promise<boolean> {
  const options = Object.assign({}, defaultGoToPageOptions, opt)
  if (typeof url === 'string' && url.includes('http')) {
    window.location.assign(url)
    return Promise.reject(false)
  }
  if (options.replace) {
    return router.replace(url, options.as ?? url)
  } else {
    return router.push(url, options.as ?? url)
  }
}

import { Word } from '@buggy/shared'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, clone, isEmpty, noop } from 'lodash-es'
import {
  failureAPIStatus,
  initialAPIStatus,
  requestingAPIStatus,
  successAPIStatus,
} from '@/constants/api-status'
import { APIStatus } from '@/types/api-status'
import {
  CampaignContentRequest,
  CampaignContentResponse,
} from '@/types/campaign-content'
import {
  CampaignChartRowData,
  CampaignCrawlerJob,
  CampaignReport,
} from '@/types/campaign-crawler'
import {
  CommentRawData,
  Statistics,
  Term,
} from '@/types/schema/chart-data-schema'
import { ReportContent } from '@/types/schema/report-schema'
import { getGuestVisibility } from '@/utils/deep-report/get-guest-visibility'
import { getPlatformShortName } from '@/utils/get-platform-name'

interface StateReport {
  needRefresh: boolean
  total: number
  currentTotal: number
  report?: CampaignReport
  selectedKOLID?: number
  crawling: boolean
  chartData: {
    [section: string]: CampaignChartRowData[]
  }
  allChartData: {
    [section: string]: CampaignChartRowData[]
  }
  commentData?: CommentRawData[]
  terms: Term[]
  frequency: Word[]
  ownReport?: boolean
  crawlerJobs: any[]
}

interface SectionIsLoading {
  section: string
  isLoading: boolean
}

type SummaryFilterSection = 'score' | 'comment' | 'kol' | 'post' | 'platform'

interface SummaryFilter {
  date: any[]
  kol: string[]
  platform: string[]
}

interface SummaryFilterSettings {
  [section: string]: SummaryFilter
}

interface CampaignReportState {
  apiStatus: {
    getReport: APIStatus
    triggerCrawler: APIStatus
    fetchCrawlerStatus: APIStatus
    fetchContent: APIStatus
    fetchReportCrawlingStatus: APIStatus
  }
  content?: CampaignContentResponse
  crawlerJobs: CampaignCrawlerJob[]
  data: StateReport
  editGuestVisibility: {
    [key: string]: boolean
  }
  error: Error | null
  latestCrawlerJob?: CampaignCrawlerJob
  loading: boolean
  isCommentsLoading: boolean
  isFrequencyLoading: boolean
  summaryFilter: SummaryFilterSettings
  editingReportContent?: ReportContent
  statistics: Statistics
  mode: string
}

const InitialReportTotal = -1

const initialState: CampaignReportState = {
  apiStatus: {
    fetchContent: initialAPIStatus(),
    fetchCrawlerStatus: initialAPIStatus(),
    getReport: initialAPIStatus(),
    triggerCrawler: initialAPIStatus(),
    fetchReportCrawlingStatus: initialAPIStatus(),
  },
  content: undefined,
  crawlerJobs: [],
  data: {
    allChartData: {
      kol: [],
      platform: [],
      post: [],
      score: [],
    },
    chartData: {
      kol: [],
      platform: [],
      post: [],
      score: [],
    },
    crawlerJobs: [],
    crawling: false,
    currentTotal: 0,
    frequency: [],
    needRefresh: false,
    ownReport: true,
    terms: [],
    total: InitialReportTotal,
  },
  editGuestVisibility: {},
  error: null,
  isCommentsLoading: false,
  isFrequencyLoading: false,
  latestCrawlerJob: undefined,
  loading: false,
  mode: '',
  statistics: {
    statisticsAll: {
      engagementRate: 0,
      type: '',
      viewRate: 0,
    },
    statisticsCustom: {
      engagementRate: 0,
      type: '',
      viewRate: 0,
    },
  },
  summaryFilter: {
    comment: {
      date: ['', ''],
      kol: [],
      platform: [],
    },
    kol: {
      date: ['', ''],
      kol: [],
      platform: [],
    },
    platform: {
      date: ['', ''],
      kol: [],
      platform: [],
    },
    post: {
      date: ['', ''],
      kol: [],
      platform: [],
    },
    score: {
      date: ['', ''],
      kol: [],
      platform: [],
    },
  },
}

const campaignReportSlice = createSlice({
  name: 'campaignReport',
  initialState,
  reducers: {
    fetchCampaignCrawlerStatus: (state, action: PayloadAction<number>) => {
      state.apiStatus.fetchCrawlerStatus = requestingAPIStatus()
      noop(action)
    },
    fetchCampaignCrawlerStatusFailure: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.apiStatus.fetchCrawlerStatus = failureAPIStatus()
      noop(action)
    },
    fetchCampaignCrawlerStatusSuccess: (
      state,
      action: PayloadAction<CampaignCrawlerJob[]>,
    ) => {
      state.crawlerJobs = action.payload
      state.latestCrawlerJob = action.payload[0]
      state.apiStatus.fetchCrawlerStatus = successAPIStatus()
    },
    fetchReportCrawlingStatus: (state, action: PayloadAction<number>) => {
      state.apiStatus.fetchReportCrawlingStatus = requestingAPIStatus()
      noop(action)
    },
    fetchReportCrawlingStatusFailure: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.apiStatus.fetchReportCrawlingStatus = failureAPIStatus()
      noop(action)
    },
    fetchReportCrawlingStatusSuccess: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.data.crawling = action.payload
      state.apiStatus.fetchReportCrawlingStatus = successAPIStatus()
    },
    fetchContent: (
      state,
      action: PayloadAction<Omit<CampaignContentRequest, 'workspaceID'>>,
    ) => {
      state.apiStatus.fetchContent = requestingAPIStatus()
      noop(action)
    },
    fetchContentFailure: (state, action: PayloadAction<Error>) => {
      state.apiStatus.fetchContent = failureAPIStatus()
      noop(action)
    },
    fetchContentSuccess: (
      state,
      action: PayloadAction<CampaignContentResponse>,
    ) => {
      state.content = action.payload
      state.apiStatus.fetchContent = successAPIStatus()
    },
    setAllChartData: (
      state,
      action: PayloadAction<{ data: CampaignChartRowData[] }>,
    ) => {
      const sections = ['score', 'kol', 'post', 'platform', 'comment']
      const { data } = action.payload
      const visibleData = data.filter((d) => !d.isHidden)
      sections.forEach(
        (section: 'score' | 'kol' | 'post' | 'platform' | 'comment') => {
          state.data.allChartData[section] = visibleData
          state.data.chartData[section] = visibleData
        },
      )
    },
    setChartData: (
      state,
      action: PayloadAction<{
        section: SummaryFilterSection
        data: CampaignChartRowData[]
      }>,
    ) => {
      const { section, data } = action.payload
      state.data.chartData[section] = data
    },
    setReport: (state, action: PayloadAction<CampaignReport | undefined>) => {
      state.apiStatus.getReport = successAPIStatus()
      state.data.report = action.payload

      if (state.data.report && action.payload) {
        state.data.report.platform = (state.data.report.platforms ?? []).map(
          (type) => {
            return {
              id: type.toString(),
              name: getPlatformShortName(type),
            }
          },
        )
      }
    },
    setSummaryFilter: (
      state,
      action: PayloadAction<{
        section: SummaryFilterSection
        data: SummaryFilter
      }>,
    ) => {
      const { section, data } = action.payload
      state.summaryFilter[section] = data
    },
    cleanReport: (state) => {
      state.apiStatus.getReport = initialAPIStatus()
      state.data.report = undefined
      state.data.chartData = {
        kol: [],
        platform: [],
        post: [],
        score: [],
      }
    },
    setIsLoading: (state, action: PayloadAction<SectionIsLoading>) => {
      const { section, isLoading } = action.payload
      state[section] = isLoading
    },
    getReport: (
      state,
      action: PayloadAction<{
        campaignID: number
        workspaceId?: number
      }>,
    ) => {
      state.apiStatus.getReport = requestingAPIStatus()
      noop(action)
    },
    setGuestVisibility: (
      state,
      action: PayloadAction<{ [key: string]: boolean }>,
    ) => {
      if (isEmpty(action.payload)) {
        state.editGuestVisibility = getGuestVisibility()
      } else {
        const cloneVisibility = clone(state.editGuestVisibility)
        assign(cloneVisibility, action.payload)
        state.editGuestVisibility = cloneVisibility
      }
    },
    triggerCrawler: (state, action: PayloadAction<{ campaignID: number }>) => {
      state.apiStatus.triggerCrawler = requestingAPIStatus()
      noop(action)
    },
    triggerCrawlerFailure: (state) => {
      state.apiStatus.triggerCrawler = failureAPIStatus()
    },
    triggerCrawlerSuccess: (
      state,
      action: PayloadAction<{ campaignID: number }>,
    ) => {
      state.apiStatus.triggerCrawler = successAPIStatus()
      noop(action)
    },
    setReportCrawlerJobs: (state, action: PayloadAction<any[]>) => {
      state.data.crawlerJobs = action.payload
    },
  },
})

export const {
  cleanReport,
  fetchCampaignCrawlerStatus,
  fetchCampaignCrawlerStatusFailure,
  fetchCampaignCrawlerStatusSuccess,
  fetchReportCrawlingStatus,
  fetchReportCrawlingStatusFailure,
  fetchReportCrawlingStatusSuccess,
  fetchContent,
  fetchContentFailure,
  fetchContentSuccess,
  getReport,
  setAllChartData,
  setChartData,
  setGuestVisibility,
  setReport,
  setReportCrawlerJobs,
  setSummaryFilter,
  triggerCrawler,
  triggerCrawlerFailure,
  triggerCrawlerSuccess,
} = campaignReportSlice.actions

export default campaignReportSlice.reducer

import { useMemo } from 'react'
import {
  RateSelectorOption,
  RateSelectorProps,
} from '@/components/search/filters/rate-selector'
import useSearch from '@/hooks/use-search'
import { useIntl } from '@/i18n/hooks/use-intl'

enum RateSelectorSection {
  ViewRate = 'viewRate',
  EngagementRate = 'engagementRate',
  GrowthRate = 'growthRate',
}

const useRangeSelector = (): Record<RateSelectorSection, RateSelectorProps> => {
  const { formatMessage } = useIntl()
  const { kolQueryParams, handleSearch } = useSearch()

  const options = useMemo<
    Record<RateSelectorSection, RateSelectorOption[]>
  >(() => {
    return {
      [RateSelectorSection.ViewRate]: [
        {
          label: formatMessage({ id: 'search:filter_option_percent_any' }),
        },
        {
          max: 10,
          label: formatMessage(
            { id: 'search:filter_option_percent_down' },
            { n: 10 },
          ),
        },
        {
          min: 10,
          max: 50,
          label: '10%~50%',
        },
        {
          min: 50,
          max: 200,
          label: '50%~200%',
        },
        {
          min: 200,
          label: formatMessage(
            { id: 'search:filter_option_percent_up' },
            { n: 200 },
          ),
        },
      ],
      [RateSelectorSection.EngagementRate]: [
        {
          label: formatMessage({ id: 'search:filter_option_percent_any' }),
        },
        {
          max: 5,
          label: formatMessage(
            { id: 'search:filter_option_percent_down' },
            { n: 5 },
          ),
        },
        {
          min: 5,
          max: 20,
          label: '5%~20%',
        },
        {
          min: 20,
          label: formatMessage(
            { id: 'search:filter_option_percent_up' },
            { n: 20 },
          ),
        },
      ],
      [RateSelectorSection.GrowthRate]: [
        {
          label: formatMessage({ id: 'search:filter_option_percent_any' }),
        },
        {
          max: 10,
          label: formatMessage(
            { id: 'search:filter_option_percent_down' },
            { n: 10 },
          ),
        },
        {
          min: 10,
          max: 50,
          label: '10%~50%',
        },
        {
          min: 50,
          max: 200,
          label: '50%~200%',
        },
        {
          min: 200,
          label: formatMessage(
            { id: 'search:filter_option_percent_up' },
            { n: 200 },
          ),
        },
      ],
    }
  }, [formatMessage])

  return {
    [RateSelectorSection.ViewRate]: {
      buttonLabel: formatMessage({ id: 'search:filter_view_rate_label' }),
      fromValue: kolQueryParams.avgPvRateBegin,
      endValue: kolQueryParams.avgPvRateEnd,
      options: options[RateSelectorSection.ViewRate],
      onConfirmSelect: (fromValue, endValue): void => {
        handleSearch({
          avgPvRateBegin: fromValue,
          avgPvRateEnd: endValue,
        })
      },
    },
    [RateSelectorSection.EngagementRate]: {
      buttonLabel: formatMessage({ id: 'search:filter_engagment_rate_label' }),
      fromValue: kolQueryParams.interactiveRateBegin,
      endValue: kolQueryParams.interactiveRateEnd,
      options: options[RateSelectorSection.EngagementRate],
      onConfirmSelect: (fromValue, endValue): void => {
        handleSearch({
          interactiveRateBegin: fromValue,
          interactiveRateEnd: endValue,
        })
      },
    },
    [RateSelectorSection.GrowthRate]: {
      buttonLabel: formatMessage({
        id: 'search:filter_fans_growth_rate_label',
      }),
      fromValue: kolQueryParams.fansUpRateBegin,
      endValue: kolQueryParams.fansUpRateEnd,
      options: options[RateSelectorSection.GrowthRate],
      onConfirmSelect: (fromValue, endValue): void => {
        handleSearch({
          fansUpRateBegin: fromValue,
          fansUpRateEnd: endValue,
        })
      },
    },
  }
}

export default useRangeSelector

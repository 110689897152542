import { stringifyUrl } from 'query-string'
import { Page } from '@/hooks/use-authorization/constants'

interface GetRedirectUrl {
  (url: string): string
}

const getRedirectUrl: GetRedirectUrl = (url) => {
  return stringifyUrl({
    url: Page.Login,
    query: {
      redirect: url,
    },
  })
}

export default getRedirectUrl

import { CurrencyCode } from '@buggy/shared'
import { useEffect } from 'react'
import { useFetchUserStatusQuery } from '@/api/user-api'
import { CountryCode } from '@/constants/country-currency'
import { useAuth } from '@/hooks/use-auth'
import { UserType } from '@/hooks/use-authorization/constants'
import { Nullable } from '@/types/nullable'

type UseSyncRadarUserProps = (props: {
  enabled: boolean
  syncFn: (userProps: {
    userId: string
    email: string
    userType: UserType
    company?: string
    nationality: string
    lang: string
    industry?: string
    isTest: Nullable<boolean>
    workspaceName?: string
    workspaceId?: number
    workspacePlan?: string
    workspaceCurrency?: CurrencyCode
    workspaceNational?: CountryCode
  }) => void
}) => void

const useSyncRadarUserProps: UseSyncRadarUserProps = ({ enabled, syncFn }) => {
  const { userInfo, isAd } = useAuth()
  const { data: userStatus } = useFetchUserStatusQuery()

  useEffect(() => {
    if (!enabled || !userStatus || !userInfo) {
      return
    }
    const workspacePlan =
      userStatus.currentWorkspace.isTrial &&
      userStatus.currentWorkspace.workspaceType === 'vip'
        ? 'premiumTrial'
        : (userStatus.currentWorkspace.workspaceType ?? undefined)

    const userProps = {
      userId: userInfo.id,
      email: userInfo.email,
      userType: userInfo.type,
      company: userInfo.company,
      nationality: userInfo.country,
      lang: userInfo.language,
      industry: userInfo.industry,
      isTest: isAd ? userStatus.currentWorkspace.isTest : null,
      workspaceName: isAd ? userStatus.currentWorkspace.name : undefined,
      workspaceId: isAd ? userStatus.currentWorkspace.id : undefined,
      workspacePlan: isAd ? workspacePlan : undefined,
      workspaceCurrency: isAd
        ? (userStatus.currentWorkspace.currencyCode ?? undefined)
        : undefined,
      workspaceNational: isAd
        ? (userStatus.currentWorkspace.countryCode ?? undefined)
        : undefined,
    }

    syncFn(userProps)
  }, [enabled, isAd, syncFn, userInfo, userStatus])
}

export default useSyncRadarUserProps

interface PubNubError extends Error {
  name: 'Error'
  status: {
    statusCode: number
  }
}

export const isPubNubError = (error: Error): error is PubNubError => {
  return error?.name === 'Error'
}

import { CurrencyCode } from '@buggy/shared'
import { z } from 'zod'
import { Gender } from '@/constants/gender'
import { PostType } from '@/constants/post-type'
import { linkSchema } from '@/types/schema/kol/link-schema'
import { PlatformType } from '@/types/schema/report-schema'
import { PlatformShortcode } from '@/utils/convert-platform'

export const behaviorRecommendationTextListSchema = z.object({
  seed_behavior_recommendation: z.string().nullable(),
  candidate_behavior_recommendation: z.string().array(),
})

export const postSchema = z.object({
  publish_time: z.string().datetime(),
  title_content: z.string(),
  post_id: z.string(),
  post_type: z.nativeEnum(PostType),
  platform_type: z.nativeEnum(PlatformType),
  thumbnail: z.string().url(),
  crawled_id: z.string(),
  link: z.string().url(),
  ig_post_id: z.string(),
})

export type Post = z.infer<typeof postSchema>

const relatedKolSchema = z.object({
  hash_kol_datahub_identity_id: z.string(),
  kol_uuid: z.string(),
  name: z.string(),
  kol_type: z.string(),
  rank: z.number(),
})

const kpiSchema = z.object({
  platform: z.nativeEnum(PlatformShortcode),
  follow_count: z.number().nullable(),
  engagement_rate_3m: z.number().nullable(),
  follow_count_growth_rate_3m: z.number().nullable(),
  view_rate_3m: z.number().nullable(),
})

const quotationSchema = z.object({
  min_price: z.number().nullable(),
  max_price: z.number().nullable(),
  currency: z.nativeEnum(CurrencyCode),
})

const behaviorRecommendationKolSchema = z.object({
  kol_uuid: z.string(),
  hash_kol_datahub_identity_id: z.string(),
  names: z.string().array(),
  links: linkSchema
    .pick({ platform_type: true, type: true, url: true })
    .array(),
  total_follow_count: z.number().nullable(),
  max_view_rate_3m: z.number().nullable(),
  max_engagement_rate_3m: z.number().nullable(),
  max_follow_count_growth_rate_3m: z.number().nullable(),
  kpi: kpiSchema.array(),
  related_kols: relatedKolSchema.array(),
  sponsored_rate: z.number().nullable(),
  audience_gender: z.nativeEnum(Gender).nullable(),
  quotations: z.object({
    post: quotationSchema,
    video: quotationSchema,
  }),
  posts: postSchema.array(),
  recommendation_summary: z.string().nullable(),
})

export type BehaviorRecommendationKol = z.infer<
  typeof behaviorRecommendationKolSchema
>

export const behaviorRecommendationSchema = z.object({
  kols: behaviorRecommendationKolSchema.array(),
  recommendation_type: z
    .enum([
      'potential',
      'engagement_rate',
      'word_of_mouth_volume',
      'text_action',
    ])
    .nullable(),
})

export type BehaviorRecommendation = z.infer<
  typeof behaviorRecommendationSchema
>

const keywordRecommendationKolSchema = z.object({
  kol_uuid: z.string(),
  hash_kol_datahub_identity_id: z.string(),
  names: z.string().array(),
  customized_tags_names: z.array(z.string()),
  links: linkSchema
    .pick({ platform_type: true, type: true, url: true })
    .array(),
  total_follow_count: z.number().nullable(),
  max_view_rate_3m: z.number().nullable(),
  max_engagement_rate_3m: z.number().nullable(),
  max_follow_count_growth_rate_3m: z.number().nullable(),
  kpi: kpiSchema.array(),
  matched_posts: postSchema.array(),
  matched_post_count: z.number(),
})

export type KeywordRecommendationKol = z.infer<
  typeof keywordRecommendationKolSchema
>

export const keywordRecommendationSchema = z.object({
  kols: keywordRecommendationKolSchema.array(),
  keywords: z.string().array(),
})

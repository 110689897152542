import { onValue, ref } from 'firebase/database'
import { isEmpty } from 'lodash-es'
import React, { useEffect } from 'react'
import { firebaseApi } from '@/api/firebase/firebase-api'
import { useAuth } from '@/hooks/use-auth'
import { useSelector } from '@/hooks/use-selector'
import { ampli } from '@/utils/ampli'
import { isClient } from '@/utils/is-client'

type UseSingleLogin = (
  triggerState: boolean,
  trigger: React.Dispatch<React.SetStateAction<boolean>>,
) => void

/**
 * @description: single login detector
 */
const useSingleLogin: UseSingleLogin = (triggerState, trigger) => {
  const { isAd, userInfo } = useAuth()
  const loginTime = useSelector((state) => state.me.loginTime)

  useEffect(() => {
    if (!isClient()) {
      return
    }

    // first time if no login user login will not have loginTime.
    if (!userInfo?.id || !isAd || !loginTime) {
      return
    }

    const path = `/login/${userInfo.id}`
    if (isAd) {
      const unsubscribe = onValue(ref(firebaseApi.database(), path), (e) => {
        const newLoginTime = e.val()?.toString()

        if (isEmpty(newLoginTime)) {
          return
        }

        if (`${loginTime}` !== `${newLoginTime}`) {
          ampli.singleLoginAlert({
            lastLoginTime: loginTime,
            newestLoginTime: newLoginTime,
            firebaseRef: path,
          })
          trigger(true)
        } else {
          if (triggerState) {
            trigger(false)
          }
        }
      })

      return unsubscribe
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAd, userInfo?.id, loginTime])
}

export default useSingleLogin

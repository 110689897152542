import { useRouter } from 'next/router'
import { useMemo } from 'react'
import styled from 'styled-components'
import IconPremiumSVG from '@/components/icon/own-svg/el-icon-premium2.svg'
import MenuItem from '@/components/public-page/header/components/mega-menu/item/type'
import useUrlControl from '@/components/public-page/hook/use-url-control'
import { CHROME_EXTENSION_STORE_URL } from '@/constants/chrome-extension'
import { CountryCode } from '@/constants/country-currency'
import { getLocaleRegion, useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli } from '@/utils/ampli'

const useKolRadarJpMenuItems = (): MenuItem[] => {
  const { formatMessage } = useIntl()
  const { locale } = useI18n()
  const country = getLocaleRegion(locale) || CountryCode.TW
  const router = useRouter()
  const { getRootUrl } = useUrlControl()

  return useMemo(
    () => [
      {
        type: 'dropdown',
        title: formatMessage({ id: 'general:navbar_item_product' }),
        columns: [
          [
            {
              title: formatMessage({
                id: 'general:navbar_item_product_feature',
              }),
              type: 'primary',
              linkItems: [
                {
                  title: formatMessage({
                    id: 'general:navbar_item_influencer_discover',
                  }),
                  url: getRootUrl('influencer-discover'),
                },
                {
                  title: formatMessage({
                    id: 'general:navbar_item_influencer_analysis',
                  }),
                  url: getRootUrl('influencer-analysis'),
                },
                {
                  title: formatMessage({
                    id: 'general:navbar_item_influencer_collection',
                  }),
                  url: getRootUrl('influencer-collection'),
                },
                {
                  title: formatMessage({
                    id: 'general:navbar_item_deep_report',
                  }),
                  url: getRootUrl('deep-report'),
                },
                {
                  title: formatMessage({ id: 'general:navbar_item_irm' }),
                  url: getRootUrl('irm'),
                },
              ],
            },
          ],
          [
            {
              title: formatMessage({ id: 'general:navbar_item_extension' }),
              type: 'primary',
              linkItems: [
                {
                  title: formatMessage({
                    id: 'general:navbar_item_install_browser_extension',
                  }),
                  isBlank: true,
                  url: CHROME_EXTENSION_STORE_URL,
                  onClick: (): void => {
                    ampli.visitChromeExtensionWebstore({
                      path: router.asPath,
                      from: 'header',
                    })
                  },
                },
              ],
            },
          ],
        ],
      },
      {
        type: 'link',
        title: (
          <PricingDropdownLinkItem>
            <IconPremiumSVG />
            <span>{formatMessage({ id: 'general:navbar_item_pricing' })}</span>
          </PricingDropdownLinkItem>
        ),
        onClick: (): void => {
          ampli.clickPricing({ path: router.asPath })
        },
        url: getRootUrl('pricing'),
      },
      {
        type: 'link',
        title: formatMessage({ id: 'general:navbar_item_cross_page' }),
        url: 'https://www.kolradar.com/lp/jp-cross-border',
      },
      {
        type: 'dropdown',
        title: formatMessage({ id: 'general:navbar_group_trend' }),
        columns: [
          [
            {
              title: formatMessage({ id: 'general:navbar_group_resources' }),
              type: 'primary',
              linkItems: [
                {
                  title: formatMessage({ id: 'general:navbar_item_report' }),
                  url: getRootUrl('reports'),
                },
                {
                  title: formatMessage({
                    id: 'general:navbar_item_blog',
                  }),
                  isBlank: true,

                  url: `https://www.kolradar.com/jp/blog`, // 部落格沒有區分 dev, staging, prod，一率使用 prod，所以不能使用 getRootUrl
                },
              ],
            },
          ],
          [
            {
              title: formatMessage({ id: 'general:navbar_item_search_kol' }),
              type: 'primary',
              linkItems: [
                {
                  title: formatMessage({
                    id: 'general:navbar_item_search__kol_by_customized_tag',
                  }),
                  url: getRootUrl(
                    'billboard/instagram/jp/customized-tag?page=1',
                  ),
                },
                {
                  title: formatMessage({
                    id: 'general:navbar_item_search__kol_by_follower_count',
                  }),
                  url: getRootUrl('billboard/instagram/jp/follower-level'),
                },
                {
                  title: formatMessage({
                    id: 'general:navbar_item_search__kol_by_country',
                  }),
                  url: getRootUrl('billboard/instagram/jp/statistics'),
                },
              ],
            },
          ],
        ],
      },
    ],
    [country, formatMessage, getRootUrl, router.asPath],
  )
}

const PricingDropdownLinkItem = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    color: ${({ theme }): string => theme.colors.text.primary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin: 0 0 2px;
  }
`

export default useKolRadarJpMenuItems

import { ChartRowData } from '@/types/schema/chart-data-schema'
import { Report, ReportContent } from '@/types/schema/report-schema'

const NAMESPACE = 'report'

interface StateReport {
  report?: Report
  chartData: {
    [section: string]: ChartRowData[]
  }
  allChartData: {
    [section: string]: ChartRowData[]
  }
}
export type SummaryFilterSection =
  | 'score'
  | 'comment'
  | 'kol'
  | 'post'
  | 'platform'

interface SummaryFilter {
  kol: string[]
  platform: string[]
}

interface SummaryFilterSettings {
  currentReportID: number | 'demo'
  filterByBoard: Record<SummaryFilterSection, SummaryFilter>
}

export type ReportMode = 'preview' | 'edit' | ''

export interface State {
  data: StateReport
  editGuestVisibility: {
    [key: string]: boolean
  }
  summaryFilter: SummaryFilterSettings
  editingReportContent?: ReportContent
  mode: ReportMode
}

export const ACTION_TYPES = {
  CLEAN_REPORT: `${NAMESPACE}#cleanReport`,
  CLEAN_REPORT_CONTENT: `${NAMESPACE}#cleanReportContent`,
  SET_ALL_CHART_DATA: `${NAMESPACE}#setAllChartData`,
  SET_CHART_DATA: `${NAMESPACE}#setChartData`,
  SET_REPORT: `${NAMESPACE}#setReport`,
  DELETE_REPORT_KOL: `${NAMESPACE}#deleteReportKOL`,
  SET_REPORT_CONTENT: `${NAMESPACE}#setReportContent`,
  SET_SUMMARY_FILTER: `${NAMESPACE}#setSummaryFilter`,
  SET_MODE: `${NAMESPACE}#setMode`,
  SET_GUEST_VISIBILITY: `${NAMESPACE}#setGuestVisibility`,
  SET_VISIBILITY: `${NAMESPACE}#setVisibility`,
}

export interface ActionPayload {
  SET_ALL_CHART_DATA: {
    data: ChartRowData[]
  }
  SET_CHART_DATA: {
    section: SummaryFilterSection
    data: ChartRowData[]
  }
  SET_REPORT: Report | undefined
  SET_REPORT_CONTENT: ReportContent
  SET_SUMMARY_FILTER: {
    currentReportID: number | 'demo'
    section: SummaryFilterSection
    data: SummaryFilter
  }
  DELETE_REPORT_KOL: { id: number }
  SET_MODE: ReportMode
  SET_GUEST_VISIBILITY: { [key: string]: boolean }
  SET_VISIBILITY: { [key: string]: boolean }
}

import { isEmpty } from 'lodash-es'
import { tokenProvider } from '@/utils/token-provider'

type PrepareHeaders = (headers: Headers) => Headers

export const prepareHeaders: PrepareHeaders = (headers) => {
  const endpointToken = headers.get('authorization')
  const userToken = tokenProvider()

  if (isEmpty(endpointToken) && userToken) {
    headers.set('authorization', userToken)
  }

  return headers
}

import { MessageRendererProps } from '@pubnub/react-chat-components'
import React, { FunctionComponent, useMemo } from 'react'
import styled from 'styled-components'
import { Participant } from '@/components/campaign/chatroom/message-compose'
import { KolThumbnail } from '@/components/common/kol-thumbnail'
import { Icon } from '@/components/custom-icon'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import { useIntl } from '@/i18n/hooks/use-intl'
import { getMilliseconds } from '@/utils/nanoseconds'
import { getFileAttachment, getMessageText } from '@/utils/pubnub'

type MessageRowProps = {
  sender: Participant
} & MessageRendererProps

const MessageRow: FunctionComponent<MessageRowProps> = (props) => {
  const { formatDate } = useIntl()
  const { message: envelope } = props
  const file = getFileAttachment(envelope)
  const time = useMemo((): string => {
    const timestamp = getMilliseconds(parseInt(String(envelope.timetoken)))
    return formatDate(timestamp, 'dateTimeShort')
  }, [envelope.timetoken, formatDate])

  return (
    <HStack>
      {!props.isOwn && (
        <KolThumbnail imageSize='small' src={props.sender.imageUrl} />
      )}
      <VStack isOwn={props.isOwn}>
        {file?.isImage ? (
          <SafeNewTabLink href={file.url}>
            <FileImage alt={file.name} src={file.url} />
          </SafeNewTabLink>
        ) : (
          <Bubble>
            {file ? (
              <FileLink href={file.url}>
                <Icon type='paper-clip' />
                <span>{file.name}</span>
              </FileLink>
            ) : (
              <Text>{getMessageText(envelope)}</Text>
            )}
          </Bubble>
        )}
        <DateTime>{time}</DateTime>
      </VStack>
    </HStack>
  )
}

const HStack = styled.div`
  display: flex;
  max-width: inherit;

  > :not(:last-child) {
    margin-right: 8px;
  }

  .ant-avatar {
    flex-shrink: 0;
  }

  .ant-avatar::selection {
    background: transparent;
  }
`

const Bubble = styled.div`
  border-radius: 16px;
  max-width: min(480px, 100%);
  padding: 16px 16px 13px;
`

const DateTime = styled.p`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin: 8px 0 0;
`

const VStack = styled.div<{ isOwn: boolean }>`
  align-items: ${({ isOwn }): string => (isOwn ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: column;
  margin: 0 0 14px 0;
  max-width: inherit;

  ${Bubble} {
    background: ${({ isOwn, theme }): string =>
      isOwn ? '#FEF4F3' : theme.colors.background.white};
    border: ${({ isOwn }): string => (isOwn ? 'none' : '1px solid #E8E8E8')};
  }

  ${DateTime} {
    text-align: ${({ isOwn }): string => (isOwn ? 'right' : 'left')};
  }
`

const Text = styled.p`
  color: ${({ theme }): string => theme.colors.text.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  overflow-wrap: anywhere;
  white-space: break-spaces;
`

const FileImage = styled.img`
  width: 240px;
  height: auto;
  border-radius: 16px;

  ::selection {
    background: transparent;
  }
`

const FileLink = styled(SafeNewTabLink)`
  span {
    color: ${({ theme }): string => theme.colors.text.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline;
  }

  .anticon {
    color: ${({ theme }): string => theme.colors.text.secondary};
    margin: 0 4px 0 0;
  }
`

export default MessageRow

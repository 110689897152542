import { RightOutlined } from '@ant-design/icons'
import { Radio } from 'antd'
import isEmpty from 'lodash-es/isEmpty'
import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  OptionGroup,
  OptionGroupListWrapper,
  OptionItem,
} from '@/components/search/filters/common/customized-tag-selector'
import useCustomizedTag from '@/hooks/use-customized-tag'
import { useIntl } from '@/i18n/hooks/use-intl'
import { CustomizedTag } from '@/types/schema/search'

interface CustomizedTagContentSingleModeProps {
  customizedTagsName?: string
  onValuesChange: (customizedTagsName?: string) => void
  data: CustomizedTag[]
}

const DEFAULT_CUSTOMIZED_TAG_NAME = {
  customized_tag_name: 'all',
  sub_customized_tag_names: [],
}

const CustomizedTagContentSingleMode: FunctionComponent<
  CustomizedTagContentSingleModeProps
> = ({ customizedTagsName, onValuesChange, data }) => {
  const { formatMessage } = useIntl()
  const { getCustomizedTagLocalizedName } = useCustomizedTag()
  const [activeParentTag, setActiveParentTag] = useState<CustomizedTag>()
  const [subCustomizedTagsNames, setSubCustomizedTagsNames] = useState<
    CustomizedTag[]
  >([])

  useEffect(() => {
    const parentTagName = customizedTagsName?.split('/')[0]
    const parentCustomizedTag = data.find(
      (tag) => tag.customized_tag_name === parentTagName,
    )
    if (parentCustomizedTag) {
      setActiveParentTag(parentCustomizedTag)
      setSubCustomizedTagsNames(parentCustomizedTag.sub_customized_tag_names)
    }
  }, [customizedTagsName, data])

  const handleParentTagOnClick = (parentTag: CustomizedTag): void => {
    setActiveParentTag(parentTag)
    setSubCustomizedTagsNames(parentTag.sub_customized_tag_names)
  }

  return (
    <OptionGroupListWrapper>
      <StyledRadioGroup
        value={customizedTagsName}
        onChange={(e): void => onValuesChange(e.target.value)}
      >
        <OptionGroup>
          {[DEFAULT_CUSTOMIZED_TAG_NAME, ...data].map((parentTag) => {
            return (
              <CustomizedTagItem
                $isActive={
                  activeParentTag?.customized_tag_name ===
                  parentTag.customized_tag_name
                }
                key={parentTag.customized_tag_name}
                onClick={(): void => {
                  handleParentTagOnClick(parentTag)
                }}
              >
                <Radio value={parentTag.customized_tag_name}>
                  <span>
                    {parentTag.customized_tag_name === 'all'
                      ? formatMessage({ id: 'general:auto_tag_all' })
                      : getCustomizedTagLocalizedName(
                          parentTag.customized_tag_name,
                        )}
                  </span>
                  {!isEmpty(parentTag.sub_customized_tag_names) && (
                    <RightOutlined />
                  )}
                </Radio>
              </CustomizedTagItem>
            )
          })}
        </OptionGroup>
        <OptionGroup>
          {subCustomizedTagsNames.map((childTag) => {
            return (
              <CustomizedTagItem
                $isActive={customizedTagsName === childTag.customized_tag_name}
                key={childTag.customized_tag_name}
              >
                <Radio value={childTag.customized_tag_name}>
                  {getCustomizedTagLocalizedName(childTag.customized_tag_name)}
                </Radio>
              </CustomizedTagItem>
            )
          })}
        </OptionGroup>
      </StyledRadioGroup>
    </OptionGroupListWrapper>
  )
}

const CustomizedTagItem = styled((props) => <OptionItem {...props} />)`
  label {
    width: 100%;
  }
  .ant-radio-wrapper {
    margin: 0;
  }
  span.ant-radio + * {
    padding: 0 0 0 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;
  padding: 12px 8px;
`

export default CustomizedTagContentSingleMode

import { DownOutlined } from '@ant-design/icons'
import { Radio, Tag } from 'antd'
import isEmpty from 'lodash-es/isEmpty'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import AllCountrySelect from '@/components/all-country-select'
import {
  ButtonRadioGroupWrapper,
  FilterGroupWrapper,
  Label,
} from '@/components/search/filters/advanced-filters'
import CustomizedTagSelector from '@/components/search/filters/common/customized-tag-selector'
import FansSelectorContent from '@/components/search/filters/common/fans-selector-content'
import QuotationRateSelectorContent from '@/components/search/filters/common/quotation-rate-selector-content'
import LangFilter from '@/components/search/filters/lang-filter'
import useCountries from '@/components/search/hooks/use-countries'
import { COUNTRY_I18N_MAP } from '@/constants/country-code'
import { CurrencyI18nId } from '@/constants/country-currency'
import { Gender } from '@/constants/gender'
import useCustomizedTag from '@/hooks/use-customized-tag'
import useIsJapan from '@/hooks/use-is-japan'
import { mapKolQuotationCurrency } from '@/hooks/use-kol-quotations-range'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'

export const GENDERS: {
  value: Gender
  label: I18nId
}[] = [
  {
    value: Gender.Any,
    label: 'search:filter_platform_any',
  },
  {
    value: Gender.Female,
    label: 'kol:edit_field_gender_female',
  },
  {
    value: Gender.Male,
    label: 'kol:edit_field_gender_male',
  },
  {
    value: Gender.Else,
    label: 'kol:edit_field_gender_other',
  },
]

interface KolSelfFilterGroupProps {
  filterValues: Condition
  onValuesChange: (changedValues: Condition) => void
}

const KolSelfFilterGroup: FunctionComponent<KolSelfFilterGroupProps> = ({
  filterValues,
  onValuesChange,
}) => {
  const { formatMessage } = useIntl()
  const isJapan = useIsJapan()
  const { getCustomizedTagLocalizedName, parseFilterTags } = useCustomizedTag()
  const { options, defaultCountry } = useCountries({ hasUnlimited: true })
  const { data: userStatus } = useFetchUserStatusQuery()
  const quotationCurrency = mapKolQuotationCurrency(
    userStatus?.currentWorkspace?.currencyCode,
  )

  const filterTags = parseFilterTags(filterValues.customized_tags_names)
  const country_code = filterValues.country_code ?? defaultCountry

  return (
    <FilterGroupWrapper>
      <h4>{formatMessage({ id: 'general:about_influencer' })}</h4>
      <SelectorWrapper>
        <Label>
          {formatMessage({ id: 'kol:field_kol_type' })} (
          {formatMessage({
            id: 'search:filter_kol_category_choose_many_2',
          })}
          )
        </Label>
        <CustomizedTagSelector
          customizedTagsNames={filterValues.customized_tags_names}
          mode='multiple'
          onValuesChange={(customized_tags_names): void =>
            onValuesChange({ customized_tags_names })
          }
        >
          <HackSelectorWrapper>
            <div>
              {isEmpty(filterTags)
                ? formatMessage({ id: 'kol:field_skill_placeholder' })
                : filterTags.map((key) => (
                    <Tag
                      closable
                      key={key}
                      onClose={(): void =>
                        onValuesChange({
                          customized_tags_names: filterTags
                            .filter((tag) => tag !== key)
                            .join('|'),
                        })
                      }
                    >
                      {getCustomizedTagLocalizedName(key)}
                    </Tag>
                  ))}
            </div>
            <DownOutlined />
          </HackSelectorWrapper>
        </CustomizedTagSelector>
      </SelectorWrapper>
      <SelectorWrapper>
        <Label>{formatMessage({ id: 'kol:edit_fans_group_region' })}</Label>
        <AllCountrySelect
          defaultPriorityOptions={options}
          value={country_code}
          onChange={(country_code): void => {
            onValuesChange({
              country_code,
            })
          }}
        >
          <HackSelectorWrapper>
            <div>
              {formatMessage({
                id: COUNTRY_I18N_MAP[country_code],
              })}
            </div>
            <DownOutlined />
          </HackSelectorWrapper>
        </AllCountrySelect>
      </SelectorWrapper>
      <div>
        <Label>{formatMessage({ id: 'kol:edit_field_gender' })}</Label>
        {/* @todo Use components/common/selectors/gender-radio-group.tsx instead */}
        <ButtonRadioGroupWrapper>
          <Radio.Group
            optionType='button'
            options={GENDERS.map(({ label: id, value }) => ({
              label: formatMessage({ id }),
              value,
            }))}
            value={filterValues.gender ?? Gender.Any}
            onChange={(v): void =>
              onValuesChange({
                gender:
                  v.target.value === Gender.Any ? undefined : v.target.value,
              })
            }
          />
        </ButtonRadioGroupWrapper>
      </div>
      <div>
        <Label>{formatMessage({ id: 'general:languages' })} </Label>
        <LangFilter
          value={filterValues?.languages ?? []}
          onChange={(languages: string[]): void =>
            onValuesChange({ languages: languages })
          }
        />
      </div>
      <div>
        <Label>{formatMessage({ id: 'search:filter_option_fans' })}</Label>
        <FansSelectorContent
          filterValues={{
            follower_start_from: filterValues.follower_start_from,
            follower_end_to: filterValues.follower_end_to,
          }}
          onValuesChange={onValuesChange}
        />
      </div>
      {!isJapan && (
        <div>
          <Label>{formatMessage({ id: 'campaign:accept_price' })}</Label>
          <QuotationRateSelectorContent
            filterValues={{
              minQuotationPrice: filterValues.minQuotationPrice,
              maxQuotationPrice: filterValues.maxQuotationPrice,
            }}
            onValuesChange={onValuesChange}
          />
          <QuotationCurrency>
            {`${formatMessage({ id: 'general:currency' })} : ${formatMessage({
              id: CurrencyI18nId[quotationCurrency],
            })} ${quotationCurrency}`}
          </QuotationCurrency>
        </div>
      )}
    </FilterGroupWrapper>
  )
}

const HackSelectorWrapper = styled.div`
  cursor: pointer;
  padding: 5px 11px;
  display: flex;
  min-height: 32px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }): string => theme.colors.border.border};
  border-radius: 4px;
  color: ${({ theme }): string => theme.colors.base.grey.grey3};

  svg {
    width: 10px;
    height: 10px;
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-tag {
    margin-right: 4px;
  }
`

const QuotationCurrency = styled.div`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
`

const SelectorWrapper = styled.div`
  .ant-popover {
    width: calc(100% - 208px);

    .ant-popover-inner-content {
      padding-right: 0;
    }

    .ant-radio-group-outline {
      display: flex;
      flex-direction: column;

      > :not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
`

export default KolSelfFilterGroup

import { ModelServiceWithEndAt } from '@/types/mock-api-types'

function getDbPermissions(
  availableServices: ModelServiceWithEndAt[] = [],
): string[] {
  return availableServices
    .filter((service) => service.serviceCategory === 'database')
    .map((service) => service.code.split('_')[1] ?? '')
    .filter((db) => db !== '')
}

export default getDbPermissions

import {
  Page,
  PageAuthentication,
  PUBLIC_PAGES,
  WITHOUT_LOGIN_PAGES,
} from '@/hooks/use-authorization/constants'

interface PageAuthenticationChecker {
  (page: Page): PageAuthentication
}

const getPageAuthentication: PageAuthenticationChecker = (page) => {
  if (PUBLIC_PAGES.includes(page)) {
    return PageAuthentication.Ignore
  }

  if (WITHOUT_LOGIN_PAGES.includes(page)) {
    return PageAuthentication.Unauthenticated
  }

  return PageAuthentication.Authenticated
}

export default getPageAuthentication

import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

interface CountdownProps {
  expireAt: string
  render: (countDownTime: {
    days: number
    hours: number
    minutes: number
  }) => ReactNode
}

const Countdown: FunctionComponent<CountdownProps> = ({ expireAt, render }) => {
  const [countDownTime, setCountDownTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  })

  const countDown = useCallback(() => {
    const expireTime = new Date(expireAt).getTime()
    const now = new Date().getTime()
    const distance = expireTime - now <= 0 ? 0 : expireTime - now

    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    )
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))

    setCountDownTime({ days, hours, minutes })
  }, [expireAt])

  useEffect(() => {
    countDown()

    const countDownInterval = setInterval(countDown, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [countDown])

  return render(countDownTime)
}

export default Countdown

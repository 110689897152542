import { Button, message, Table, Tag } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { head, isNull, isUndefined } from 'lodash-es'
import React, { FunctionComponent, ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCopyToClipboard } from 'react-use'
import styled from 'styled-components'
import { nameof } from 'ts-simple-nameof'
import CampaignDetailDrawer from '@/components/campaign/campaign-detail-drawer'
import {
  CampaignContentWrapper,
  MessageWrapper,
  NA,
  UTMLinkWrapper,
} from '@/components/campaign/kol/case'
import { FinishCasesProps } from '@/components/campaign/kol/finish/finish-cases'
import { KolInfoWrapper } from '@/components/campaign/kol/hooks/use-pending-cases-columns'
import {
  getAcceptionStatusTagColor,
  isSignedAcception,
  openAttachment,
  paymentStatusConfigs,
  PaymentStatusTag,
} from '@/components/campaign/kol/running/running-case-table'
import SummaryHead from '@/components/campaign/kol/summary-head'
import { KolThumbnail } from '@/components/common/kol-thumbnail'
import { Icon } from '@/components/custom-icon'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import { FixedLeft, FixedRight } from '@/constants/column-fixed-placements'
import useAgentInfo from '@/hooks/use-agent-info'
import { useAuth } from '@/hooks/use-auth'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Campaign } from '@/store/campaign'
import {
  setTemporaryConversation,
  toggleMessageComposeVisible,
} from '@/store/chatroom'
import { CampaignCaseColumn, CampaignKolPost } from '@/types/campaign-case'
import {
  CampaignKolPaymentStatus,
  CampaignKolStatus,
} from '@/types/mock-api-types'
import { convertPlatformType } from '@/utils/convert-platform'
import useContractModal from '@/utils/hooks/use-contract-modal'
import { parseAsNumber } from '@/utils/parse-as-number'
import { convertUTMInfo } from '@/utils/utm'

export const getPaymentStatus = (
  campaignCase: CampaignCaseColumn,
): PaymentStatusTag | undefined => {
  {
    const { status, campaign, orderStatus, approverType, paymentStatus } =
      campaignCase

    if (status === CampaignKolStatus.Canceled) {
      return {
        color: 'error',
        tagI18nKey: 'campaign:cancel_cooperation',
      }
    }

    if (campaign.snapIsInternal) {
      if (status === CampaignKolStatus.Rejected) {
        return {
          color: 'default',
          tagI18nKey: 'kol:payment_status_rejected',
        }
      }

      if (paymentStatus === CampaignKolPaymentStatus.NotNeed) {
        return {
          color: 'default',
          tagI18nKey: 'campaign:payment_status_no_need',
        }
      }

      return {
        color: 'success',
        tagI18nKey: 'campaign:payment_status_paid',
      }
    } else {
      if (status === CampaignKolStatus.Rejected) {
        return {
          color: 'default',
          tagI18nKey:
            `kol:close_list_status_${status}_by_${approverType}` as I18nId,
        }
      }

      if (isNull(orderStatus)) {
        return
      }

      const paymentStatus = paymentStatusConfigs.find((config) =>
        config.orderStatusList.includes(orderStatus),
      )

      if (isUndefined(paymentStatus)) {
        return
      }

      return {
        color: paymentStatus.color,
        tagI18nKey: `kol:payment_status_${paymentStatus.status}`,
      }
    }
  }
}

const FinishCaseTable: FunctionComponent<FinishCasesProps> = ({
  response,
  isFetching,
  onChangePage,
}) => {
  const dispatch = useDispatch()
  const { showContract } = useContractModal()
  const { formatNumber, formatMessage } = useIntl()
  const { isAgent } = useAuth()
  const { agencyKOL } = useAgentInfo()

  const [, copyToClipboard] = useCopyToClipboard()
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>()

  const columns: ColumnProps<CampaignCaseColumn>[] = [
    {
      title: formatMessage({ id: 'kol:campaign_content' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.campaign),
      width: 400,
      fixed: FixedLeft,
      onCell: (campaignCase: CampaignCaseColumn) => ({
        rowSpan: campaignCase.isHead ? campaignCase.campaign?.kolsCount : 0,
      }),
      render: (campaign: CampaignCaseColumn['campaign']): ReactNode => {
        return (
          <CampaignContentWrapper>
            <SummaryHead
              campaign={campaign}
              onCheckDetailClick={setSelectedCampaign}
            />
          </CampaignContentWrapper>
        )
      },
    },
    isAgent
      ? {
          title: formatMessage({ id: 'campaign:field_influencer' }),
          dataIndex: nameof<CampaignCaseColumn>((p) => p.kolUUID),
          render: (
            kolUUID: CampaignCaseColumn['kolUUID'],
            campaignKol: CampaignCaseColumn,
          ): ReactNode => {
            return (
              <KolInfoWrapper>
                <KolThumbnail kolUuid={kolUUID} />
                <span>{head(campaignKol.kolName)}</span>
              </KolInfoWrapper>
            )
          },
        }
      : {},
    {
      title: formatMessage({ id: 'kol:cooperation_fee' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.reward),
      width: 120,
      render: (reward: CampaignCaseColumn['reward']): string => {
        return `$ ${formatNumber(reward)}`
      },
    },
    {
      title: formatMessage({ id: 'campaign:condition_utm_link_title' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.utmInfo),
      width: 200,
      render: (
        info: CampaignCaseColumn['utmInfo'],
        campaignCase,
      ): ReactNode => {
        if (campaignCase.status === CampaignKolStatus.Canceled) {
          return (
            <Tag color='error'>
              {formatMessage({ id: 'campaign:cancel_cooperation' })}
            </Tag>
          )
        } else if (info) {
          const utmLink = convertUTMInfo(info)
          return (
            <UTMLinkWrapper>
              <p>{utmLink}</p>
              <Button
                type='link'
                onClick={(): void => {
                  copyToClipboard(utmLink)
                  message.success(
                    formatMessage({ id: 'general:successfully_copied' }),
                  )
                }}
              >
                <Icon type='elIconCopy' />
              </Button>
            </UTMLinkWrapper>
          )
        } else {
          return <NA>N/A</NA>
        }
      },
    },
    {
      title: formatMessage({ id: 'general:contract' }),
      dataIndex: 'campaignKolContracts',
      render: (
        contracts: CampaignCaseColumn['campaignKolContracts'],
        data,
      ): ReactNode => {
        return (
          <>
            {contracts.map((contract) => (
              <Contract key={contract.id}>
                <Tag
                  color={getAcceptionStatusTagColor(
                    isSignedAcception(contract.contractAcception),
                  )}
                >
                  {isSignedAcception(contract.contractAcception)
                    ? formatMessage({ id: 'campaign:contact_status_confirmed' })
                    : formatMessage({ id: 'kol:wait_sign_back' })}
                </Tag>
                <Content
                  onClick={(): void => {
                    const campaignInfo = {
                      id: data.campaign.id,
                      name: data.campaign.name,
                    }
                    showContract(contract.contractAcception, campaignInfo)
                  }}
                >
                  {contract.contractTitle}
                </Content>
              </Contract>
            ))}
          </>
        )
      },
    },
    {
      title: formatMessage({ id: 'campaign:field_remittance_advice' }),
      dataIndex: 'campaignKolLaborDeclaration',
      render: (
        labor: CampaignCaseColumn['campaignKolLaborDeclaration'],
        data,
      ): ReactNode => {
        return labor ? (
          <Contract>
            <Tag
              color={getAcceptionStatusTagColor(
                isSignedAcception(labor.contractAcception),
              )}
            >
              {isSignedAcception(labor.contractAcception)
                ? formatMessage({ id: 'campaign:contact_status_confirmed' })
                : formatMessage({ id: 'kol:wait_sign_back' })}
            </Tag>
            <Content
              onClick={(): void => {
                if (labor.contractAcception) {
                  const campaignInfo = {
                    id: data.campaign.id,
                    name: data.campaign.name,
                  }
                  showContract(labor.contractAcception, campaignInfo)
                }
              }}
            >
              {formatMessage({ id: 'campaign:field_remittance_advice' })}
            </Content>
          </Contract>
        ) : (
          'N/A'
        )
      },
    },
    {
      title: formatMessage({ id: 'campaign:field_attachment' }),
      dataIndex: 'attachments',
      render: (attachments: CampaignCaseColumn['attachments']): ReactNode => {
        return (
          <>
            {attachments.map((attachment) => (
              <Contract key={attachment.id}>
                <Icon type='paper-clip' />
                <Content
                  onClick={(): void => openAttachment(attachment.signedUrl)}
                >
                  {attachment.fileName}
                </Content>
              </Contract>
            ))}
          </>
        )
      },
    },
    {
      title: formatMessage({ id: 'kol:post_link' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.campaignKolPosts),
      render: (posts: CampaignKolPost[]): ReactNode => {
        return (
          <>
            {posts.map((post) => {
              const platform = convertPlatformType(post.platformType)

              if (!platform) {
                return null
              }

              return (
                <Contract key={post.id}>
                  <SafeNewTabLink href={post.url}>
                    {platform.title}
                  </SafeNewTabLink>
                </Contract>
              )
            })}
          </>
        )
      },
    },
    {
      title: formatMessage({ id: 'kol:approve_payment_amount' }),
      width: 200,
      dataIndex: nameof<CampaignCaseColumn>(
        (campaignCase) => campaignCase.orderRewardReceived,
      ),
      render: (
        orderRewardReceived: CampaignCaseColumn['orderRewardReceived'],
        campaignCase: CampaignCaseColumn,
      ): ReactNode => {
        if (campaignCase.status === CampaignKolStatus.Canceled) {
          return (
            <Tag color='error'>
              {formatMessage({ id: 'campaign:cancel_cooperation' })}
            </Tag>
          )
        }
        const reward = parseAsNumber(orderRewardReceived)

        return reward === 0 ? (
          '-'
        ) : (
          <ApproveAmountWrapper>
            <span>{`$ ${formatNumber(reward)}`}</span>
            {!campaignCase.campaign.snapIsInternal && (
              <span>
                {formatMessage(
                  { id: 'kol:approve_payment_amount_sub_info' },
                  {
                    commissionPercentage: campaignCase.snapCommissionPercentage,
                  },
                )}
              </span>
            )}
          </ApproveAmountWrapper>
        )
      },
    },
    {
      title: formatMessage({ id: 'kol:approve_payment_status' }),
      dataIndex: nameof<CampaignCaseColumn>((p) => p.orderStatus),
      render: (
        orderStatus: CampaignCaseColumn['orderStatus'],
        campaignCase: CampaignCaseColumn,
      ): ReactNode => {
        const paymentStatus = getPaymentStatus(campaignCase)
        if (paymentStatus) {
          return (
            <Tag color={paymentStatus.color}>
              {formatMessage({
                id: paymentStatus.tagI18nKey,
              })}
            </Tag>
          )
        }
      },
    },
    {
      title: formatMessage({ id: 'campaign:field_operation' }),
      dataIndex: 'id',
      width: 100,
      fixed: FixedRight,
      render: (campaignId: number, campaignCase): ReactNode => {
        return (
          !agencyKOL && (
            <MessageWrapper
              onClick={(): void => {
                dispatch(
                  setTemporaryConversation({
                    campaignCase,
                    id: campaignCase.channelId,
                    lastReadTime: 1,
                    metadata: undefined,
                    unreadMessageCount: 0,
                  }),
                )
                dispatch(toggleMessageComposeVisible())
              }}
            >
              {formatMessage({ id: 'general:message_checking' })}
            </MessageWrapper>
          )
        )
      },
    },
  ]

  return (
    <>
      <Table<CampaignCaseColumn>
        columns={columns}
        dataSource={response?.data}
        loading={isFetching}
        pagination={{
          total: response?.total,
          onChange: onChangePage,
          showSizeChanger: false,
        }}
        scroll={{ x: 'max-content' }}
        showSorterTooltip={false}
        tableLayout='auto'
      />
      <CampaignDetailDrawer
        campaign={selectedCampaign}
        onClose={(): void => setSelectedCampaign(undefined)}
      />
    </>
  )
}

export default FinishCaseTable

const Content = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const Contract = styled.div`
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;

  a {
    color: ${({ theme }): string => theme.colors.text.primary};
    text-decoration: underline;
  }
`

export const ApproveAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    margin: 2px 0;
  }
`

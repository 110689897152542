import { camelizeKeys } from 'humps'
import { api, OAuthAPITagType } from '@/api/api'
import {
  googleAnalyticsAdminApi,
  GoogleAnalyticsAdminAPITagType,
} from '@/api/google-analytics-admin-api'
import { youTubeDataAPI, YouTubeDataAPITagType } from '@/api/youtube-data-api'
import { decamelizeKeys } from '@/utils/humps'

type SetFacebookAuthTokenRequest = {
  accessToken: string
  userID: string
}

export interface AuthPage {
  instagramBusinessAccount?: string
  isVerified: boolean
  facebookName: string
  instagramUsername: string
  pageAccessToken: string
  pageId?: string
  facebookProfileThumbnail?: string
  instagramProfileThumbnail?: string
}

interface FetchAuthPagesResponse {
  pageIds: AuthPage[]
}

export enum GoogleAccessTokenType {
  Analytics = 'ga4',
  YouTube = 'youtube',
}

export enum GoogleAuthorizationStatus {
  Declined = 'permission_declined',
  Granted = 'permission_granted',
}

type ExchangeGoogleAccessTokenRequest = {
  type: GoogleAccessTokenType
  authorizationCode: string
}

type FetchGoogleAccessTokenResponse = {
  type: GoogleAccessTokenType
  status: GoogleAuthorizationStatus
  accessToken?: string
}

export const oauthApi = api.injectEndpoints({
  endpoints: (build) => ({
    setFacebookAuthToken: build.mutation<void, SetFacebookAuthTokenRequest>({
      query: (params) => {
        return {
          url: '/oauth/facebook/token',
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: [OAuthAPITagType.AuthPages],
    }),
    fetchAuthPages: build.query<FetchAuthPagesResponse, void>({
      query: () => {
        return {
          url: '/oauth/facebook/page/verification-status',
        }
      },
      providesTags: [OAuthAPITagType.AuthPages],
      transformResponse: (response: object[]) => {
        return camelizeKeys(response) as unknown as FetchAuthPagesResponse
      },
    }),
    fetchGoogleAccessToken: build.query<
      FetchGoogleAccessTokenResponse,
      GoogleAccessTokenType
    >({
      query: (type) => {
        return {
          url: '/oauth/google/token',
          params: { type },
        }
      },
      providesTags: [OAuthAPITagType.GoogleAccessToken],
      transformResponse: (baseQueryReturnValue: object) =>
        camelizeKeys(baseQueryReturnValue) as FetchGoogleAccessTokenResponse,
      async onQueryStarted(type, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          switch (type) {
            case GoogleAccessTokenType.YouTube:
              dispatch(
                youTubeDataAPI.util.invalidateTags([
                  YouTubeDataAPITagType.Channels,
                ]),
              )
              break
            case GoogleAccessTokenType.Analytics:
              dispatch(
                googleAnalyticsAdminApi.util.invalidateTags([
                  GoogleAnalyticsAdminAPITagType.AccountSummaries,
                ]),
              )
              break
          }
        } catch (error) {
          console.error(error)
        }
      },
    }),
    exchangeGoogleAccessToken: build.mutation<
      void,
      ExchangeGoogleAccessTokenRequest
    >({
      query: (request) => {
        return {
          url: '/oauth/google/token',
          method: 'POST',
          body: decamelizeKeys(request),
        }
      },
      invalidatesTags: [OAuthAPITagType.GoogleAccessToken],
    }),
  }),
  overrideExisting: false,
})

export const useSetFacebookAuthTokenMutation =
  oauthApi.endpoints.setFacebookAuthToken.useMutation
export const useFetchAuthPagesQuery = oauthApi.endpoints.fetchAuthPages.useQuery
export const useFetchGoogleAccessTokenQuery =
  oauthApi.endpoints.fetchGoogleAccessToken.useQuery
export const useExchangeGoogleAccessTokenMutation =
  oauthApi.endpoints.exchangeGoogleAccessToken.useMutation

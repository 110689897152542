import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModelFileItem, WorkspaceRequestObject } from '@/types/mock-api-types'

interface CreateWorkspaceState {
  draft?: WorkspaceDraft
}

const initialState: CreateWorkspaceState = {
  draft: undefined,
}

type WorkspaceDraft = Partial<WorkspaceRequestObject> & {
  currentStep: 'aboutTeam' | 'choosePlan'
  iconFile?: ModelFileItem
}

const createWorkspaceSlice = createSlice({
  name: 'createWorkspace',
  initialState,
  reducers: {
    setWorkspaceDraft: (
      state,
      action: PayloadAction<CreateWorkspaceState['draft']>,
    ) => {
      state.draft = action.payload
    },
  },
})

export const { setWorkspaceDraft } = createWorkspaceSlice.actions

export default createWorkspaceSlice.reducer

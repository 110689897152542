import { skipToken } from '@reduxjs/toolkit/query/react'
import isEmpty from 'lodash-es/isEmpty'
import { useFetchUserStatusQuery } from '@/api/user-api'
import { Trial, useFetchTrialQuery } from '@/api/workspaces-api'
import useIsJapan from '@/hooks/use-is-japan'
import { usePermissions } from '@/hooks/use-permissions'

interface UseTrial {
  (): { trial: Trial[]; canTrial: boolean }
}

const useTrial: UseTrial = () => {
  const { data: userStatus } = useFetchUserStatusQuery()
  const { data } = useFetchTrialQuery(
    userStatus?.currentWorkspaceId
      ? {
          workspaceId: userStatus.currentWorkspaceId,
        }
      : skipToken,
  )
  const { withFreePermission } = usePermissions()
  const isJapan = useIsJapan()

  return {
    trial: data ?? [],
    canTrial: !isJapan && isEmpty(data) && withFreePermission,
  }
}

export default useTrial

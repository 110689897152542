import { isEmpty, omit, pick } from 'lodash-es'
import querystring, { StringifiableRecord } from 'query-string'
import { UTM_KEYS } from '@/constants/cookie-name'
import { camelizeKeys, decamelizeKeys } from '@/utils/humps'

export type UTMInfo = {
  baseUrl: string
  utmSource: string
  utmMedium: string
  utmCampaign: string
  utmContent?: string
  utmTerm?: string
}

export const isContainsUTMInfo = (link?: string): boolean => {
  if (isEmpty(link) || !link) {
    return false
  }

  const info = convertUTMLink(link)
  return (
    !isEmpty(info.utmSource) ||
    !isEmpty(info.utmMedium) ||
    !isEmpty(info.utmCampaign) ||
    !isEmpty(info.utmContent) ||
    !isEmpty(info.utmTerm)
  )
}

export const convertUTMLink = (link: string): Partial<UTMInfo> => {
  const parsedUrl = querystring.parseUrl(link)
  const query = omit(parsedUrl.query, UTM_KEYS)

  return {
    baseUrl: querystring.stringifyUrl({ url: parsedUrl.url, query }),
    ...camelizeKeys(pick(parsedUrl.query, UTM_KEYS)),
  }
}

export const convertUTMInfo = (info: UTMInfo): string => {
  return querystring.stringifyUrl(
    {
      url: info.baseUrl,
      query: decamelizeKeys(omit(info, 'baseUrl')) as StringifiableRecord,
    },
    { skipNull: true, skipEmptyString: true },
  )
}

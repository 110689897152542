import { Page, UserType } from '@/hooks/use-authorization/constants'
import { UserSchema } from '@/types/schema/user-schema'

interface GetRegistrationStatusUrl {
  (user: UserSchema): string
}

const getRegistrationStatusUrl: GetRegistrationStatusUrl = (user) => {
  const hasType = user.type !== UserType.Untyped
  const completeRegistration = !user.incompleteRegistration
  const emailConfirmed = user.emailConfirmed !== false
  const verified = user.verified
  const isAd = user.type === UserType.NormalAd

  if (!hasType) {
    if (completeRegistration) {
      return `${Page.Register}?step=1.5`
    }

    return `${Page.Register}?continueRegister=true`
  }

  if (!completeRegistration) {
    return `${Page.Register}?continueRegister=true&type=${user.type}`
  }

  if (!emailConfirmed) {
    return Page.RegisterSuccess
  }

  if (!verified) {
    return Page.RegisterSuccess
  }

  if (isAd) {
    return Page.CreateWorkspace
  }

  if (!isAd) {
    return Page.VerifyAccount
  }

  return ''
}

export default getRegistrationStatusUrl

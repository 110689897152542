import type { PluginFunc } from 'dayjs'

const unixNanoseconds: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.unixNanoseconds = (): number => {
    const day = dayjsFactory(this)
    return day.valueOf() * 10000
  }
}

declare module 'dayjs' {
  interface Dayjs {
    /**
     * This returns the number of nanoseconds since the Unix Epoch of the Day.js object.
     */
    unixNanoseconds(): number
  }
}

export default unixNanoseconds

export enum PostType {
  Text = 'text',
  Image = 'image',
  Video = 'video',
  Live = 'live',
  Story = 'story',
  Blog = 'blog',
  Other = 'other',
  Short = 'short',
}

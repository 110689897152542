import defaults from 'lodash-es/defaults'
import { Messages, SupportedLanguages } from '@/i18n/config'
import ach from '@/i18n/locales/ach/locale.json'
import zh from '@/i18n/locales/zh/locale.json'

// support safari 12 and IE 11
import('intl-pluralrules')

// 如果沒有值，就用 sourceLanguage 的值, 除了 zh & ach 其他都會由 OTA 抓
const locales: Record<SupportedLanguages, Messages> = {
  zh: zh,
  en: zh,
  ja: zh,
  ach: defaults(ach, zh),
}

export const i18nApi = {
  messages(language: SupportedLanguages): Messages {
    return locales[language]
  },
}

import { Radio } from 'antd'
import { RadioProps } from 'antd/lib/radio'
import React from 'react'
import styled from 'styled-components'
import { StyleParagraph } from '@/components/common/typography'
import { clickLabelStatus } from '@/utils/click-label-status'

export const RadioButton = styled((props: RadioProps) => (
  <Radio {...props}>
    <StyleParagraph color={clickLabelStatus(props.checked, props.disabled)}>
      {props.children}
    </StyleParagraph>
  </Radio>
))`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

import { skipToken } from '@reduxjs/toolkit/query'
import { Col, Modal, Radio, Row, Spin } from 'antd'
import { isEmpty } from 'lodash-es'
import React, { FunctionComponent, useEffect, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useSet } from 'react-use'
import styled from 'styled-components'
import { Story, useFetchStoriesQuery } from '@/api/instagram-api'
import { AuthPage } from '@/api/oauth-api'
import AuthPages from '@/components/campaign/kol/add-post-modal/auth-pages'
import AsyncImage from '@/components/common/async-image'
import { useAuth } from '@/hooks/use-auth'
import { useIntl } from '@/i18n/hooks/use-intl'
import { PlatformCode } from '@/utils/convert-platform'
import { getThumbnail } from '@/utils/get-thumbnails'
import { isFetchBaseQueryError } from '@/utils/redux-toolkit'

type AddInstagramStoryProps = {
  selectedPage?: AuthPage
  selectedStoryId?: string
  onSelect: (story: Story) => void
  onAuthClick: VoidFunction
}

const InstagramStories: FunctionComponent<AddInstagramStoryProps> = ({
  selectedPage,
  selectedStoryId,
  onSelect,
  onAuthClick,
}) => {
  const { formatDate } = useIntl()

  const { userInfo } = useAuth()
  const [authModalVisible, setAuthModalVisible] = useState(false)
  const [, { add, has }] = useSet<string>()
  const [stories, setStories] = useState<Story[]>([])
  const [nextCursor, setNextCursor] = useState<string>()

  const {
    data: fetchStoriesResponse,
    error,
    isFetching: fetchingStories,
  } = useFetchStoriesQuery(
    selectedPage?.instagramBusinessAccount
      ? {
          instagramBusinessAccount: selectedPage.instagramBusinessAccount,
          nextCursor,
        }
      : skipToken,
  )

  const [sentryRef] = useInfiniteScroll({
    loading: fetchingStories,
    hasNextPage:
      !!fetchStoriesResponse &&
      !fetchStoriesResponse.isLastPage &&
      !isEmpty(fetchStoriesResponse?.nextCursor),
    onLoadMore: () => {
      setNextCursor(fetchStoriesResponse?.nextCursor)
    },
  })

  useEffect(() => {
    if (error && isFetchBaseQueryError(error) && error.status === 500) {
      setAuthModalVisible(true)
    }
  }, [error])

  useEffect(() => {
    if (fetchStoriesResponse?.data.length) {
      if (!nextCursor) {
        setStories(fetchStoriesResponse.data)
        return
      }

      if (nextCursor && !fetchingStories && !has(nextCursor)) {
        add(nextCursor)

        setStories((prevStories) => [
          ...prevStories,
          ...fetchStoriesResponse.data,
        ])
      }
    }
  }, [add, fetchStoriesResponse, has, nextCursor, fetchingStories])

  return (
    <Wrapper ref={sentryRef}>
      <Row gutter={[8, 7]}>
        {stories.map((story) => {
          const selected = story.shortcode === selectedStoryId
          return (
            <Col
              key={story.shortcode}
              span={6}
              onClick={(): void => onSelect(story)}
            >
              <StoryWrapper>
                <AsyncImage
                  alt={story.caption}
                  fallbackUrls={[getThumbnail(userInfo?.kolId)]}
                  src={story.thumbnailUrl ?? ''}
                />
                <Radio checked={selected} />
                <p>{formatDate(story.timestamp, 'dateTimeShort')}</p>
                {selected && <SelectedIndicator />}
              </StoryWrapper>
            </Col>
          )
        })}
      </Row>
      {fetchingStories && <Spin />}
      <Modal
        centered
        destroyOnClose={true}
        footer={null}
        open={authModalVisible}
        onCancel={(): void => setAuthModalVisible(false)}
      >
        <AuthPages
          handleAuthClick={(): void => {
            setAuthModalVisible(false)
            onAuthClick()
          }}
          platformCode={PlatformCode.Instagram}
        />
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-height: 315px;
  overflow: scroll;

  .ant-spin {
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
  }
`

const SelectedIndicator = styled.div`
  border: 3px solid ${({ theme }): string => theme.colors.brand.primary};
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`

const StoryWrapper = styled.div`
  background: rgba(62, 61, 61, 0.2);
  border-radius: 2px;
  cursor: pointer;
  height: 191px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ant-radio-wrapper {
    margin: 8px 0 0 8px;
  }

  p {
    width: 100%;
    bottom: 4px;
    color: ${({ theme }): string => theme.colors.text.white};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
    position: absolute;
    text-align: center;
  }
`

export default InstagramStories

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import env from '@/utils/env'

export const k6api = createApi({
  reducerPath: 'k6api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.PUBLIC_K6RSATELLITE_API_BASE_URL}/v1`,
  }),
  endpoints: () => ({}),
})

import { api } from '@/api/api'
import {
  Part,
  Request,
  Response,
  YouTubePlaylistItem,
} from '@/api/youtube-data-api'
import removeNil from '@/utils/remove-nil'

const DEFAULT_MAX_RESULTS = 50

type YouTubePlaylistItemsRequest = Request & {
  playlistId: string
}

const youTubeApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchPlaylistItems: build.query<
      Response<YouTubePlaylistItem>,
      YouTubePlaylistItemsRequest
    >({
      query: ({
        playlistId,
        pageToken,
        part = [Part.Snippet],
        maxResults = DEFAULT_MAX_RESULTS,
      }) => {
        return {
          url: '/youtube/playlist-items',
          params: removeNil({ part, playlistId, maxResults, pageToken }),
        }
      },
    }),
  }),
})

export const useFetchPlaylistItemsQuery =
  youTubeApi.endpoints.fetchPlaylistItems.useQuery

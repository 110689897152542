import { useRouter } from 'next/router'
import { Page } from '@/hooks/use-authorization/constants'
import getPage from '@/hooks/use-authorization/get-page'
import routes from '@/hooks/use-authorization/routes'

type UseKolDetailRoute = () =>
  | {
      page: Page.KolId
      params: ReturnType<typeof routes.kolId.useParams>
      searchParams: ReturnType<typeof routes.kolId.useSearchParams>
      setSearchParams: ReturnType<typeof routes.kolId.useSetSearchParams>
      fragmentIdentifier: ReturnType<typeof routes.kolId.useFragmentIdentifier>
      isPublicKolDetailPage: false
    }
  | {
      page: Page.KolSelf
      params: ReturnType<typeof routes.kolSelf.useParams>
      searchParams: ReturnType<typeof routes.kolSelf.useSearchParams>
      setSearchParams: ReturnType<typeof routes.kolSelf.useSetSearchParams>
      fragmentIdentifier: ReturnType<
        typeof routes.kolSelf.useFragmentIdentifier
      >
      isPublicKolDetailPage: false
    }
  | {
      page: Page.KolAgencyId
      params: ReturnType<typeof routes.kolAgencyId.useParams>
      searchParams: ReturnType<typeof routes.kolAgencyId.useSearchParams>
      setSearchParams: ReturnType<typeof routes.kolAgencyId.useSetSearchParams>
      fragmentIdentifier: ReturnType<
        typeof routes.kolAgencyId.useFragmentIdentifier
      >
      isPublicKolDetailPage: false
    }
  | {
      page: Page.PublicKolDetail
      params: ReturnType<typeof routes.publicKolDetail.useParams>
      searchParams: ReturnType<typeof routes.publicKolDetail.useSearchParams>
      setSearchParams: ReturnType<
        typeof routes.publicKolDetail.useSetSearchParams
      >
      fragmentIdentifier: ReturnType<
        typeof routes.publicKolDetail.useFragmentIdentifier
      >
      isPublicKolDetailPage: true
    }

const useKolDetailRoute: UseKolDetailRoute = () => {
  const { pathname } = useRouter()
  const page = getPage(pathname)

  // Page.KolId
  const params = routes.kolId.useParams()
  const searchParams = routes.kolId.useSearchParams()
  const setSearchParams = routes.kolId.useSetSearchParams()
  const fragmentIdentifier = routes.kolId.useFragmentIdentifier()

  // Page.KolSelf
  const paramsForKolSelf = routes.kolSelf.useParams()
  const searchParamsForKolSelf = routes.kolSelf.useSearchParams()
  const setSearchParamsForKolSelf = routes.kolSelf.useSetSearchParams()
  const fragmentIdentifierForKolSelf = routes.kolSelf.useFragmentIdentifier()

  // Page.KolAgencyId
  const paramsForKolAgencyId = routes.kolAgencyId.useParams()
  const searchParamsForKolAgencyId = routes.kolAgencyId.useSearchParams()
  const setSearchParamsForKolAgencyId = routes.kolAgencyId.useSetSearchParams()
  const fragmentIdentifierForKolAgencyId =
    routes.kolAgencyId.useFragmentIdentifier()

  // Page.PublicKolDetail
  const paramsForPublicKolDetail = routes.publicKolDetail.useParams()
  const searchParamsForPublicKolDetail =
    routes.publicKolDetail.useSearchParams()
  const setSearchParamsForPublicKolDetail =
    routes.publicKolDetail.useSetSearchParams()
  const fragmentIdentifierForPublicKolDetail =
    routes.publicKolDetail.useFragmentIdentifier()

  switch (page) {
    case Page.PublicKolDetail:
      return {
        page: Page.PublicKolDetail,
        params: paramsForPublicKolDetail,
        searchParams: searchParamsForPublicKolDetail,
        setSearchParams: setSearchParamsForPublicKolDetail,
        fragmentIdentifier: fragmentIdentifierForPublicKolDetail,
        isPublicKolDetailPage: true,
      }
    case Page.KolSelf:
      return {
        page: Page.KolSelf,
        params: paramsForKolSelf,
        searchParams: searchParamsForKolSelf,
        setSearchParams: setSearchParamsForKolSelf,
        fragmentIdentifier: fragmentIdentifierForKolSelf,
        isPublicKolDetailPage: false,
      }
    case Page.KolAgencyId:
      return {
        page: Page.KolAgencyId,
        params: paramsForKolAgencyId,
        searchParams: searchParamsForKolAgencyId,
        setSearchParams: setSearchParamsForKolAgencyId,
        fragmentIdentifier: fragmentIdentifierForKolAgencyId,
        isPublicKolDetailPage: false,
      }
    default:
      return {
        page: Page.KolId,
        params,
        searchParams,
        setSearchParams,
        fragmentIdentifier,
        isPublicKolDetailPage: false,
      }
  }
}

export default useKolDetailRoute

declare const TPDirect: any

export interface TappayInputSetting {
  cardNumberElementId: string
  expirationDateElement: HTMLElement | null
  cvcElementId: string
}

/**
 * Ref: https://docs.tappaysdk.com/tutorial/zh/reference.html#related-topics228
 */
export enum TappayStatusCode {
  /**
   * 欄位已填好，並且沒有問題
   */
  Success, //
}

interface GetPrimeResult {
  status: TappayStatusCode
  card: {
    prime: string
  }
}

export const tappayApi = {
  init(): void {
    TPDirect.setupSDK(
      process.env.NEXT_PUBLIC_TAPPAY_APP_ID,
      process.env.NEXT_PUBLIC_TAPPAY_APP_KEY,
      process.env.NEXT_PUBLIC_TAPPAY_MODE,
    )
  },
  setup(tappayInputSetting: TappayInputSetting): void {
    const { cardNumberElementId, expirationDateElement, cvcElementId } =
      tappayInputSetting

    TPDirect.card.setup({
      fields: {
        number: {
          element: cardNumberElementId,
          placeholder: '**** **** **** ****',
        },
        expirationDate: {
          element: expirationDateElement,
          placeholder: 'MM / YY',
        },
        ccv: {
          element: cvcElementId,
          placeholder: 'CVC / CVV',
        },
      },
      styles: {
        // '.valid': {
        //   color: '#67c23a'
        // },
        '.invalid': {
          color: '#f56c6c',
        },
      },
    })
  },
  getPrimeResult(): Promise<GetPrimeResult> {
    return new Promise<GetPrimeResult>(function (resolve) {
      TPDirect.card.getPrime((result) => {
        resolve(result)
      })
    })
  },
}

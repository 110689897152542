import { z } from 'zod'
import { unlimitedCountryCodeEnumSchema } from '@/constants/country-code'
import { Gender } from '@/constants/gender'
import { KolSortOption } from '@/constants/search/kol-sort-option'
import { PostSortOption } from '@/constants/search/post-sort-option'
import { SearchMode } from '@/constants/search/search-mode'
import { VerifiedKol } from '@/constants/verified-kol'
import { Page } from '@/hooks/use-authorization/constants'
import { PlatformShortcode } from '@/utils/convert-platform'
import { Potential } from '@/utils/detail/potential'
import makeRoute, { emptySchema } from '@/utils/routes/make-route'

const commonKolAndPostSearchParams = z.object({
  keyword: z.string().optional(),
  platform_type: z.string().optional(),
  customized_tags_names: z.string().optional(),
  follower_start_from: z.coerce.number().optional(),
  follower_end_to: z.coerce.number().optional(),
  post_start_time: z.string().optional(),
  post_end_time: z.string().optional(),
  country_code: unlimitedCountryCodeEnumSchema.optional(),
  filter_collection: z.boolean().optional(),
  filter_cooperation: z.boolean().optional(),
  filter_unrecommended: z.boolean().optional(),
  filter_kol_type: z.string().optional(),
  exclude_kol_collection_folder_ids: z.string().optional(),
})

const kolSearchParamSchema = z
  .object({
    mode: z.literal(SearchMode.Kol).optional().default(SearchMode.Kol),
    sort: z
      .nativeEnum(KolSortOption)
      .optional()
      .default(KolSortOption.Recommend),
    from: z.string().optional(),
    verifiedKol: z.nativeEnum(VerifiedKol).optional(),
    potential: z.nativeEnum(Potential).optional(),
    gender: z.nativeEnum(Gender).optional(),
    avgPvRateBegin: z.coerce.number().optional(),
    avgPvRateEnd: z.coerce.number().optional(),
    interactiveRateBegin: z.coerce.number().optional(),
    interactiveRateEnd: z.coerce.number().optional(),
    followerRangeLevel: z.coerce.number().optional(),
    fansUpRateBegin: z.coerce.number().optional(),
    fansUpRateEnd: z.coerce.number().optional(),
    audienceGenderCode: z.enum(['male', 'female']).optional(),
    audienceGenderRateBegin: z.coerce.number().optional(),
    audienceGenderRateEnd: z.coerce.number().optional(),
    audienceAgeCode: z.string().optional(),
    audienceAgeRateBegin: z.coerce.number().optional(),
    audienceAgeRateEnd: z.coerce.number().optional(),
    audienceGeoCode: z.string().optional(),
    audienceGeoRateBegin: z.coerce.number().optional(),
    audienceGeoRateEnd: z.coerce.number().optional(),
    minQuotationPrice: z.coerce.number().optional(),
    maxQuotationPrice: z.coerce.number().optional(),
    languages: z.array(z.string()).optional(),
    keywordFrom: z.string().optional(),
    anchor: z.string().optional(),
  })
  .merge(commonKolAndPostSearchParams)

const postSearchParamSchema = z
  .object({
    mode: z.literal(SearchMode.Post),
    customized_tags_names: z.string().optional(),
    sort: z
      .nativeEnum(PostSortOption)
      .optional()
      .default(PostSortOption.Default),
  })
  .merge(commonKolAndPostSearchParams)

const similarKolSearchParamSchema = z.object({
  mode: z.literal(SearchMode.SimilarKol),
  similarKolId: z.string(),
  similarKolDcIdHashed: z.string().optional(),
  similarKolPlatform: z.nativeEnum(PlatformShortcode),
  from: z.literal('Search Similar Influencer'),
})

export type KolSearchParam = z.infer<typeof kolSearchParamSchema>
export type PostSearchParam = z.infer<typeof postSearchParamSchema>

export type KolSearchParamKey = keyof KolSearchParam

const searchRoute = makeRoute(
  Page.Search,
  emptySchema,
  z.union([
    kolSearchParamSchema,
    postSearchParamSchema,
    similarKolSearchParamSchema,
  ]),
)

export default searchRoute

import Image from 'next/image'
import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import browserWarningSrc from '@/assets/images/warning/browser.png'
import rwdWarningSrc from '@/assets/images/warning/rwd.png'
import MessageDropdown from '@/components/header/message-dropdown'
import { SearchInputContainer } from '@/components/header/search-keyword'
import IrmFooter, { FooterWrapper } from '@/components/layout/irm/irm-footer'
import IrmHeader, {
  ActionWrapper,
  HeaderWrapper,
  LogoWrapper,
} from '@/components/layout/irm/irm-header'
import { default as PublicPageFooter } from '@/components/public-page/footer'
import { default as PublicPageHeader } from '@/components/public-page/header'
import { WarningType } from '@/components/warning/use-site-warning'
import { useAuth } from '@/hooks/use-auth'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli } from '@/utils/ampli'

interface WarningProps {
  onSkip?: () => void
  type: WarningType
}

const Warning: FunctionComponent<WarningProps> = ({
  onSkip = (): void => {},
  type,
}) => {
  const { formatMessage } = useIntl()
  const { isLogin } = useAuth()

  return (
    <Wrapper>
      {isLogin ? <IrmHeader isCollapsed /> : <PublicPageHeader />}
      <Content>
        {((): ReactNode => {
          switch (type) {
            case 'rwd':
              return (
                <>
                  <TextWrapper>
                    <Title>
                      {formatMessage({ id: 'general:title_warning_rwd' })}
                    </Title>
                    <Desc>
                      {formatMessage({ id: 'general:desc_warning_rwd' })}
                    </Desc>
                    <Button
                      onClick={(): void => {
                        ampli.continueToViewOnMobile()
                        onSkip()
                      }}
                    >
                      {formatMessage({ id: 'general:btn_warning_rwd' })}
                    </Button>
                  </TextWrapper>
                  <ImageWrapper>
                    <Image fill alt='unsupported-rwd' src={rwdWarningSrc} />
                  </ImageWrapper>
                </>
              )
            case 'browser':
              return (
                <>
                  <ImageWrapper>
                    <Image
                      fill
                      alt='unsupported-browser'
                      src={browserWarningSrc}
                    />
                  </ImageWrapper>
                  <TextWrapper>
                    <Title>
                      {formatMessage({ id: 'general:title_warning_browser' })}
                    </Title>
                    <Desc>
                      {formatMessage({ id: 'general:desc_warning_browser' })}
                    </Desc>
                  </TextWrapper>
                </>
              )
            default:
              const _exhaustiveCheck: never = type
              return _exhaustiveCheck
          }
        })()}
      </Content>
      {isLogin ? <IrmFooter isCollapsed /> : <PublicPageFooter />}
    </Wrapper>
  )
}

export default Warning

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4;
  color: ${({ theme }): string => theme.colors.text.primary};

  @media (max-width: 768px) {
    font-size: 24px;
  }
`
const Desc = styled.div`
  font-size: 20px;
  line-height: 1.4;
  color: ${({ theme }): string => theme.colors.text.primary};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`
const Button = styled.div`
  color: ${({ theme }): string => theme.colors.brand.primary};
  cursor: pointer;

  :hover {
    color: ${({ theme }): string => theme.colors.brand.primaryHover};
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: auto;
  aspect-ratio: 335/255;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Title} {
    margin-bottom: 20px;
  }
  ${Desc} {
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    ${Title} {
      margin-bottom: 16px;
    }
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 82px;
  padding: 40px 20px;
  max-width: 1140px;
  min-height: calc(100vh - 82px);
  margin-left: auto;
  margin-right: auto;

  ${TextWrapper} {
    &:first-child {
      margin-right: 40px;
    }
  }
  ${ImageWrapper} {
    &:first-child {
      margin-right: 40px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    margin-top: 62px;

    ${TextWrapper} {
      &:first-child {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
    ${ImageWrapper} {
      &:first-child {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
`

const Wrapper = styled.div`
  background-color: #f8f7f7;

  ${HeaderWrapper} {
    left: 0;
    top: 0;
    width: 100%;

    ${LogoWrapper} {
      display: block;
    }
    ${MessageDropdown},${SearchInputContainer} {
      display: none;
    }
    ${ActionWrapper} {
      a {
        display: none;
      }
    }
  }

  ${FooterWrapper} {
    margin: 0;
    color: ${({ theme }): string => theme.colors.text.secondary};
  }
`

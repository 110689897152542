import * as Sentry from '@sentry/react'
import { ScopeContext } from '@sentry/types/types/scope'
import env from '@/utils/env'

const info = (
  info: unknown,
  extra?: ScopeContext['extra'],
  tags?: ScopeContext['tags'],
): void => {
  if (env.DEVELOPMENT_MODE) {
    console.info({ info, extra, tags })
  } else {
    Sentry.captureException(info, {
      level: 'info',
      extra,
      tags,
    })
  }
}

const error = (
  error: Error,
  extra?: ScopeContext['extra'],
  tags?: ScopeContext['tags'],
): void => {
  if (env.DEVELOPMENT_MODE) {
    console.info({ error, extra, tags })
  } else {
    Sentry.captureException(error, {
      level: 'error',
      extra,
      tags,
    })
  }
}

const logger = {
  info,
  error,
}

export default logger

import {
  api,
  CampaignInvitationResponse,
  CampaignsAPITagType,
  infiniteConfigSetting,
  PartnerAPITagType,
  StringifyRequest,
  UserAPITagType,
} from '@/api/api'
import { PostModel } from '@/components/campaign/kol/add-post-modal/add-post-modal'
import GeneralTagCode from '@/constants/general-tag-code'
import { Campaign } from '@/store/campaign'
import { CampaignCase, RequestUpdateCampaignCase } from '@/types/campaign-case'
import { ApproverType, CampaignKolStatus } from '@/types/mock-api-types'
import { ListResource } from '@/types/resource-type'
import { PlatformShortcode } from '@/utils/convert-platform'
import { camelizeKeys, decamelizeKeys } from '@/utils/humps'

export enum KolReplyAction {
  Accept = 'accept',
  Reject = 'reject',
}

interface CampaignInvitationRequest {
  campaignKolIds: number[]
  action: KolReplyAction
}

export enum FetchCampaignRequestSorting {
  PriceLowToHigh = 'budget_per_person asc',
  PriceHighToLow = 'budget_per_person desc',
  CreateDateNewToOld = 'created_at desc',
  CreateDateOldToNew = 'created_at asc',
  ApplyEndTimeOldToNew = 'apply_end_time asc',
}

interface DeleteCampaignPostRequest {
  campaignId: number
  campaignKolId: number
  postId: number
}

interface AddCampaignPostRequest {
  campaignId: number
  campaignKolId: number
  posts: PostModel[]
}

interface FetchApplyCampaignRequest {
  campaignId: number
  page: number
  perPage?: number
}

interface ApplyCampaignRequest {
  campaignId: number
  kolUUIDs: string[]
}

export interface ApplyCampaignKol {
  name: string[]
  uuid: string
  followerCount: number
  status: CampaignKolStatus | null
  approverType: ApproverType
  appliedTime: string
}

export interface FetchCampaignListRequest extends StringifyRequest {
  page: number
  perPage?: number
  tags?: GeneralTagCode[]
  keyword?: string
  platform?: PlatformShortcode
  lowerPrice?: number
  upperPrice?: number
  startAt?: string
  endAt?: string
  sortBy: FetchCampaignRequestSorting[]
}

interface AcceptApplicationRequest {
  campaignId: number
  campaignKolId: number
  token: string
}

interface AcceptApplicationResponse {
  isUpdated: boolean
}

const campaignsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateCampaignInvitation: builder.mutation<
      CampaignInvitationResponse,
      CampaignInvitationRequest
    >({
      query: (params) => {
        return {
          url: `/campaign-invitation-actions`,
          body: decamelizeKeys(params),
          method: 'POST',
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as CampaignInvitationResponse
      },
      invalidatesTags: [PartnerAPITagType.CampaignList],
    }),
    updateCampaignMailInvitation: builder.mutation<
      CampaignCase,
      RequestUpdateCampaignCase
    >({
      query: ({ campaignID, campaignKolID, token, params }) => {
        return {
          url: `/campaigns/${campaignID}/campaign-kols/${campaignKolID}/accept`,
          body: decamelizeKeys(params),
          method: 'PATCH',
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as CampaignCase
      },
      invalidatesTags: [PartnerAPITagType.CampaignList],
    }),
    fetchCaseList: builder.query<
      ListResource<Campaign>,
      FetchCampaignListRequest
    >({
      query: (request) => {
        return {
          url: '/campaigns',
          params: decamelizeKeys(request),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as ListResource<Campaign>
      },
      providesTags: [CampaignsAPITagType.CampaignList],
    }),
    deleteCampaignPost: builder.mutation<void, DeleteCampaignPostRequest>({
      query: ({ campaignId, campaignKolId, postId }) => {
        return {
          url: `/campaigns/${campaignId}/campaign-kols/${campaignKolId}/campaign-kol-posts/${postId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [PartnerAPITagType.CampaignList],
    }),
    addCampaignPost: builder.mutation<void, AddCampaignPostRequest>({
      query: ({ campaignId, campaignKolId, posts }) => {
        return {
          url: `/campaigns/${campaignId}/campaign-kols/${campaignKolId}/campaign-kol-posts`,
          method: 'POST',
          body: { posts: decamelizeKeys(posts) },
        }
      },
      invalidatesTags: [PartnerAPITagType.CampaignList],
    }),
    applyCampaign: builder.mutation<void, ApplyCampaignRequest>({
      query: ({ campaignId, kolUUIDs }) => {
        return {
          url: `/campaigns/${campaignId}/applied-campaigns`,
          method: 'POST',
          body: decamelizeKeys({ kolUUIDs }),
        }
      },
      invalidatesTags: [
        PartnerAPITagType.CampaignList,
        CampaignsAPITagType.CampaignList,
        CampaignsAPITagType.ApplyCampaignKolList,
        UserAPITagType.PubNubAccessToken,
      ],
    }),
    fetchApplyCampaignKolList: builder.query<
      ListResource<ApplyCampaignKol>,
      FetchApplyCampaignRequest
    >({
      query: ({ campaignId, ...params }) => {
        return {
          url: `/campaigns/${campaignId}/applied-campaigns`,
          method: 'GET',
          params: decamelizeKeys(params),
        }
      },
      ...infiniteConfigSetting<
        FetchApplyCampaignRequest,
        ListResource<ApplyCampaignKol>
      >(),
      transformResponse: (response: object) => {
        return camelizeKeys(
          response,
        ) as unknown as ListResource<ApplyCampaignKol>
      },
      providesTags: [CampaignsAPITagType.ApplyCampaignKolList],
    }),
    acceptApplication: builder.mutation<
      AcceptApplicationResponse,
      AcceptApplicationRequest
    >({
      query: ({ campaignId, campaignKolId, token }) => {
        return {
          url: `/campaigns/${campaignId}/campaign-kols/${campaignKolId}/accept-application`,
          method: 'PATCH',
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as AcceptApplicationResponse
      },
    }),
  }),
  overrideExisting: false,
})

export const useUpdateCampaignInvitationMutation =
  campaignsApi.endpoints.updateCampaignInvitation.useMutation
export const useUpdateCampaignMailInvitationMutation =
  campaignsApi.endpoints.updateCampaignMailInvitation.useMutation
export const useFetchCaseListQuery =
  campaignsApi.endpoints.fetchCaseList.useQuery
export const useDeleteCampaignPostMutation =
  campaignsApi.endpoints.deleteCampaignPost.useMutation
export const useAddCampaignPostMutation =
  campaignsApi.endpoints.addCampaignPost.useMutation
export const useApplyCampaignMutation =
  campaignsApi.endpoints.applyCampaign.useMutation
export const useFetchApplyCampaignKolListQuery =
  campaignsApi.endpoints.fetchApplyCampaignKolList.useQuery
export const useAcceptApplicationMutation =
  campaignsApi.endpoints.acceptApplication.useMutation

import * as Sentry from '@sentry/react'
import React, { ReactNode, FunctionComponent, ReactElement } from 'react'
import ErrorPage from '@/pages/_error'

interface RootErrorBoundaryProps {
  children: ReactNode
}

const RootErrorBoundary: FunctionComponent<RootErrorBoundaryProps> = ({
  children,
}) => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope): void => {
        scope.setTag('cosmos', true)
      }}
      fallback={({ resetError }): ReactElement => (
        <ErrorPage onReset={resetError} />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default RootErrorBoundary

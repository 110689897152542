export const parseAsNumber = (
  value?: string | number | boolean | null,
  defaults = 0,
): number => {
  if (typeof value === 'number') {
    return value
  }

  if (!value) {
    return defaults
  }

  const newValue = parseInt(String(value), 10)
  return Number.isNaN(newValue) ? defaults : newValue
}

import humps, {
  HumpsOptions,
  HumpsProcessor,
  HumpsProcessorParameter,
  OptionOrProcessor,
} from 'humps'

const decamelizeKeysHumpsProcessor: HumpsProcessor = (
  key: string,
  convert: HumpsProcessorParameter,
  options?: HumpsOptions,
): string => {
  switch (key) {
    case 'iconUUID':
      return 'icon_uuid'
    case 'productIconUUID':
      return 'product_icon_uuid'
    case 'kolUUIDs':
      return 'kol_uuids'
    case 'kolUUID':
      return 'kol_uuid'
    case 'KolUUIDs':
      return 'kol_uuids'
    case 'taxID':
      return 'tax_id'
    default:
      return convert(key, options)
  }
}

const camelizeKeysHumpsProcessor: HumpsProcessor = (
  key: string,
  convert: HumpsProcessorParameter,
  options?: HumpsOptions,
) => {
  switch (key) {
    case 'FB':
      return 'fb'
    case 'YT':
      return 'yt'
    case 'IG':
      return 'ig'
    case 'TIKTOK':
      return 'tiktok'
    case 'ID':
      return 'id'
    case 'tax_id':
      return 'taxID'
    case 'UUID':
      return 'UUID'
    case 'kol_uuid':
      return 'kolUUID'
    case 'URL':
      return 'url'
    case 'PCU':
      return 'pcu'
    case 'PCUValue':
      return 'pcuValue'
    case 'permanent_url':
      return 'permanentURL'
    default:
      return convert(key, options)
  }
}

export const decamelizeKeys = (
  objects: object | object[],
  optionsOrProcessor: OptionOrProcessor = decamelizeKeysHumpsProcessor,
): object | object[] => {
  return humps.decamelizeKeys(objects, optionsOrProcessor)
}

export const camelizeKeys = (
  objects: object | object[],
  optionsOrProcessor: OptionOrProcessor = camelizeKeysHumpsProcessor,
): object | object[] => {
  return humps.camelizeKeys(objects, optionsOrProcessor)
}

import { z } from 'zod'
import { priceRangeSchema } from '@/types/schema/kol/price-range-schema'

/**
 * @description: 預估合作費用
 */
export const quotationsSchema = z.object({
  post: priceRangeSchema,
  video: priceRangeSchema,
})

import { gtagApi } from '@/api/gtag/gtag-api'
import { UserType } from '@/hooks/use-authorization/constants'
import { AccountCreatedAdProperties, ampli } from '@/utils/ampli'
import env from '@/utils/env'

interface SendIndividualAccountCreatedEvent {
  (props: {
    type: AccountCreatedAdProperties['type']
    userType: UserType
    ssoPlatform?: string
    from?: string
  }): void
}

const sendIndividualAccountCreatedEvent: SendIndividualAccountCreatedEvent = ({
  type,
  userType,
  ssoPlatform,
  from,
}): void => {
  const eventProperties = {
    type,
    ssoPlatform,
    from,
  }

  switch (userType) {
    case UserType.NormalAd:
      ampli.accountCreatedAd(eventProperties)
      gtagApi.sendGoogleAdsEvent(env.GOOGLE_ADS_ACCOUNT_CREATED_EVENT_ID)
      break
    case UserType.Agency:
      ampli.accountCreatedKolAgency(eventProperties)
      break
    case UserType.NormalKol:
      ampli.accountCreatedKol(eventProperties)
      break
    case UserType.Untyped:
      break
  }
}

export default sendIndividualAccountCreatedEvent

import dayjs from 'dayjs'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useIntl } from '@/i18n/hooks/use-intl'

const MINIMUM_REMAINING_APPLY_DAYS = 10

interface RemainingApplyDaysProps {
  className?: string
  prefix?: string
  applyEndTime: string
  suffix?: string
}

const RemainingApplyDays: FunctionComponent<RemainingApplyDaysProps> = ({
  className,
  prefix,
  applyEndTime,
  suffix,
}) => {
  const { formatMessage } = useIntl()

  const remainingApplyDays = dayjs(applyEndTime).diff(dayjs(), 'day')

  if (remainingApplyDays > MINIMUM_REMAINING_APPLY_DAYS) {
    return null
  }

  return (
    <Paragraph className={className}>
      {prefix}
      {formatMessage(
        { id: 'kol:campaign_detail_remaining_apply_day' },
        { remainingApplyDays },
      )}
      {suffix}
    </Paragraph>
  )
}

const Paragraph = styled.p`
  color: ${({ theme }): string => theme.colors.brand.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;

  @media (min-width: 768px) {
    flex-grow: 1;
    text-align: right;
  }
`

export default styled(RemainingApplyDays)``

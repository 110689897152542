import { useRouter } from 'next/router'
import { Page } from '@/hooks/use-authorization/constants'
import getPage from '@/hooks/use-authorization/get-page'

const useIsPublicDetailPage = (): boolean => {
  const { pathname } = useRouter()
  const page = getPage(pathname)

  return Page.PublicKolDetail === page
}

export default useIsPublicDetailPage

import { ImsSchema } from '@/types/schema/imscschema'

export enum KolUserIdentityType {
  KOL = 'kol',
  RadarStarKOL = 'radar-star-kol',
  Agency = 'agency',
  RadarStarAgency = 'radar-star-agency',
  AgencyKOL = 'agency-kol',
  RadarStarAgencyKOL = 'radar-star-agency-kol',
}

export interface AgentSchema {
  companyName: string
  companyAddr: string
  agencyName: string
  agencyEmail: string
  agencyPhone: string
  ims: ImsSchema[]
  id: string
  taxID: string
  kolUserIdentityType: KolUserIdentityType
}

import { noop } from 'lodash-es'
import { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { AppContext } from '@/api/executor/app-context'
import { invalidateUserInfoAction } from '@/api/user-api'
import { Icon } from '@/components/custom-icon'
import { useAuth } from '@/hooks/use-auth'
import { useI18n } from '@/hooks/use-i18n'
import useIsPublicDetailPage from '@/hooks/use-is-public-detail-page'
import { SupportedLanguages } from '@/i18n/config'
import { languages } from '@/i18n/languages'
import { ampli } from '@/utils/ampli'
import { goToPage } from '@/utils/routes/go-to-page'

export const LangSelect: FunctionComponent = () => {
  const { language } = useI18n()
  const dispatch = useDispatch()
  const { userInfo } = useAuth()
  const [lang, setLang] = useState(language?.i18nCode)
  const isPublicKolDetailPage = useIsPublicDetailPage()
  const selectLanguage = (e): void => {
    const selectedLang = e.target.value as SupportedLanguages
    if (userInfo) {
      AppContext.ApiExecutor.updateUser({ language: selectedLang })
        .then(() => {
          setLang(selectedLang)
          dispatch(invalidateUserInfoAction)

          ampli.changeLanguage({ language: selectedLang })
          return noop()
        })
        .catch((error) => console.warn(error))
    } else {
      const path = isPublicKolDetailPage
        ? `${location.origin}${location.pathname}`
        : location.pathname
      goToPage(`${path}?lang=${selectedLang}`).then(noop)
    }
  }

  useEffect(() => {
    setLang(language?.i18nCode)
  }, [language])

  return (userInfo ?? language) ? (
    <>
      <Icon style={{ fontSize: '16px' }} type='elIconGlobal' />
      <LangSelectWrapper value={lang} onChange={selectLanguage}>
        {languages.map((language, index) => (
          <option key={index} value={language.code}>
            {language.label}
          </option>
        ))}
      </LangSelectWrapper>
    </>
  ) : null
}

const LangSelectWrapper = styled.select`
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme }): string => theme.colors.text.white};
  height: 25px;
  margin: 20px 0 10px 5px;
`

import { IconNames, theme } from '@buggy/shared'
import { isNil, values } from 'lodash-es'
import { createEnumParam } from 'use-query-params'
import { I18nId } from '@/i18n/config'
import { Nullable } from '@/types/nullable'

export enum Potential {
  High = 'high',
  Normal = 'normal',
  Decline = 'decline',
  Any = 'any',
}

export const PotentialParam = createEnumParam(values(Potential))

export const POTENTIAL_OPTIONS = [
  {
    value: Potential.Any,
    label: 'search:filter_platform_any',
  },
  {
    value: Potential.High,
    label: 'kol:field_potential_high',
  },
  {
    value: Potential.Normal,
    label: 'kol:field_potential_normal',
  },
  {
    value: Potential.Decline,
    label: 'kol:field_potential_decline',
  },
] as const

interface PotentialStyledProps {
  color: string
  iconName: IconNames
}

export const POTENTIAL_STYLED_MAP: Record<
  Potential.High | Potential.Normal | Potential.Decline,
  PotentialStyledProps
> = {
  [Potential.High]: {
    color: theme.colors.level1,
    iconName: 'trending-up',
  },
  [Potential.Normal]: {
    color: theme.colors.level3,
    iconName: 'trending-plateau',
  },
  [Potential.Decline]: {
    color: theme.colors.level4,
    iconName: 'trending-down',
  },
} as const

export function getPotentialI18n(potential: undefined): undefined
export function getPotentialI18n(potential: Nullable<Potential>): I18nId
export function getPotentialI18n(
  potential: Nullable<Potential> | undefined,
): I18nId | undefined {
  if (isNil(potential)) {
    return undefined
  }

  return (
    POTENTIAL_OPTIONS.find(
      (potentialOption) => potentialOption.value === potential,
    )?.label ?? 'search:filter_platform_any'
  )
}

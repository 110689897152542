/*! thanks to fontawesome, see https://fontawesome.com/license */
import AntdIcon, {
  AppstoreOutlined,
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DollarOutlined,
  DoubleRightOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  KeyOutlined,
  LeftOutlined,
  LikeOutlined,
  LockOutlined,
  MessageOutlined,
  MoreOutlined,
  PaperClipOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
  RightOutlined,
  RiseOutlined,
  SearchOutlined,
  SettingOutlined,
  StarOutlined,
  SyncOutlined,
  TableOutlined,
  TagOutlined,
  UnlockOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import React, { DOMAttributes, FunctionComponent } from 'react'
import styled from 'styled-components'
import chevronDownSolid from '@/components/icon/fa-svg/chevron-down-solid.svg'
import chevronLeftSolid from '@/components/icon/fa-svg/chevron-left-solid.svg'
import chevronRightSolid from '@/components/icon/fa-svg/chevron-right-solid.svg'
import chevronUpSolid from '@/components/icon/fa-svg/chevron-up-solid.svg'
import circleCheckSolid from '@/components/icon/fa-svg/circle-check-solid.svg'
import circleXmarkSolid from '@/components/icon/fa-svg/circle-xmark-solid.svg'
import filmSolid from '@/components/icon/fa-svg/film-solid.svg'
import alertCircle from '@/components/icon/feather/alert-circle.svg'
import chevronDown from '@/components/icon/feather/chevron-down.svg'
import featherDownload from '@/components/icon/feather/download.svg'
import helpCircle from '@/components/icon/feather/help-circle.svg'
import info from '@/components/icon/feather/info.svg'
import featherLock from '@/components/icon/feather/lock.svg'
import logOut from '@/components/icon/feather/log-out.svg'
import moreHorizontal from '@/components/icon/feather/more-horizontal.svg'
import thumbsDown from '@/components/icon/feather/thumbs-down.svg'
import thumbsUp from '@/components/icon/feather/thumbs-up.svg'
import trendingUp from '@/components/icon/feather/trending-up.svg'
import userMinus from '@/components/icon/feather/user-minus.svg'
import userPlus from '@/components/icon/feather/user-plus.svg'
import arrowLeftCircle from '@/components/icon/own-svg/arrow-left-circle.svg'
import arrowRightCircle from '@/components/icon/own-svg/arrow-right-circle.svg'
import elIconBriefcaseOriginal from '@/components/icon/own-svg/briefcase-original.svg'
import elIconBriefcase from '@/components/icon/own-svg/briefcase.svg'
import btnYouTube from '@/components/icon/own-svg/btn-youtube.svg'
import bullhorn2 from '@/components/icon/own-svg/bullhorn2.svg'
import bullhorn from '@/components/icon/own-svg/bullhorn.svg'
import caseIcon from '@/components/icon/own-svg/case.svg'
import elIcon404 from '@/components/icon/own-svg/el-icon-404.svg'
import elIconAdd from '@/components/icon/own-svg/el-icon-add.svg'
import elIconAnalytics from '@/components/icon/own-svg/el-icon-analytics.svg'
import elIconArrowForwardOutline from '@/components/icon/own-svg/el-icon-arrow-forward-outline.svg'
import elIconArrowRight from '@/components/icon/own-svg/el-icon-arrow-right.svg'
import elIconBadgeWhiteBg from '@/components/icon/own-svg/el-icon-badge-white-bg.svg'
import elIconBadge from '@/components/icon/own-svg/el-icon-badge.svg'
import elIconBarChart from '@/components/icon/own-svg/el-icon-bar-chart.svg'
import elIconBlogger from '@/components/icon/own-svg/el-icon-blogger.svg'
import elIconBookmarkOutline from '@/components/icon/own-svg/el-icon-bookmark-outline.svg'
import elIconBookmark from '@/components/icon/own-svg/el-icon-bookmark.svg'
import elIconBullhorn from '@/components/icon/own-svg/el-icon-bullhorn.svg'
import elIconCast from '@/components/icon/own-svg/el-icon-cast.svg'
import elIconCheckListThin from '@/components/icon/own-svg/el-icon-check-list-thin.svg'
import elIconCheck from '@/components/icon/own-svg/el-icon-check.svg'
import elIconChevronsDown from '@/components/icon/own-svg/el-icon-chevrons-down.svg'
import elIconChevronsUp from '@/components/icon/own-svg/el-icon-chevrons-up.svg'
import elIconCircleClose from '@/components/icon/own-svg/el-icon-circle-close.svg'
import elIconClose from '@/components/icon/own-svg/el-icon-close.svg'
import elIconCompany from '@/components/icon/own-svg/el-icon-company.svg'
import elIconCompass from '@/components/icon/own-svg/el-icon-compass.svg'
import elIconConversation from '@/components/icon/own-svg/el-icon-conversation.svg'
import elIconCooperationEvent from '@/components/icon/own-svg/el-icon-cooperation-event.svg'
import elIconCooperationLive from '@/components/icon/own-svg/el-icon-cooperation-live.svg'
import elIconCooperationPost from '@/components/icon/own-svg/el-icon-cooperation-post.svg'
import elIconCooperationVideo from '@/components/icon/own-svg/el-icon-cooperation-video.svg'
import elIconCopy from '@/components/icon/own-svg/el-icon-copy.svg'
import elIconCost from '@/components/icon/own-svg/el-icon-cost.svg'
import elIconCrownOutline from '@/components/icon/own-svg/el-icon-crown-outline.svg'
import elIconCrown from '@/components/icon/own-svg/el-icon-crown.svg'
import elIconCs from '@/components/icon/own-svg/el-icon-cs.svg'
import elIconData from '@/components/icon/own-svg/el-icon-data.svg'
import elIconDown from '@/components/icon/own-svg/el-icon-down.svg'
import elIconEmail from '@/components/icon/own-svg/el-icon-email.svg'
import elIconExternalLink from '@/components/icon/own-svg/el-icon-external-link.svg'
import elIconEye from '@/components/icon/own-svg/el-icon-eye.svg'
import elIconFacebook from '@/components/icon/own-svg/el-icon-facebook-brand.svg'
import elIconFacebookWhite from '@/components/icon/own-svg/el-icon-facebook-white.svg'
import elIconFileText from '@/components/icon/own-svg/el-icon-file-text.svg'
import elIconFinish from '@/components/icon/own-svg/el-icon-finish.svg'
import elIconGlobal from '@/components/icon/own-svg/el-icon-global.svg'
import elIconGlobe from '@/components/icon/own-svg/el-icon-globe.svg'
import elIconGoogle from '@/components/icon/own-svg/el-icon-google.svg'
import elIconInstagramWhite from '@/components/icon/own-svg/el-icon-instagram-white.svg'
import elIconInstagram from '@/components/icon/own-svg/el-icon-instagram.svg'
import elIconKey from '@/components/icon/own-svg/el-icon-key.svg'
import elIconLayout1 from '@/components/icon/own-svg/el-icon-layout1.svg'
import elIconLayout2 from '@/components/icon/own-svg/el-icon-layout2.svg'
import elIconList from '@/components/icon/own-svg/el-icon-list.svg'
import elIconMessageCircle from '@/components/icon/own-svg/el-icon-message-circle.svg'
import elIconNoData from '@/components/icon/own-svg/el-icon-nodata.svg'
import openMouth from '@/components/icon/own-svg/el-icon-open-mouth.svg'
import elIconPhone from '@/components/icon/own-svg/el-icon-phone.svg'
import elIconPlurk from '@/components/icon/own-svg/el-icon-plurk-brand.svg'
import elIconPlusSquare from '@/components/icon/own-svg/el-icon-plus-square.svg'
import elIconPremium from '@/components/icon/own-svg/el-icon-premium.svg'
import elIconPriceTags from '@/components/icon/own-svg/el-icon-price-tags.svg'
import elIconPrint from '@/components/icon/own-svg/el-icon-print.svg'
import elIconResume from '@/components/icon/own-svg/el-icon-resume.svg'
import elIconSelected from '@/components/icon/own-svg/el-icon-selected.svg'
import elIconSetting from '@/components/icon/own-svg/el-icon-settings.svg'
import elIconSignup from '@/components/icon/own-svg/el-icon-signup.svg'
import elIconSinaweibo from '@/components/icon/own-svg/el-icon-sinaweibo-brand.svg'
import elIconSolution from '@/components/icon/own-svg/el-icon-solution.svg'
import elIconSort from '@/components/icon/own-svg/el-icon-sort.svg'
import elIconStartup from '@/components/icon/own-svg/el-icon-startup.svg'
import elIconSurvey from '@/components/icon/own-svg/el-icon-survey.svg'
import elIconTarget from '@/components/icon/own-svg/el-icon-target.svg'
import elIconThreads from '@/components/icon/own-svg/el-icon-threads.svg'
import elIconThumbsUp from '@/components/icon/own-svg/el-icon-thumbs-up.svg'
import elIconTiktok from '@/components/icon/own-svg/el-icon-tiktok.svg'
import elIconTwitch from '@/components/icon/own-svg/el-icon-twitch-brand.svg'
import elIconTwitter from '@/components/icon/own-svg/el-icon-twitter-brand.svg'
import elIconUpload from '@/components/icon/own-svg/el-icon-upload.svg'
import elIconUserChecked from '@/components/icon/own-svg/el-icon-user-checked.svg'
import elIconUserPlus from '@/components/icon/own-svg/el-icon-user-plus.svg'
import elIconUsers from '@/components/icon/own-svg/el-icon-users.svg'
import elIconWarning from '@/components/icon/own-svg/el-icon-warning.svg'
import elIconYoutube from '@/components/icon/own-svg/el-icon-youtube-brand.svg'
import elPayment from '@/components/icon/own-svg/el-payment.svg'
import elReport from '@/components/icon/own-svg/el-report.svg'
import elIconFacebookDeactivate from '@/components/icon/own-svg/facebook-deactivate.svg'
import fbCorner from '@/components/icon/own-svg/fb-corner.svg'
import filterIcon from '@/components/icon/own-svg/filter.svg'
import flag from '@/components/icon/own-svg/flag.svg'
import igCorner from '@/components/icon/own-svg/ig-corner.svg'
import elIconInstagramDeactivate from '@/components/icon/own-svg/instagram-deactivate.svg'
import jpFlag from '@/components/icon/own-svg/jp.svg'
import moreVertical from '@/components/icon/own-svg/more-vertical.svg'
import play from '@/components/icon/own-svg/play.svg'
import product from '@/components/icon/own-svg/product.svg'
import radarStarBadge from '@/components/icon/own-svg/radar-star-badge.svg'
import rateIcon from '@/components/icon/own-svg/rate.svg'
import saveToCollection from '@/components/icon/own-svg/save-to-collection.svg'
import savedToCollection from '@/components/icon/own-svg/saved-to-collection.svg'
import selected from '@/components/icon/own-svg/selected.svg'
import send from '@/components/icon/own-svg/send.svg'
import tableIcon from '@/components/icon/own-svg/table.svg'
import targetIcon from '@/components/icon/own-svg/target.svg'
import thFlag from '@/components/icon/own-svg/th.svg'
import elIconTiktokDeactivate from '@/components/icon/own-svg/tiktok-deactivate.svg'
import top1 from '@/components/icon/own-svg/top1.svg'
import top2 from '@/components/icon/own-svg/top2.svg'
import top3 from '@/components/icon/own-svg/top3.svg'
import twFlag from '@/components/icon/own-svg/tw.svg'
import elIconTwitterDeactivate from '@/components/icon/own-svg/twitter-deactivate.svg'
import usFlag from '@/components/icon/own-svg/us.svg'
import elIconYoutubeDeactivate from '@/components/icon/own-svg/youtube-deactivate.svg'
import ytCorner from '@/components/icon/own-svg/yt-corner.svg'

/**
 * 基於 Antd.Icon，根據官方接口，擴充卡匣
 *
 * ok with `antd@^3.9.0`
 *
 * see https://ant.design/components/icon-cn/#API
 */
const IconComponents = {
  // custom icon section //
  product,
  elIconSort,
  bullhorn,
  circleCheckSolid,
  elIconCheckListThin,
  radarStarBadge,
  elIconBadge,
  elIconBadgeWhiteBg,
  elIconCrown,
  elIconCs,
  elIconBlogger,
  elIconPlurk,
  elIconSinaweibo,
  elIconFacebook,
  elIconTwitch,
  elIconTwitter,
  elIconThreads,
  elIconYoutube,
  filmSolid,
  elIconInstagram,
  elIconTiktok,
  elIconKey,
  elIconSignup,
  elIconCompany,
  caseIcon,
  elIconData,
  elIconTarget,
  elIconSolution,
  elIconConversation,
  elIconStartup,
  elIconAnalytics,
  elIcon404,
  elIconPremium,
  elIconCrownOutline,
  elIconArrowForwardOutline,
  elIconFacebookWhite,
  elIconGoogle,
  elIconBullhorn,
  elIconPriceTags,
  elIconNoData,
  elIconCooperationPost,
  elIconCooperationVideo,
  elIconCooperationLive,
  elIconCooperationEvent,
  elIconCost,
  elIconResume,
  elIconSurvey,
  saveToCollection,
  savedToCollection,
  elIconYoutubeDeactivate,
  elIconTiktokDeactivate,
  elIconInstagramDeactivate,
  elIconFacebookDeactivate,
  elIconTwitterDeactivate,
  tableIcon,
  elPayment, // payment-outlined
  elReport,
  selected,
  top1,
  top2,
  top3,
  fbCorner,
  igCorner,
  ytCorner,
  jpFlag,
  thFlag,
  twFlag,
  usFlag,
  btnYouTube,
  bullhorn2,
  circleXmarkSolid,
  openMouth,
  /// custom icon section end //

  // feather icon section //
  info,
  'more-horizontal': moreHorizontal,
  'thumbs-down': thumbsDown,
  'thumbs-up': thumbsUp,
  'feather-download': featherDownload,
  'user-minus': userMinus,
  'user-plus': userPlus,
  'alert-circle': alertCircle,
  'more-vertical': moreVertical,
  'help-circle': helpCircle,
  'trending-up': trendingUp,
  featherLock: featherLock,
  rateIcon, //feather-name: activity
  elIconUserChecked, // feather-name: user-check
  filterIcon, // feather-name: sliders
  targetIcon, //feather-name: target
  elIconUserPlus, // feather-name: user-plus
  elIconExternalLink, // feather-name: external-link
  elIconUsers, // feather-name: users
  elIconSetting, // feather-name: settings
  elIconLayout1, // feather-name: grid
  elIconLayout2, // feather-name: list
  elIconList, // feather-name: list
  elIconThumbsUp, // feather-name: thumbs-up
  elIconEye, // feather-name: eye
  elIconBarChart, // feather-name: bar-chart-2
  elIconArrowRight, // feather-name: chevron-right
  elIconPhone, // feather-name: phone
  elIconFinish, // feather-name: smile
  elIconCast, // feather-name: cast
  elIconAdd, // feather-name: plus
  arrowRightCircle, // feather-name: arrow-right-circle
  elIconBookmark, // feather-name: bookmark
  elIconBookmarkOutline, // feather-name: bookmark
  elIconUpload, // feather-name: upload
  arrowLeftCircle, // feather-name: arrow-left-circle
  elIconPrint, // feather-name: printer
  elIconBriefcase, // feather-name: briefcase
  elIconBriefcaseOriginal, // feather-name: briefcase
  elIconGlobal, // feather-name: globe
  elIconGlobe, // feather-name: globe
  elIconDown, // feather-name: chevron-down
  elIconSelected, // feather-name: check
  elIconCheck, // feather-name: check
  elIconClose, // feather-name: x
  flag, // feather-name: flag
  play, // feather-name: play
  elIconCopy, // feather-name: copy
  chevronDownSolid, // feather-name: chevron-down
  elIconChevronsDown, // feather-name: chevrons-down
  chevronLeftSolid, // feather-name: chevron-left
  chevronRightSolid, // feather-name: chevron-right
  chevronUpSolid, // feather-name: chevron-up
  elIconChevronsUp, // feather-name: chevrons-up
  send, // feather-name: send
  elIconCircleClose, // feather-name: x-circle
  elIconCompass, // feather-name: compass
  elIconPlusSquare, // feather-name: plus-square
  elIconInstagramWhite, // feather-name: instagram
  elIconEmail, // feather-name: mail
  elIconWarning, // feather-name: alert-circle
  elIconMessageCircle, // feather-name: message-circle
  elIconFileText, // feather-name: file-text
  'chevron-down': chevronDown,
  'log-out': logOut,
  // feather icon section end //

  // antd icon design section //
  plus: PlusOutlined,
  down: DownOutlined,
  right: RightOutlined,
  search: SearchOutlined,
  'info-circle': InfoCircleOutlined,
  edit: EditOutlined,
  more: MoreOutlined,
  close: CloseOutlined,
  'exclamation-circle': ExclamationCircleOutlined,
  like: LikeOutlined,
  message: MessageOutlined,
  'clock-circle': ClockCircleOutlined,
  eye: EyeOutlined,
  delete: DeleteOutlined,
  dollar: DollarOutlined,
  'paper-clip': PaperClipOutlined,
  download: DownloadOutlined,
  'close-circle': CloseCircleOutlined,
  table: TableOutlined,
  'check-circle': CheckCircleOutlined,
  rise: RiseOutlined,
  home: HomeOutlined,
  user: UserOutlined,
  key: KeyOutlined,
  appstore: AppstoreOutlined,
  calculator: CalculatorOutlined,
  calendar: CalendarOutlined,
  'arrow-up': ArrowUpOutlined,
  'arrow-down': ArrowDownOutlined,
  lock: LockOutlined,
  left: LeftOutlined,
  'question-circle': QuestionCircleOutlined,
  'double-right': DoubleRightOutlined,
  setting: SettingOutlined,
  unlock: UnlockOutlined,
  sync: SyncOutlined,
  star: StarOutlined,
  'plus-square': PlusSquareOutlined,
  check: CheckOutlined,
  'arrow-right': ArrowRightOutlined,
  environment: EnvironmentOutlined,
  tag: TagOutlined,
}

export type OurIconType = keyof typeof IconComponents

export interface IconProps
  extends Partial<CustomIconComponentProps>,
    DOMAttributes<HTMLSpanElement> {
  type: OurIconType
}

export const Icon: FunctionComponent<IconProps> = (props) => {
  const { type, ...iconProps } = props
  return (
    <AntdIcon
      component={
        IconComponents[type] as React.ForwardRefExoticComponent<unknown>
      }
      {...iconProps}
    />
  )
}

export const FeatherIcon = styled(Icon)`
  svg {
    fill: none;
  }
`

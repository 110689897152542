import Script from 'next/script'
import React, { FunctionComponent } from 'react'

const TaboolaPixelScript: FunctionComponent = () => {
  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `
            window._tfa = window._tfa || [];
            window._tfa.push({notify: 'event', name: 'page_view', id: 1735625});
            !function (t, f, a, x) {
              if (!document.getElementById(x)) {
                t.async = 1; t.src = a; t.id=x; f.parentNode.insertBefore(t, f);
              }
            }(document.createElement('script'),
            document.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/unip/1735625/tfa.js',
            'tb_tfa_script');
          `,
      }}
      id='taboola-pixel'
      strategy='afterInteractive'
    />
  )
}

export default TaboolaPixelScript

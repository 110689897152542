import { OurIconType } from '@/components/custom-icon'
import { usePermissions } from '@/hooks/use-permissions'
import { useIntl } from '@/i18n/hooks/use-intl'
import { getThumbnailBadgeInfo } from '@/utils/get-badge-info'

interface ThumbnailBageInfo {
  hasBadge: boolean
  toolTipTitle?: string
  badgeType?: OurIconType
  isInternalRadarStar: boolean
}
const useThumbnailAndNameBadgeInfo = (
  isVerified: boolean,
  isRadarStar: boolean,
): ThumbnailBageInfo => {
  const { formatMessage } = useIntl()
  const { withInternalPermission, withKolPermission } = usePermissions()
  const { hasBadge, toolTipTitleKey, badgeType, isInternalRadarStar } =
    getThumbnailBadgeInfo(
      isVerified,
      isRadarStar,
      withInternalPermission,
      withKolPermission,
    )

  return {
    hasBadge: hasBadge,
    toolTipTitle: !!toolTipTitleKey
      ? formatMessage({ id: toolTipTitleKey })
      : undefined,
    badgeType: badgeType,
    isInternalRadarStar: isInternalRadarStar,
  }
}

export default useThumbnailAndNameBadgeInfo

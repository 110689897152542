import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import AsyncImage from '@/components/common/async-image'

interface ContentProps {
  children: ReactNode
}

export const Content: FunctionComponent<ContentProps> = ({ children }) => {
  return (
    <ContentWrapper>
      <DataWrapper>{children}</DataWrapper>
      <UnderLine />
    </ContentWrapper>
  )
}

export const CaseWrapper = styled.div<{ isLast: boolean; isHead: boolean }>`
  background: ${({ theme }): string => theme.colors.background.white};
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-top: ${({ isHead }): string =>
    isHead ? '1px solid #f0f0f0' : 'none'};
  border-bottom: ${({ isLast }): string =>
    isLast ? '1px solid #f0f0f0' : 'none'};
  box-shadow: ${({ isLast }): string =>
    isLast ? '0 2px 2px rgba(0, 0, 0, 0.102)' : ''};
  margin: ${({ isLast }): string => (isLast ? '0 0 30px 0' : '')};
`

export const CaseTitle = styled.div`
  text-align: center;
  background: #fafafa;
  padding: 8px 12px;
  border-bottom: 1px solid #f0f0f0;
`

const ContentWrapper = styled.div`
  padding: 12px 12px 0;
`

const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const UnderLine = styled.div`
  margin: 12px 0 0 0;
  border-bottom: 1px solid #f0f0f0;
`

export const ClickableContent = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

export const MessageWrapper = styled.div`
  cursor: pointer;
  color: ${({ theme }): string => theme.colors.brand.primary};
  display: flex;
  align-items: center;
  min-width: 50px;

  .anticon {
    margin: 0 4px 0 0;
  }

  &:hover {
    color: ${({ theme }): string => theme.colors.brand.primaryHover};
  }
`

export const SelectableStatusWrapper = styled.div`
  text-align: end;
  margin-bottom: 8px;

  span {
    margin: 0;
    font-size: 12px;
  }

  .ant-tag {
    cursor: pointer;
  }
`

export const Contract = styled.div`
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;

  a {
    color: ${({ theme }): string => theme.colors.text.primary};
    text-decoration: underline;
  }

  &:hover {
    > :last-child {
      visibility: visible;
    }
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const SkeletonWrapper = styled.div`
  padding: 15px;
  background: ${({ theme }): string => theme.colors.background.white};
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.102);
  margin: 0 0 30px 0;
`

export const BONES = [1, 2, 3, 4, 5, 6, 7, 8, 9]

export const CampaignContentWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const MobileCampaignContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > img {
    margin: 0 0 8px 0;
  }
`

export const ProductImageWrapper = styled.div`
  width: 128px;
  height: 128px;
  margin: 0 8px 0 0;
`

export const ProductImage = styled(AsyncImage)`
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 2px;
`

export const ProductName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }): string => theme.colors.text.primary};
  margin: 0 0 12px 0;
`

export const ContentDetail = styled(ClickableContent)`
  color: ${({ theme }): string => theme.colors.brand.secondary};

  &:hover {
    color: ${({ theme }): string => theme.colors.brand.secondaryHover};
  }
`

export const WaitForSetting = styled.div`
  color: #bfbfbf;
`

export const NA = styled.p`
  color: ${({ theme }): string => theme.colors.text.secondary};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
`

export const UTMLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  word-break: break-all;
  padding: 0 15px;
  max-width: 200px;

  @media (min-width: 768px) {
    padding: 0;
  }

  p {
    color: ${({ theme }): string => theme.colors.text.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    margin: 0 9px 0 0;
  }

  .ant-btn {
    padding: 0;
  }

  .anticon {
    color: ${({ theme }): string => theme.colors.brand.primary};
  }
`

export const CaseSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 12px 0;

  ${UnderLine} {
    width: 100%;
    padding: 0 12px;
  }
`

export const CaseSummaryContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const CaseSummaryDescription = styled.div`
  display: flex;
  width: 175px;
  padding: 0;
  flex-direction: column;
  gap: 12px;

  h6 {
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    margin: 0;
  }

  span {
    color: ${({ theme }): string => theme.colors.text.secondary};
    font-weight: 500;
    font-size: 12px;
  }
`

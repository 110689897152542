import { FunctionComponent } from 'react'
import Dropdown from '@/components/public-page/header/components/mega-menu/item/dropdown'
import LinkItem from '@/components/public-page/header/components/mega-menu/item/link-item'
import MenuItem from '@/components/public-page/header/components/mega-menu/item/type'

interface MegaMenuProps {
  items: MenuItem[]
}

const MegaMenu: FunctionComponent<MegaMenuProps> = (props) => {
  const { items } = props

  return (
    <>
      {items.map((item, i) => {
        switch (item.type) {
          case 'link':
            return (
              <LinkItem {...item} key={i}>
                {item.title}
              </LinkItem>
            )
          case 'dropdown':
            return (
              <Dropdown {...item} key={i}>
                {item.title}
              </Dropdown>
            )
          default:
            const never: never = item
            return never
        }
      })}
    </>
  )
}

export default MegaMenu

import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { default as DropdownBase } from '@/components/public-page/header/components/dropdown'
import Content, {
  Column,
} from '@/components/public-page/header/components/mega-menu/item/dropdown/content'
import IconDownSVG from '@/components/public-page/header/svg/el-icon-arrow-down.svg'

interface DropdownProps {
  children: ReactNode
  visible?: boolean
  columns: Column[]
}

const Dropdown: FunctionComponent<DropdownProps> = (props) => {
  const { children, visible = true, columns } = props

  if (!visible) {
    return null
  }

  return (
    <DropdownBase
      menuTitle={
        <TitleContainer>
          {children}
          <IconDownSVG />
        </TitleContainer>
      }
    >
      <Content columns={columns} />
    </DropdownBase>
  )
}

export type {}

export default Dropdown

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.text.primary};
  cursor: pointer;

  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    fill: ${({ theme }): string => theme.colors.base.grey.grey3};
  }
`

import { createSlice } from '@reduxjs/toolkit'

interface State {
  dbPlan: string | null
  planDuration: number
}

const initialState: State = {
  dbPlan: null,
  planDuration: 365,
}

const userPlanSlice = createSlice({
  name: 'userPlan',
  initialState,
  reducers: {
    setDBPlan: (state, action) => {
      state.dbPlan = action.payload
    },
    setPlanDuration: (state, action) => {
      state.planDuration = action.payload
    },
  },
})

export const { setDBPlan, setPlanDuration } = userPlanSlice.actions

export default userPlanSlice.reducer

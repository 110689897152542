import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { AvatarImage } from '@/components/workspace/avatar-image'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'

interface WorkspaceItemProps {
  name?: string
  imgSrc?: string
  type: I18nId
  memberAmount?: number
  isGuest?: boolean
  className?: string
}

export const WorkspaceItem: FunctionComponent<WorkspaceItemProps> = ({
  name = '',
  type,
  imgSrc,
  memberAmount = 0,
  isGuest,
  className,
}) => {
  const { formatMessage } = useIntl()
  return (
    <Wrapper className={className}>
      <AvatarImage imgSrc={imgSrc} name={name} />
      <TextWrapper>
        <Name>{name} </Name>
        <Description>
          {isGuest
            ? formatMessage({ id: 'workspace:role_name_guest' })
            : `${formatMessage({ id: type })} - ${formatMessage(
                { id: 'general:navbar_workspace_member_count' },
                { n: memberAmount },
              )}`}
        </Description>
      </TextWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding: 16px 0;
`

const TextWrapper = styled.div`
  margin: 0 0 0 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Name = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #3e3d3d;
`

const Description = styled.div`
  font-size: 12px;
  line-height: 1.4;
  color: #6f6e6e;
`
